<template>
    <article class="b-steps-content" :class="{ 'b-steps-content--full': isFull }">
        <cross :is-dark="isFull" />
        <ul class="b-steps-content__copy">
            <li>
                <h3 v-html="copy.title" />
            </li>
            <li>
                <h4 class="b-steps-content__copy__description" v-html="copy.description" />
            </li>
            <li class="b-steps-content__copy__body" v-html="copy.body" />
        </ul>
        <div class="b-steps-content__btn">
            <btn
                tag="router-link"
                v-if="url"
                color="dark"
                color-hover="light"
                :has-underline="true"
                icon-after="arrow-right"
                :label="$t('shared.button.learn-more')"
                :href="url"
            />
        </div>
    </article>
</template>

<script>
import Cross from "@/templates/components/Shared/Cross";
import Btn from "@/templates/components/Shared/SharedBtn/SharedBtn";
export default {
    components: {
        Cross,
        Btn
    },
    props: {
        copy: {
            type: Object,
            required: false,
            default: null
        },
        isFull: {
            type: Boolean,
            required: false,
            default: false
        },
        url: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        humanizeIndexCard() {
            return this.indexCard + 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.b-steps-content {
    --actions-content-copy: var(--color-gray-chateau);
    --actions-content-v-padding: var(--grid-gutter);
    --actions-content-h-padding: var(--grid-gutter);
    --actions-content-index-opacity: 1;

    --opacity-content: 0;
    --pointer-event-content: none;

    --opacity-animation: 0;
    --transform-animation: 5rem;
    --delay-animation: 0s;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: var(--actions-content-v-padding) var(--actions-content-h-padding);
    color: var(--actions-content-copy);

    @media #{md("sm")} {
        --actions-content-v-padding: var(--grid-gutter-1-5X);
        --actions-content-h-padding: var(--grid-gutter-2X);
    }

    &--full {
        --actions-content-copy: var(--color-dark);
        --actions-content-index-opacity: 0.4;
        --opacity-content: 0;
        --pointer-event-content: auto;
    }

    .c-cross {
        position: absolute;
        top: var(--actions-content-v-padding);
        right: var(--actions-content-v-padding);
        z-index: 3;
    }

    &__copy {
        @include t-content(var(--grid-gutter));
        @include reset-list;
        text-align: left;
        li {
            @include t-cms;
            &:first-child {
                // opacity: var(--actions-content-index-opacity);
                color: var(--color-dark-lighten);
                &:before {
                    @include fontHeading;
                    content: counter(steps, decimal-leading-zero) ".";
                    display: block;
                    font-size: fs("xlarge");
                    margin-bottom: 0.8rem;
                }
            }
            &:nth-child(n + 2) {
                // this is not really my fav solution, however it works really because it will always be tall enough to display the text on the over
                // because the over is absolute (so no fancy code, it always display on top), I don't want to do complex calcul or JS to get the size needed
                opacity: var(--opacity-content);
                pointer-events: var(--pointer-event-content);

                @include transition(all 0.6s ease-in-out);
                @include transform(translateY(var(--transform-animation)));
            }
            @for $i from 2 through 4 {
                &:nth-child(#{$i}) {
                    transition-delay: calc(#{$i} * var(--delay-animation));
                }
            }
        }
        h3 {
            @include t3;
        }
        h4#{&}__description {
            @include t4;
            @media #{md("lg", "max")} {
                font-size: fs("medium");
            }
        }
        p {
            @include fontCopy;
        }
    }

    &__btn {
        @include transform(translateY(var(--transform-animation)));
        @include transition(all 0.6s ease-in-out 0.5s);
        display: flex;
        justify-content: flex-start;
        margin-top: var(--grid-gutter);

        // same as line 96
        opacity: var(--opacity-content);
        pointer-events: var(--pointer-event-content);
    }
}
</style>
