<template>
    <div class="b-highlight | wrap bs-grid" ref="highlight" v-reveal.once>
        <div
            class="b-highlight__copy t-cms | col-6 col-8-sm start-2-sm start-1-lg col-6-lg start-3-lg"
            ref="highlightCopy"
        >
            <div class="t-cms" v-html="data.content" />
        </div>

        <div class="b-highlight__visual | col-5 col-7-sm start-5-sm col-6-lg start-10-lg">
            <div class="b-highlight__visual__square" ref="highlightSquare">
                <shapes-square background-color="concrete" icon="shape--inclined-lines" />
            </div>
            <div class="b-highlight__visual__img" ref="highlightAsset">
                <asset v-if="data.image" :asset="data.image" :cover="true" />
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";

import Asset from "objects/Asset";
import ShapesSquare from "components/Shapes/ShapesSquare";

export default {
    name: "BlockHighlight",
    props: {
        data: false
    },
    components: {
        ShapesSquare,
        Asset
    },
    data() {
        return {
            gsapAnimation: {
                timeline: null,
                durations: { duration1: 0.5 },
                ease: "power1.inOut",
                isRunning: false
            }
        };
    },
    mounted() {
        this.observer = new MutationObserver(mutations => {
            for (const m of mutations) {
                const newValue = m.target.getAttribute(m.attributeName);
                this.$nextTick(() => {
                    this.onClassChange(newValue, m.oldValue);
                });
            }
        });

        this.observer.observe(this.$refs.highlight, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ["class"]
        });
    },
    beforeDestroy() {
        this.observer.disconnect();
        this.destroyTimeline();
    },
    methods: {
        ////////////////////////////////
        //       START TRIGGER ANIMATION
        ////////////////////////////////
        onClassChange(classAttrValue) {
            const classList = classAttrValue.split(" ");
            if (classList.includes("is-visible")) {
                !this.gsapAnimation.timeline ? this.runTimeLine() : null;
            }
        },
        ////////////////////////////////
        //       END TRIGGER ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        runTimeLine() {
            this.gsapAnimation.timeline = gsap.timeline({ paused: false });
            this.gsapAnimation.timeline

                .to(
                    this.$refs.highlightAsset,
                    {
                        x: 0,
                        opacity: 1,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "start"
                )
                .to(this.$refs.highlightAsset, {
                    y: 0,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                })
                .to(
                    this.$refs.highlightSquare,
                    {
                        y: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "start"
                )
                .to(this.$refs.highlightSquare, {
                    x: 0,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                })
                .to(this.$refs.highlightCopy, {
                    x: 0,
                    opacity: 1,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                });
        },

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////

        //       START DESTROY
        ////////////////////////////////
        destroyTimeline() {
            this.gsapAnimation.timeline
                ? (this.gsapAnimation.timeline.kill(), (this.gsapAnimation.timeline = null))
                : null;
        }
        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.block-highlight {
    --highlight-v-space: var(--block-spacer);
    --highlight-bottom-space: 4rem;
    padding-top: var(--highlight-v-space);
    padding-bottom: calc(var(--highlight-v-space) + var(--highlight-bottom-space));
    overflow: hidden;
    background-color: var(--color-dark);

    // Fake negative margin bottom
    > * {
        z-index: 2;
    }

    &:after {
        content: "";
        height: var(--highlight-bottom-space);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: white;
    }
}

.b-highlight {
    &__copy {
        @include pre-animation(-100px, 0px);
        .t-cms {
            color: var(--color-light);

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: var(--color-malibu);
            }
        }
    }

    &__visual {
        align-self: end;
        margin-bottom: calc((var(--highlight-v-space) + var(--highlight-bottom-space)) * -1);

        @media #{md("xs")} {
            margin-top: calc((var(--highlight-v-space) + var(--bs-row-gap)) * -1);
        }

        @media #{md("md")} {
            margin-top: 0;
        }

        .c-shapes-square {
            --width-shape-square: 12rem;
            position: relative;
            margin-left: auto;

            @media #{md("xs", "max")} {
                margin-right: var(--grid-gutter-minus-2X);
                margin-bottom: calc(var(--width-shape-square) * -0.5);
            }

            @media #{md("xs")} {
                --width-shape-square: 16rem;
                transform: translateX(100%);
            }
        }

        &__square {
            @include transform(translate(100px, 100px));
        }

        &__img {
            --highlight-img-ratio: 1 / 0.8;
            @include transform(translate(-100px, 100px));
            aspect-ratio: var(--highlight-img-ratio);
            background: var(--color-malibu);

            @media #{md("xs", "max")} {
                margin-left: var(--grid-gutter-minus);
            }

            .o-asset {
                aspect-ratio: var(--highlight-img-ratio);
            }
        }
    }
}
</style>
