import getAsset from "./assets";

/*
 * Craft GraphQL Snippets
 */

/**********************************
 *
 *
 *  DEFAULTS
 *
 *
 **********************************/

// Default data
const defaultData = `
id
uri
`;

// Default entry
const entry = `
id
section: sectionHandle
title
slug
uri
`;

// Default category
const category = `
id
slug
title
`;

/**********************************
 *
 *
 *  ATOMS
 *
 *
 **********************************/

// SEO
const seo = `
seo {
    title
    description
}
`;

// Map (SuperTable)
const map = `
map {
    ...on map_BlockType {
        lng
        lat
    }
}
`;

/**********************************
 *
 *
 *  ASSET
 *
 *
 **********************************/

/**********************************
 *
 *
 *  RELATIONS
 *
 *
 **********************************/

const relationCategoryArticleTypes = `
articleType: relationCategoryArticleTypes {
    ${category}
}
`;

const relationEntryPage = `
page: relationEntryPage {
    ${entry}
}
`;

const relationUser = `
relationUser {
    fullName
    email
    ${getAsset("photo")}
    ...on User {
        role: label
        linkedIn
    }
}
`;

/**********************************
 *
 *
 *  BUILDER
 *
 *
 **********************************/

// Accordions
const blockAccordions = `
...on layout_BlockAccordions_BlockType {
    typeHandle
    user: ${relationUser}
    linkLabel: label
    linkUrl: relationEntryPage {
        uri
    }
    heading
    description
    accordions: children {
        ...on layout_accordion_BlockType {
            heading
            content: body
        }
    }
}
`;

const BlockApproach = `
...on layout_BlockApproach_BlockType {
    typeHandle
    heading
    ${getAsset()}
    elements: children {
        ...on layout_accordion_BlockType {
            heading
            content: body
        }
    }
}
`;

// Content
const blockContent = `
...on layout_BlockContent_BlockType {
    typeHandle
    content: body
    verticalAlignment
}
`;

// Gallery
const blockGallery = `
...on layout_BlockGallery_BlockType {
    typeHandle
    ${getAsset("images")}
}
`;

// Image
const blockImage = `
...on layout_BlockImage_BlockType {
    typeHandle
    ${getAsset()}
}
`;

// References
const BlockReferences = `
...on layout_BlockReferences_BlockType {
    typeHandle
    heading
    ${getAsset("logos")}
    bubbles {
        number
        text
    }
}
`;

// Steps
const BlockSteps = `
...on layout_BlockSteps_BlockType {
    typeHandle
    heading
    description
    label
    ${relationEntryPage}
}
`;

// Tagline
const BlockTagline = `
...on layout_BlockTagline_BlockType {
    typeHandle
    heading
}
`;

// Articles
const blockArticles = `
...on layout_BlockArticles_BlockType {
    typeHandle
    heading
    description,
}
`;

// Scrollhack
const BlockScrollhack = `
...on layout_BlockScrollhack_BlockType {
    typeHandle
    type: typeToDisplay
}
`;

// Spacer
const blockSpacer = `
...on layout_BlockSpacer_BlockType {
    typeHandle
}
`;

// Section
const blockSection = `
...on layout_BlockSection_BlockType {
    typeHandle
    id
    label
    displayLabel: trueOrFalse1
    children {
        ${blockAccordions}
        ${BlockApproach}
        ${BlockSteps}
        ${blockArticles}
        ${blockContent}
        ${blockGallery}
        ${blockImage}
        ${BlockScrollhack}
        ${blockSpacer}
        ${BlockTagline}
        ${BlockReferences}
    }
}
`;

// Layout
const layout = `
sections: layout {
    ${blockSection}
}
`;

/*
 * Article's specific blocks
 */

// Tagline
const blockTaglineArticle = `
...on layoutArticle_BlockTagline_BlockType {
    typeHandle
    heading
}
`;

// Content
const blockContentArticle = `
...on layoutArticle_BlockContent_BlockType {
    typeHandle
    content: body
    verticalAlignment
}
`;

// Highlight
const blockHighlightArticle = `
...on layoutArticle_BlockHighlight_BlockType {
    typeHandle
    content: body
    ${getAsset()}
}
`;

// Article Layout
const layoutArticle = `
sections: layoutArticle {
    ${blockTaglineArticle}
    ${blockContentArticle}
    ${blockHighlightArticle}
}
`;

/**********************************
 *
 *
 *  CHANNELS AND STRUCTURES
 *
 *
 **********************************/

const page = `
${entry}
...on page_default_Entry {
    heading
    headerType
    ${layout}
    ${seo}
    children {
        ${entry}
    }
    parent {
        title

        #...on page_default_Entry {
        #}
        children {
            ${entry}
        }
    }
}
`;

const article = `
${entry}
postDate: postDate @formatDateTime
...on article_default_Entry {
    ${relationCategoryArticleTypes}
    ${seo}
    excerpt: description
    completeArticleLink: webUrl
    author {
        id
        fullName
        email
        ${getAsset("photo")}
        ...on User {
            role: label
            linkedIn
        }
    }
    ${getAsset()}
    ${layoutArticle}
}
`;

const step = `
${entry}
...on step_default_Entry {
    ${layout}
}
`;

const service = `
${entry}
...on service_default_Entry {
    ${layout}
}
`;

// Exports
export default {
    // Defaults
    defaultData,
    entry,
    category,

    // Atoms
    seo,
    map,

    // Builder
    layout,
    layoutArticle,

    // CHANNELS
    page,
    article,
    service,
    step,
    blockArticles,

    // Relations
    relationEntryPage,
    relationUser,
};
