<template>
    <section class="b-scrollhack-desktop  | bs-grid">
        <block-scroll-hack-main-square
            :active-item="activeItem"
            :entries="entries"
            :is-square-blue="isSquareBlue"
            :is-visible="isParagraphVisible"
        />

        <block-scroll-hack-main-list
            :active-item="listProgress(activeItem - 1)"
            :entries="entries"
            :is-visible="isParagraphVisible"
        />
    </section>
</template>

<script>
import { gsap, Observer } from "gsap/all";
gsap.registerPlugin(Observer);

import BlockScrollHackMainList from "blocks/BlockScrollhack/BlockScrollHackMainList/BlockScrollHackMainList";
import BlockScrollHackMainSquare from "blocks/BlockScrollhack/BlockScrollHackMainSquare/BlockScrollHackMainSquare";

export default {
    components: {
        BlockScrollHackMainList,
        BlockScrollHackMainSquare
    },
    props: {
        entries: {
            type: Array,
            required: false,
            default: () => []
        },
        isRunning: {
            type: Boolean,
            required: false,
            default: false
        },
        isSquareBlue: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            scrollAnimation: {
                observer: null,
                isRunning: false
            },
            activeItem: 0
        };
    },
    computed: {
        isParagraphVisible() {
            return this.activeItem >= 0 && this.isRunning;
        }
    },
    mounted() {
        this.initObserver();
    },
    methods: {
        toggleObserver(bool) {
            if (!this.scrollAnimation.observer) return;
            bool ? this.scrollAnimation.observer.enable() : this.scrollAnimation.observer.disabled();
        },
        ////////////////////////////////
        //       START OBSERVER
        ////////////////////////////////
        initObserver() {
            this.scrollAnimation.observer = Observer.create({
                target: window, // can be any element (selector text is fine)
                wheelSpeed: -1,
                type: "wheel,touch,scroll,pointer", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
                preventDefault: false,

                onUp: () => {
                    this.isRunning && !this.scrollAnimation.isRunning ? this.goUpOrDown(true) : null;
                },
                onDown: () => {
                    this.isRunning && !this.scrollAnimation.isRunning ? this.goUpOrDown(false) : null;
                }
                // onStop: () => {
                //     this.toggleIsRunning(false);
                // }
            });
        },
        goUpOrDown(bool) {
            this.toggleIsRunning(true);

            this.animateScroll(bool);

            // alternatively, we can use that instead of stop but I don't recommand it
            const goUpDownTimeout = setTimeout(() => {
                this.toggleIsRunning(false);
                clearTimeout(goUpDownTimeout);
            }, 2000);
        },
        animateScroll(bool) {
            switch (bool) {
                case true:
                    this.activeItem < this.entries.length
                        ? this.updateActivePanel(this.activeItem + 1)
                        : this.enableScrollBack();
                    break;

                default:
                    this.activeItem > 0 ? this.updateActivePanel(this.activeItem - 1) : this.enableScrollBack();
                    break;
            }
        },
        updateActivePanel(newValue) {
            this.activeItem = newValue;
        },
        toggleIsRunning(bool) {
            this.scrollAnimation.isRunning = bool;
        },
        enableScrollBack() {
            this.$emit("enableScrollBack", true);
        },
        ////////////////////////////////
        //       END OBSERVER
        ////////////////////////////////

        listProgress(progress) {
            return progress < 0 ? 0 : progress;
        }
    }
};
</script>
