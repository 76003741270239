<template>
    <div class="c-hero | wrap bs-grid" :class="className" ref="heroArticle" v-reveal.once>
        <div class="c-hero__copy | start-1 end-1 col-7-sm col-8-lg col-7-xl">
            <card-content-date :article-type="page.articleType" :post-date="page.postDate" class="js-hero-stagger" />

            <h1 v-html="page.title" class="c-hero__title js-hero-stagger" />

            <div v-if="page.author" class="c-hero__copy__author js-hero-stagger">
                <user-card
                    :user="page.author"
                    :before-name="$t('shared.by')"
                    :is-linkedin-visible="false"
                    orientation="horizontal"
                    size="small"
                ></user-card>
            </div>

            <div v-if="page.excerpt" v-html="page.excerpt" class="c-hero__copy__excerpt js-hero-stagger" />

            <div class="c-hero__copy__btn-share">
                <social-share />
            </div>
        </div>
        <div class="c-hero__visual | col-5 col-7-lg start-10-lg col-6-xxl start-10-xxl">
            <hero-visual-square />
            <hero-visual-blue-square class="c-hero__visual__blue-square" />

            <div class="c-hero__visual__img" v-if="page.image">
                <asset :asset="page.image" :cover="true" />
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";

import AnimText from "@/templates/objects/AnimText";
import CardContentDate from "components/Card/CardContentDate";
import HeroVisualBlueSquare from "components/Hero/HeroVisual/HeroVisualBlueSquare";
import HeroVisualSquare from "components/Hero/HeroVisual/HeroVisualSquare";
import SocialShare from "components/Shared/SocialShare";
import UserCard from "@/templates/components/UserCard.vue";

import Asset from "@/templates/objects/Asset";
import Btn from "@/templates/components/Shared/SharedBtn/SharedBtn";

export default {
    name: "ArticleHero",
    components: {
        AnimText,
        UserCard,
        SocialShare,
        HeroVisualBlueSquare,
        CardContentDate,
        Asset,
        Btn,
        HeroVisualSquare
    },
    props: {
        page: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            gsapAnimation: {
                timeline: null,
                durations: { duration1: 0.5 },
                ease: "power1.inOut",
                isTitleVisible: false
            }
        };
    },
    mounted() {
        this.observer = new MutationObserver(mutations => {
            for (const m of mutations) {
                const newValue = m.target.getAttribute(m.attributeName);
                this.$nextTick(() => {
                    this.onClassChange(newValue, m.oldValue);
                });
            }
        });

        this.observer.observe(this.$refs.heroArticle, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ["class"]
        });
    },
    beforeDestroy() {
        this.observer.disconnect();
        this.destroyTimeline();
    },
    computed: {
        className() {
            let classname = [];

            if (!this.page.image) {
                classname.push("c-hero--no-image");
            }

            return classname;
        }
    },
    methods: {
        ////////////////////////////////
        //       START TRIGGER ANIMATION
        ////////////////////////////////
        onClassChange(classAttrValue) {
            const classList = classAttrValue.split(" ");
            if (classList.includes("is-visible")) {
                !this.gsapAnimation.timeline ? this.runTimeLine() : null;
            }
        },
        ////////////////////////////////
        //       END TRIGGER ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        runTimeLine() {
            this.gsapAnimation.timeline = gsap.timeline({ paused: false });

            this.gsapAnimation.timeline
                .to(
                    ".c-hero__visual__img",
                    {
                        x: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "start"
                )
                .to(".c-hero__visual__img", {
                    y: 0,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                })
                .to(
                    ".c-hero__visual__blue-square",
                    {
                        y: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "start"
                )
                .to(".c-hero__visual__blue-square", {
                    x: 0,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                })

                .to(".js-hero-stagger", {
                    x: 0,
                    opacity: 1,
                    stagger: {
                        each: 0.3,
                        ease: this.gsapAnimation.ease
                    }
                })
                .to(".c-hero__copy__btn-share", {
                    y: 0,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                });
        },

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////
        ////////////////////////////////
        //       START DESTROY
        ////////////////////////////////
        destroyTimeline() {
            this.gsapAnimation.timeline
                ? (this.gsapAnimation.timeline.kill(), (this.gsapAnimation.timeline = null))
                : null;
        }
        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.c-hero {
    --v-spacing: 11rem;

    &.c-hero--no-image {
        row-gap: 0;
    }

    @media #{md("sm")} {
        --v-spacing: var(--grid-gutter-6X);
    }

    @media #{md("md")} {
        align-items: end;
    }

    .js-hero-stagger {
        @include pre-animation(-100px, 0px);
    }

    &__title {
        @include t1;
    }

    &__copy {
        padding-top: var(--v-spacing);

        @media #{md("xs", "max")} {
            --v-spacing: 0;
            order: 2;
        }

        @media #{md("md")} {
            margin-right: var(--grid-gutter-minus);
        }

        &__author {
            margin-top: var(--grid-gutter);
        }

        &__excerpt {
            margin-top: var(--grid-gutter-2X);
        }

        &__btn-share {
            @include pre-animation(0px, 100px);
            margin-top: var(--grid-gutter);

            @media #{md("md")} {
                margin-top: var(--grid-gutter-4X);
            }
        }
    }

    &__visual {
        padding-top: var(--v-spacing);
        position: static;

        @media #{md("xs", "max")} {
            order: 1;
        }

        @media #{md("lg", "max")} {
            margin-right: calc(var(--wrap-spacer) * -1);
        }

        @media #{md("lg")} {
            margin-left: var(--grid-gutter-minus);
        }

        .c-hero-visual-blue-square {
            --width-square: 58rem;
            width: clamp(27.5rem, var(--width-square), 60vw);
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;

            @media #{md("sm")} {
                top: 6.5rem;
            }
            @media #{md("lg")} {
                top: 0;
            }
        }

        .c-hero-visual-square {
            // Fake first load
            --intersection-y: 100%;

            .c-shapes-square--responsive {
                --width-shape-square: 0;
            }
        }

        &__blue-square {
            @include transform(translate(-10%, -20%));
        }

        &__img {
            @include transform(translate(10%, -20%));
            z-index: 2;

            .o-asset {
                width: 100%;
                aspect-ratio: 1/1.3;

                &__img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    h1 {
        @include t1;
    }
}
</style>
