<template>
    <div>
        <hero :copy="page.heading" :header-type="page.headerType" />
        <app-builder v-if="page.sections && page.sections.length" :sections="page.sections" />
    </div>
</template>

<script>
import { SECTION_HANDLES } from "src/constants";
import AppBuilder from "layout/AppBuilder";
import Hero from "components/Hero/Hero";

export default {
    name: "Page",
    components: {
        Hero,
        AppBuilder
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        };
    },
    props: {
        page: Object
    },
    data: () => ({
        SECTION_HANDLES,
        isReady: false
    })
};
</script>
