<template>
    <div class="c-hero-visual | col-5 start-2 start-8-sm col-6-lg start-11-lg" :class="`c-hero-visual--${headerType}`">
        <!-- large blue square -->
        <hero-visual-blue-square />

        <!-- shapeSquqare -->
        <hero-visual-square :header-type="headerType" :icon="iconName" />

        <!-- custom visual element -->
        <hero-visual-asset
            :header-type="headerType"
            :title="`quadrat ${headerType} visual`"
            :image="`${headerType}.png`"
        />
    </div>
</template>

<script>
import HeroVisualAsset from "components/Hero/HeroVisual/HeroVisualAsset";
import HeroVisualBlueSquare from "components/Hero/HeroVisual/HeroVisualBlueSquare";
import HeroVisualSquare from "components/Hero/HeroVisual/HeroVisualSquare";

export default {
    components: {
        HeroVisualAsset,
        HeroVisualBlueSquare,
        HeroVisualSquare
    },
    props: {
        headerType: {
            type: String,
            required: false,
            default: "stairs",
            validator: val => ["stairs", "triangles", "cubes", "columns", "rectangles"].includes(val)
        }
    },
    computed: {
        iconName() {
            switch (this.headerType) {
                case "cubes":
                    return "shape--angled-lines";
                case "triangles":
                    return "shape--curved-lines";
                case "rectangles":
                    return "shape--binded-lines";
                case "columns":
                    return "shape--wireframe-lines";
                default:
                    return "shape--inclined-lines";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.c-hero-visual {
    --intersection-x: 50%;
    --intersection-y: 50%;

    align-self: flex-start;
    margin-left: var(--grid-gutter);
    margin-right: calc(var(--wrap-spacer) * -1);

    @media #{md("xs")} {
        order: 2;
        margin-left: var(--grid-gutter-minus);
    }
    @media #{md("sm")} {
        margin-top: 6.5rem;
    }
    @media #{md("lg")} {
        margin-top: 0;
    }

    // Header types
    &--stairs {
        --intersection-x: 0%;
        --intersection-y: 83%;
    }

    &--triangles {
        --intersection-x: 28%;
        --intersection-y: 65%;
        @media #{md("xs")} {
            --intersection-y: 61.5%;
        }
    }

    &--cubes {
        --intersection-x: 8%;
        --intersection-y: 63%;
        @media #{md("xs")} {
            --intersection-y: 56.5%;
        }
    }

    &--columns {
        --intersection-x: 0%;
        --intersection-y: 60%;
        @media #{md("xs")} {
            --intersection-y: 50%;
        }
    }

    &--rectangles {
        --intersection-x: 0%;
        --intersection-y: 60%;
    }
}
</style>
