<template>
    <section v-if="user" class="c-app-nav-mobile-contact">
        <ul class="c-app-nav-mobile-contact__list">
            <!-- thumbnail -->
            <li>
                <shared-thumbnail v-if="user.photo" :title="user.fullName" :asset="user.photo" />
            </li>
            <!-- contact info -->
            <li class="c-app-nav-mobile-contact__list__contact">
                <p v-html="`${user.fullName} ${user.role || ''}`"></p>
                <btn
                    tag="a"
                    color="dark"
                    :has-underline="false"
                    :label="global.email"
                    :href="`mailto:${global.email}`"
                    :is-target-blank="true"
                />
            </li>
            <!-- linkedin -->
            <li>
                <btn
                    tag="a"
                    color="dark"
                    :has-underline="true"
                    overwrite-line="malibu"
                    :label="$t('shared.socialMedia.linkedin')"
                    :href="user.linkedIn"
                    :is-target-blank="true"
                />
            </li>
        </ul>
    </section>
</template>

<script>
import { mapState } from "vuex";

import Btn from "components/Shared/SharedBtn/SharedBtn";
import SharedThumbnail from "components/Shared/SharedThumnail/SharedThumbnail";

export default {
    components: {
        Btn,
        SharedThumbnail
    },
    computed: {
        ...mapState({
            defaultUser: state => state.global.settings.defaultUser,
            global: state => state.global.settings
        }),
        user() {
            return this.defaultUser && this.defaultUser.length ? this.defaultUser[0] : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-app-nav-mobile-contact {
    --scale-line: 0;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: var(--grid-gutter-1-5X) 0px;

    &:before {
        @include full-screen-dom(absolute);
        left: var(--grid-gutter-2X);
        right: var(--grid-gutter-2X);
        content: " ";
        height: 1px;
        background-color: var(--color-concrete);
        transform: scaleX(var(--scale-line));
        transform-origin: left center;
    }

    &__list {
        --mobile-contact-spacing: var(--grid-gutter);
        @include t-content(var(--mobile-contact-spacing));

        li {
            display: flex;
            justify-content: center;
            align-items: center;

            @include transform(translateY(5rem));
            opacity: 0;
        }

        &__contact {
            flex-direction: column;
            font-size: fs("large");

            a {
                padding: 0px;
                margin-top: calc(var(--mobile-contact-spacing) * 0.35);
            }
        }

        .c-btn--underline {
            margin-top: -0.5em;
            margin-bottom: -0.5em;
        }
    }
}
</style>
