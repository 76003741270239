<template>
    <ul v-if="elements.length" class="b-approach__elements | t-content">
        <li
            v-for="(element, i) in elements"
            :key="`ba-el-${i}`"
            class="-element | t-content"
        >
            <h3 class="-title | t-t4" v-html="element.heading"></h3>
            <div class="-content | t-cms" v-html="element.content"></div>
        </li>
    </ul>
</template>

<script>
export default {
    name: "BlockApproachElements",
    components: {
    },
    props: {
        elements: {
            type: Array,
            required: true,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.b-approach__elements {
    --content-gap: var(--grid-gutter-2X);
    @include reset-list;
    counter-set: elements
}

.-element {
    --content-gap: .8rem;
    counter-increment: elements
}

.-title {
    &:before {
        @include fontSmallCopy();
        color: var(--color-gray-chateau);
        content: counter(elements, decimal-leading-zero) ".";
    }
}

.-content {
    color: var(--color-gray-chateau);
}
</style>
