import Vue from "vue";
import Vuex from "vuex";

import * as Modules from "./modules"; // this comment can be deleted - I added it to fix the issue on staging
import { STORE_MODULES } from "src/constants";

import { capitalize } from "src/utils";

// Vuex
Vue.use(Vuex);

const modules = {};
STORE_MODULES.forEach(module => {
    modules[module] = Modules[capitalize(module)];
});

const store = new Vuex.Store({
    modules,
    strict: process.env.NODE_ENV !== "production"
});

export default store;
export { modules, store };
