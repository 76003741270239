<template>
    <div
        class="b-scrollhack-main-square start-3 col-3"
        :class="{ 'b-scrollhack-main-square--visible': isVisible, 'b-scrollhack-main-square--blue': isSquareBlue }"
    >
        <block-scroll-hack-main-square-icon :entries="entries" :active-item="activeItem" />
        <ul class="b-scrollhack-main-square__list">
            <block-scroll-hack-main-square-item
                v-for="(entry, i) in entries"
                :key="`square-${entry.slug}-${i}`"
                :entry="entry"
                :is-active="isActive(i)"
                :entry-index="entryIndex(i)"
            />
        </ul>
    </div>
</template>

<script>
import BlockScrollHackMainSquareIcon from "blocks/BlockScrollhack/BlockScrollHackMainSquare/BlockScrollHackMainSquareIcon/BlockScrollHackMainSquareIcon";
import BlockScrollHackMainSquareItem from "blocks/BlockScrollhack/BlockScrollHackMainSquare/BlockScrollHackMainSquareItem";
export default {
    components: {
        BlockScrollHackMainSquareIcon,
        BlockScrollHackMainSquareItem
    },
    props: {
        entries: {
            type: Array,
            required: false,
            default: () => []
        },
        activeItem: {
            type: Number,
            required: false,
            default: 0
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isSquareBlue: {
            type: Boolean,
            required: false,
            default: false
        },
        // its used for the mobile
        customEntryIndex: {
            type: Number,
            required: false,
            default: -1
        }
    },

    methods: {
        isActive(index) {
            return index + 1 === this.activeItem;
        },
        entryIndex(index) {
            return this.customEntryIndex >= 0 ? this.customEntryIndex : index;
        }
    }
};
</script>

<style lang="scss">
.b-scrollhack-main-square {
    --opacity: 0;
    --square-color: var(--color-concrete);
    @include transition(0.5s ease opacity);
    display: flex;
    justify-content: center;
    align-content: center;
    opacity: var(--opacity);

    height: 0%;
    // padding-bottom:100%; // friendly to browsers without aspect ratio

    &:after {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        content: " ";
        background: var(--square-color);
        padding-bottom: 100%;
        // height: 100%;
    }

    &--visible {
        --opacity: 1;
    }
    &--blue {
        --square-color: var(--color-malibu);
    }

    &__list {
        @include reset-list;
        display: flex;
        position: relative;
        width: 100%;
        padding-bottom: 100%; // aspect-ratio: 1/1; https://mambomambo-team.atlassian.net/browse/QUAD-251
        // @media #{md("sm")} {
        // }
    }
}
</style>
