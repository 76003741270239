<template>
    <tag
        :is="tag"
        :href="tag == 'a' && href !== null ? href : false"
        :to="tag == 'router-link' && href !== null ? href : false"
        :rel="tag == 'a' ? 'noopener' : false"
        class="c-btn"
        :class="className"
        :style="setStyle"
        :aria-disabled="isDisabled"
        :target="hrefTarget"
    >
        <shared-btn-icon v-if="iconBefore !== null" :color="color" :color-hover="colorHover" :icon="iconBefore" />
        <span class="c-btn__label">
            <slot>{{ label }}</slot>
        </span>
        <shared-btn-icon v-if="iconAfter !== null" :color="color" :color-hover="colorHover" :icon="iconAfter" />
    </tag>
</template>

<script>
import SharedBtnIcon from "components/Shared/SharedBtn/SharedBtnIcon";
export default {
    name: "Btn",
    components: {
        SharedBtnIcon
    },
    props: {
        //--- start base ---//
        tag: {
            type: String,
            default: "button"
        },
        href: {
            type: String,
            default: null
        },
        label: {
            type: String | Number,
            default: "Button Label"
        },
        color: {
            type: String,
            required: false,
            default: "malibu",
            validator: val => ["light", "dark", "dark-lighten", "gray-chateau", "malibu"].includes(val)
        },
        colorHover: {
            type: String,
            required: false,
            default: "dark",
            validator: val => ["light", "dark", "dark-lighten", "gray-chateau", "malibu"].includes(val)
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isTargetBlank: {
            type: Boolean,
            required: false,
            default: false
        },
        //--- end base ---//

        //--- start icons related props ---//
        iconBefore: {
            type: String,
            default: null
        },
        iconAfter: {
            type: String,
            default: null
        },
        //--- end icons related props ---//

        //--- start lines related props ---//
        hasUnderline: {
            type: Boolean,
            required: false,
            default: false
        },
        overwriteLine: {
            type: String,
            required: false,
            default: null
        }
        //--- end lines related props ---//
    },
    computed: {
        className() {
            let classname = "c-btn";

            if (this.hasUnderline) {
                classname += ` c-btn--underline`; // please leave this one BEM
            }

            return classname;
        },
        setStyle() {
            let setStyle = `--color-btn: var(--color-${this.color}); --hover-color:var(--color-${this.colorHover}); `;
            if (this.hasUnderline) {
                setStyle += ` --color-line: var(--color-${this.setColorUnderline});`;
            }
            return setStyle;
        },
        setColorUnderline() {
            return this.overwriteLine ? this.overwriteLine : this.color;
        },
        hrefTarget() {
            return this.tag === "a" ? this.isAnchorTargetBlank() : null;
        }
    },
    methods: {
        isAnchorTargetBlank() {
            return this.isTargetBlank ? "_blank" : "_self";
        }
    }
};
</script>

<style lang="scss" scoped>
.c-btn {
    --color-btn: $color-light;
    --color-line: $color-light;
    --hover-color: #69c1ff;
    --line-display: none;
    --svg-spacing: 0.625em;

    --background-position: 100%;

    @include disabler;
    @include transition(opacity 0.5s ease);
    @include underline(var(--line-display), var(--color-line), var(--hover-color), 0em, var(--background-position));
    display: inline-flex;
    align-items: center;
    padding: 0.5em 0em;
    overflow: hidden;

    cursor: pointer;

    &:hover {
        --background-position: 0%;
    }

    &--underline {
        --line-display: inline-block;
    }

    &__label {
        @include transition(0.3s ease all);
        color: var(--color-btn);
        background-image: linear-gradient(90deg, var(--hover-color) 50%, var(--color-btn) 50%);
        background-clip: border-box;
        background-size: 200% 100px;
        background-position: var(--background-position);
        background-clip: text;
        -webkit-background-clip: text;
        text-fill-color: transparent;
        -webkit-text-fill-color: transparent;

        display: inline-block;

        & + .c-btn__icon {
            margin-left: var(--svg-spacing);
        }
    }
}
</style>
