<template>
    <ul class="c-card-content-date" :class="{ 'c-card-content-date--full': isLineVisible }">
        <li>
            <p>{{ formatArticleDate(postDate) }}</p>
        </li>

        <li>
            <p v-if="articleType">{{ articleType.title }}</p>
        </li>
    </ul>
</template>

<script>
import { formatDate } from "@/utils";

export default {
    props: {
        articleType: {
            type: Object,
            required: false,
            default: null
        },
        postDate: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        isLineVisible() {
            return this.postDate && this.articleType;
        }
    },
    methods: {
        formatArticleDate(date) {
            return formatDate(date);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-card-content-date {
    --content-date-left: var(--grid-gutter-half);
    --line-display: none;
    @include reset-list;
    display: flex;
    flex-direction: row;

    &--full {
        --content-date-left: calc(2rem + var(--grid-gutter));
        --line-display: flex;
    }

    li {
        position: relative;
        &:nth-child(2) {
            padding-left: var(--content-date-left);
            &:before {
                content: " ";
                display: var(--line-display);
                position: absolute;
                top: 25%;
                left: var(--grid-gutter-half);
                height: 1px;
                width: 2rem;
                background-color: var(--color-gray-chateau);
            }
        }
    }

    p {
        color: var(--color-gray-chateau);
        font-size: fs("large");
    }
}
</style>
