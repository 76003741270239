<template>
    <header
        class="l-header"
        :class="{
            'l-header--visible': isBackgroundVisible,
            'l-header--plain': isBackgroundPlain,
            'l-header--menu-mobile-open': isNavOpen
        }"
    >
        <div class="l-header__inner | wrap">
            <app-header-logo />
            <app-nav />
            <app-header-burger />
        </div>
    </header>
</template>

<script>
import { mapState } from "vuex";
import AppHeaderLogo from "components/AppHeader/AppHeaderLogo";
import AppNav from "components/AppNav/AppNav";
import AppHeaderBurger from "components/AppHeader/AppHeaderBurger";

export default {
    name: "AppHeader",
    components: {
        AppHeaderLogo,
        AppNav,
        AppHeaderBurger
    },
    data() {
        return {
            currentScrollY: 0,
            isUserScrollingUp: true
        };
    },
    computed: {
        ...mapState({
            isScrollDisabled: state => state.scroll.isScrollDisabled,
            isNavOpen: state => state.navigation.isNavOpen
        }),
        isBackgroundPlain() {
            return this.currentScrollY > 5;
        },
        isBackgroundVisible() {
            return (this.currentScrollY < 5 || this.isUserScrollingUp) && !this.isScrollDisabled;
        }
    },
    mounted() {
        this.setScrollEventListener();
    },
    beforeDestroy() {
        document.removeEventListener("scroll", this.updateCurrentScrollPosition);
    },
    methods: {
        setScrollEventListener() {
            document.addEventListener("scroll", this.updateCurrentScrollPosition);
        },
        updateCurrentScrollPosition() {
            this.isUserScrollingUp = this.currentScrollY > window.scrollY;

            this.currentScrollY = window.scrollY;
        }
    }
};
</script>

<style lang="scss">
.l-header {
    $self: &;
    $transition-header: background 0.5s ease, transform 0.5s ease;
    --background-color-header: var(--color-light);
    --translate-header-y: 0%;
    --background-color-header: none;
    @include transform(translateY(var(--translate-header-y)));
    @include transition($transition-header);
    position: fixed;
    width: 100%;
    background: var(--background-color-header);
    z-index: 99;

    @media #{md("sm")} {
        --translate-header-y: -100%;
    }

    &--plain {
        --background-color-header: var(--color-light);
        @include shadow(inset 0px -1px 0px 0px var(--color-concrete));
    }

    &--visible {
        --translate-header-y: 0%;
    }

    &__inner {
        --header-justify: space-between;
        --header-height: 11rem;
        display: flex;
        justify-content: var(--header-justify);
        height: var(--header-height);
        transition: height 0.5s $in-out-quad;

        #{$self}--plain:not(#{$self}--menu-mobile-open) & {
            --header-height: 7rem;
        }

        @media #{md("sm")} {
            --header-justify: flex-start;
            --header-height: auto;
        }
    }
}
</style>
