<template>
    <div v-if="inputs.length > 0" class="c-form" :class="{ 'is-sending': pending }">
        <slot name="before" />

        <div v-if="error" class="c-form__error | t-cms" v-html="$t('form.messages.error')" />

        <form v-if="!success" action="/" class="c-form__inner" @submit.prevent="submitForm">
            <template v-for="(input, i) in inputs">
                <div
                    :key="`input-${input.name}-${i}`"
                    class="c-form__el"
                    :class="[{ 'has-error': input.error }, { '-half': input.half }]"
                >
                    <app-input-text
                        v-model="input.value"
                        :label="input.label"
                        :name="input.name"
                        :type="input.type"
                        :autocomplete="input.autocomplete"
                        :required="!!input.required"
                        :defaultValue="input.defaultValue"
                        :disabled="!!input.disabled"
                    />
                </div>
            </template>

            <div class="c-form__el -submit">
                <!-- <btn :label="pending ? 'Envoi en cours...' : submitLabel" type="dark" /> -->
                <btn
                    :label="pending ? $t('form.messages.pending') : submitLabel"
                    color="dark"
                    color-hover="light"
                    :has-underline="true"
                    icon-after="arrow-right--small"
                />
            </div>
        </form>

        <div v-else-if="successText || !!$slots.success" class="c-form__success">
            <slot name="success">
                <h4 v-html="successText" />
            </slot>
        </div>

        <slot name="after" />
    </div>
</template>

<script>
import AnimText from "objects/AnimText";
import AppInputText from "objects/AppInputText";

import Btn from "components/Shared/SharedBtn/SharedBtn";

export default {
    name: "AppForm",
    components: {
        AnimText,
        AppInputText,
        Btn
    },
    props: {
        formName: false,
        formTemplate: {
            type: String,
            required: true
        },
        inputs: {
            type: Array,
            default: () => [
                {
                    label: "fullName",
                    name: "fullName",
                    type: "text",
                    required: true,
                    autocomplete: "name"
                },
                {
                    label: "email",
                    name: "fromEmail",
                    type: "email",
                    disabled: false,
                    required: true,
                    autocomplete: "email"
                },
                {
                    label: "company",
                    name: "company",
                    type: "text",
                    required: false,
                    autocomplete: "company"
                },
                {
                    label: "job",
                    name: "job",
                    type: "text",
                    required: false,
                    autocomplete: "jobTitle"
                }
            ]
        },
        subject: {
            type: String,
            required: false,
            default: function() {
                return this.$t("form.email.subject");
            }
        },
        successText: {
            type: String,
            required: false,
            default: function() {
                return this.$t("form.messages.success");
            }
        },
        submitLabel: {
            type: String,
            required: false,
            default: function() {
                return this.$t("shared.button.submit");
            }
        },
        toEmail: {
            type: String,
            default: null
        }
    },
    data: () => ({
        data: {
            message: {}
        },
        error: false,
        success: false,
        pending: false,
        exceptions: ["fromName", "fromEmail", "subject", "attachment[]"]
    }),
    computed: {
        csrfName: () => window.csrfTokenName,
        csrfToken: () => window.csrfTokenValue,
        contactFullName() {
            return this.inputs.filter(i => i.name == "fullName")[0].value;
        }
    },
    created() {
        this.reset();
    },
    methods: {
        submitForm() {
            if (this.pending) {
                return;
            }

            // reset
            this.success = false;
            this.error = false;

            this.inputs.forEach((input, i) => {
                this.inputs[i].error = false;
            });

            this.pending = true;

            // Create form data
            const formData = new FormData();

            formData.append("action", "contact-form/send");

            // Add hidden inputs
            formData.append(this.csrfName, this.csrfToken);
            formData.append("fromName", this.contactFullName);
            formData.append("message[template]", this.formTemplate);

            if (this.toEmail !== null) formData.append("toEmail", this.toEmail);

            if (this.subject) {
                formData.append("subject", this.subject);
            }
            if (this.formName) {
                formData.append("message[formName]", this.formName);
            }

            this.inputs.forEach((input, i) => {
                if (typeof input.value !== "undefined") {
                    // Validation
                    if (input.value === "" && input.required) {
                        this.error = true;
                        this.pending = false;
                        this.inputs[i].error = true;
                    }

                    // Prepend input to formData
                    if (!this.exceptions.includes(input.name)) {
                        //data.message[fieldName] = input.value
                        formData.append(`message[${input.name}]`, input.value);
                    } else {
                        //data[fieldName] = input.value
                        formData.append(input.name, input.value);
                    }
                }
            });

            if (!this.error) {
                fetch("/contact-form/send", {
                    method: "POST",
                    body: formData
                })
                    .then(r => {
                        if (r.ok) {
                            return r.text();
                        } else {
                            this.pending = false;
                            this.error = true;
                            throw new Error("Something went wrong.");
                        }
                    })
                    .then(() => {
                        this.reset();

                        const FormWrap = document.querySelector(".c-app-footer-top-contact");
                        FormWrap.setAttribute("style", `height:${FormWrap.offsetHeight}px`);

                        this.success = true;
                        this.pending = false;

                        this.$emit("success");
                    })
                    .catch(e => {
                        console.error("Request failed", e); // eslint-disable-line
                    });
            }
        },
        reset() {
            this.success = false;
            this.inputs.forEach((input, i) => {
                input.value = "";
                this.inputs[i].error = false;
            });
        }
    }
};
</script>

<style lang="scss">
.c-form {
    --input-height: 4rem;

    --input-border-color: #{$color-dark};
    --input-border-radius: #{$border-radius-xs};

    --input-color-text: #{$color-dark};
    --input-color-bg: transparent;

    --input-disabled-color-text: $color-light;
    --input-disabled-color-bg: #{$color-light};

    &.is-sending {
        cursor: wait;
        pointer-events: none;
        opacity: 0.8;
    }
}

.c-form__error {
    padding: 1em;
    margin-bottom: 1em;
    background: $color-dark;
    color: $color-light;
}

.c-form__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 2;
    > div {
        @include pre-animation(0px, 30px);
    }
}

.c-form__heading {
    width: 100%;
    margin-bottom: 0.75em;

    &:not(:first-child) {
        margin-top: 2em;
    }
}

.c-form__el {
    // @include pre-animation(0px, 100px);
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: var(--grid-gutter);

    &.has-error {
        --input-border-color: red;
    }

    &.-submit {
        display: flex;
        padding-top: var(--grid-gutter-2X);
        margin-bottom: 0px;
    }

    @media #{md("md")} {
        &.-half {
            width: calc(50% - var(--grid-gutter-half));
        }
    }
}

.c-form__success {
    h4 {
        @include t4;
    }
}
</style>
