<template>
    <article class="c-card">
        <slot name="beforCard" />
        <div v-if="$slots.asset" class="c-card__asset">
            <slot name="cardAsset">
                {{ element.image }}
            </slot>
        </div>
        <div class="c-card__content">
            <slot name="beforeContent" />

            <card-content v-if="element" :element="element" :is-white="hasImage" />

            <slot name="afterContent" />

            <slot name="link">
                <div class="c-card__link">
                    <btn
                        tag="router-link"
                        :color="isButtonDark(hasImage)"
                        :color-hover="isHoverDark(!hasImage)"
                        :has-underline="true"
                        icon-after="arrow-right"
                        :label="$t('shared.button.learn-more')"
                        :href="`/${element.uri}`"
                    />
                </div>
            </slot>
        </div>
        <slot name="afterCard" />
        <div v-if="hasImage" class="c-card__img">
            <asset :asset="element.image" :cover="true" />
        </div>
    </article>
</template>

<script>
import Asset from "@/templates/objects/Asset";
import Btn from "@/templates/components/Shared/SharedBtn/SharedBtn";
import CardContent from "@/templates/components/Card/CardContent";
export default {
    name: "Card",
    components: {
        Asset,
        Btn,
        CardContent
    },
    props: {
        element: {
            type: Object,
            required: true
        }
    },
    computed: {
        hasImage() {
            if (!this.element) return false;
            return this.element.image ? true : false;
        }
    },
    methods: {
        isButtonDark(bool) {
            return bool ? "light" : "dark";
        },
        isHoverDark(bool) {
            return bool ? "gray-chateau" : "dark";
        }
    }
};
</script>

<style lang="scss">
.c-card {
    --card-aspect-ratio: 1/1.3;
    --card-width: 33.5rem;
    width: var(--card-width);
    position: relative;
    aspect-ratio: var(--card-aspect-ratio);
    padding: var(--grid-gutter);
    background: var(--color-concrete);

    @media #{md("lg")} {
        --card-width: 38.65rem;
    }

    &__img {
        @include full-screen-dom(absolute);
        z-index: 0;
        .o-asset {
            display: flex;
            flex: 1;
            height: 100%;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        z-index: 1;
    }
}
</style>
