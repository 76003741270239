<template>
    <div v-if="data && entries.length" class="b-scrollhack | wrap">
        <div class="b-scrollhack__inner">
            <block-scrollhack-observer
                v-if="isObserverVisible"
                :entries="entries"
                :is-square-blue="isSquareBlue"
                :is-running="isDisableScrollAnimationRunning"
                @enableScrollBack="enableScrollBack"
            />
            <block-scroll-hack-trigger v-else :entries="entries" :is-square-blue="isSquareBlue" />
            <div class="b-scrollhack__inner__trigger" ref="scrollhack" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import BlockScrollhackObserver from "blocks/BlockScrollhack/BlockScrollhackObserver";
import BlockScrollHackTrigger from "blocks/BlockScrollhack/BlockScrollHackTrigger/BlockScrollHackTrigger";

import disableScroll from "@/mixins/disableScroll.js";

export default {
    mixins: [disableScroll],

    components: {
        BlockScrollhackObserver,
        BlockScrollHackTrigger
    },

    props: {
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            breakpoint: 768,
            isObserverVisible: false
        };
    },
    computed: {
        ...mapState({
            services: state => state.services.services,
            steps: state => state.steps.steps
        }),
        type() {
            return this.data.type;
        },
        entries() {
            return this[this.type] || null;
        },
        isSquareBlue() {
            return this.data && this.data.type === "services";
        }
        // isObserverVisible() {
        //     return window.innerWidth > this.breakpoint;
        // }
    },
    watch: {
        entries() {
            this.setScrollTriggerWhenReady();
        }
    },
    created() {
        this.setObserverVisible();
    },
    mounted() {
        this.setScrollTriggerWhenReady();
        window.addEventListener("resizeEnd", this.resize);
    },
    beforeDestroy() {
        window.removeEventListener("resizeEnd", this.resize);
        this.destroyScrollTrigger()
    },

    methods: {
        setScrollTriggerWhenReady() {
            this.entries.length && this.isObserverVisible
                ? this.initScrollTrigger(this.$refs.scrollhack)
                : this.onDestroyDisableScroll();
        },
        setObserverVisible() {
            this.isObserverVisible = window.innerWidth > this.breakpoint;
        },
        resize() {
            this.setObserverVisible();
            this.setScrollTriggerWhenReady();
        }
    }
};
</script>

<style lang="scss" scoped>
.b-scrollhack {
    --scroll-hack-min-height: auto;
    &__inner {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: var(--scroll-hack-min-height);
        @media #{md("sm")} {
            --scroll-hack-min-height: 35rem;
        }

        &__trigger {
            // there is a bug if the trigger is located on the center when the user scroll back.
            position: absolute;
            width: 1rem;
            height: 1rem;
            top: calc(50% - 50vh);
            z-index: 999;
            // background-color: blue; // debug
        }
    }
}
</style>
