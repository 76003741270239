<template>
    <div class="b-approach" v-reveal.once>
        <div class="b-approach__container | wrap">
            <div class="b-approach__inner | bs-grid">
                <div class="-heading | col-12 col-7-sm col-6-md col-7-lg | start-1-lg">
                    <h2 class="t-t1" v-html="data.heading" />
                </div>
                <div class="-elements | col-16 col-10-sm col-8-lg | start-9-lg">
                    <BlockApproachElements :elements="data.elements" />
                </div>
                <div class="-image | col-15 col-7-lg">
                    <asset :asset="data.image[0]" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Asset from "objects/Asset";
import BlockApproachElements from "blocks/BlockApproach/BlockApproachElements";

export default {
    name: "BlockApproach",
    components: {
        Asset,
        BlockApproachElements
    },
    props: {
        data: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
$transition-approach: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
.b-approach {
    --b-safe-space: var(--grid-gutter-2X);
    --approach-opacity: 0;
    --translate-x-left-approach: -100px;
    --translate-x-right-approach: 100px;
    @include darkBackgroundTextSelect;
    @include transition(opacity 0.5s ease);
    color: var(--color-light);
    background-color: var(--color-dark);
    padding-top: var(--b-safe-space);

    opacity: var(--approach-opacity);

    @media #{md("lg")} {
        --bs-rows: 3;
        --b-safe-space: var(--grid-gutter-4X);
    }

    &.is-visible {
        --approach-opacity: 1;
        --translate-x-left-approach: 0px;
        --translate-x-right-approach: 0px;
    }

    .-heading {
        @include transition($transition-approach);
        @include transform(translateX(var(--translate-x-left-approach)));
        padding-bottom: var(--grid-gutter);
        opacity: var(--approach-opacity);
    }

    .-elements {
        @include transition($transition-approach);
        @include transform(translateX(var(--translate-x-right-approach)));
        grid-row: span 3;
        padding-bottom: var(--b-safe-space);
        opacity: var(--approach-opacity);
    }
    .-image {
        @include transition($transition-approach);
        @include transform(translateX(var(--translate-x-left-approach)));
        grid-row: span 2;

        display: flex;
        align-items: flex-end;
        opacity: var(--approach-opacity);
        @media #{md("md")} {
            margin-left: calc(var(--grid-gutter-2X) * -1);
        }
    }
}
</style>
