<template>
    <div class="c-hero-left | col-6 col-7-lg">
        <h1 v-if="copy">
            <anim-text
                :text="formattedCopy"
                :is-axis-y="false"
                :reveal="false"
                :is-visible="isTitleVisible"
                :chars="false"
                :lines="true"
                @completedIn="displayButton"
            />
        </h1>
        <div v-if="button" class="c-hero-left__button" :class="{ 'c-hero-left__button--visible': isButtonVisible }">
            <btn
                color="dark"
                :has-underline="true"
                overwrite-line="malibu"
                :label="button"
                href="/"
                tag="router-link"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import AnimText from "@/templates/objects/AnimText";
import Btn from "components/Shared/SharedBtn/SharedBtn";
export default {
    components: {
        AnimText,
        Btn
    },
    props: {
        button: {
            type: String,
            required: false,
            default: null
        },
        copy: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            isButtonVisible: false
        };
    },
    computed: {
        ...mapState({
            isTitleVisible: state => state.hero.isTitleVisible
        }),
        formattedCopy() {
            // splitText friendly
            return this.copy ? this.copy.toString().replace(/(<p>|<\/p>)/g, "") : null;
        }
    },
    methods: {
        displayButton() {
            this.toggleDisplayButton(true);
        },
        toggleDisplayButton(bool) {
            this.isButtonVisible = bool;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-hero-left {
    --spacing-hero-left: 0;

    align-self: flex-end;
    padding-bottom: var(--spacing-hero-left);

    @media #{md("sm")} {
        --spacing-hero-left: var(--grid-gutter-2X);
    }
    @media #{md("lg")} {
        --spacing-hero-left: var(--spacing-hero);
    }

    h1 {
        @include t-cms;

        @include t2;
        &::v-deep small {
            @include t4;
        }
    }
    &__button {
        --visible: 0;
        --translateX: -30px;
        @include transition(0.5s ease all);
        @include transform(translateX(var(--translateX)));
        margin-top: 30px;
        opacity: var(--visible);
        &--visible {
            --visible: 1;
            --translateX: 0px;
        }
    }
}
</style>
