import { render, staticRenderFns } from "./AppNavList.vue?vue&type=template&id=f70e8452&scoped=true&"
import script from "./AppNavList.vue?vue&type=script&lang=js&"
export * from "./AppNavList.vue?vue&type=script&lang=js&"
import style0 from "./AppNavList.vue?vue&type=style&index=0&id=f70e8452&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f70e8452",
  null
  
)

export default component.exports