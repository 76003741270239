<template>
    <div ref="blockAccordions" class="b-accordions | bs-grid" v-reveal.once>
        <div class="b-accordions__head | col-5 col-4-xl">
            <div ref="accordionCard" class="b-accordions__head__card">
                <user-card
                    v-bind:user="data.user[0]"
                    :beforeName="$t('shared.foundedBy')"
                    orientation="vertical"
                    :isLinkedinVisible="true"
                    :isRoleVisible="true"
                />
            </div>
            <div class="b-accordions__head__btn | bs-grid" ref="accordionBtn">
                <shared-btn-square
                    v-if="data.linkLabel && data.linkUrl[0].uri"
                    :label="data.linkLabel"
                    :href="data.linkUrl[0].uri"
                    icon-after="arrow-right--small"
                />
            </div>
        </div>

        <div class="b-accordions__content | start-1 end-1 col-10-md start-7-md col-9-xl start-6-xl" ref="accordionMain">
            <h2 v-html="data.heading" />
            <div class="b-accordions__content__desc t-cms">{{ data.description }}</div>
            <div v-if="data && data.accordions.length" class="b-accordions__accordions">
                <accordion
                    v-for="(accordion, i) in data.accordions"
                    :key="`b-accordions-${i}`"
                    :title="accordion.heading"
                    :openDefault="i === 0 && data.openFirst ? true : false"
                    class="b-accordions__accordions__item"
                >
                    <div v-html="accordion.content" />
                </accordion>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
import UserCard from "components/UserCard";
import Accordion from "components/Accordion";
import SharedBtnSquare from "components/Shared/SharedBtn/SharedBtnSquare";

export default {
    name: "BlockAccordions",
    components: {
        Accordion,
        UserCard,
        SharedBtnSquare
    },
    props: {
        data: false
    },
    data() {
        return {
            gsapAnimation: {
                timeline: null,
                durations: { duration1: 0.5 },
                ease: "power1.inOut"
            }
        };
    },
    mounted() {
        this.observer = new MutationObserver(mutations => {
            for (const m of mutations) {
                const newValue = m.target.getAttribute(m.attributeName);
                this.$nextTick(() => {
                    this.onClassChange(newValue, m.oldValue);
                });
            }
        });

        this.observer.observe(this.$refs.blockAccordions, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ["class"]
        });
    },
    beforeDestroy() {
        this.observer.disconnect();
        this.destroyTimeline();
    },
    methods: {
        onClassChange(classAttrValue) {
            const classList = classAttrValue.split(" ");
            if (classList.includes("is-visible")) {
                !this.gsapAnimation.timeline ? this.runTimeLine() : null;
            }
        },
        runTimeLine() {
            this.gsapAnimation.timeline = gsap.timeline({ paused: false });
            this.gsapAnimation.timeline
                .to(
                    ".b-accordions__content",
                    {
                        opacity: 1,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1 / 2
                    },
                    "start"
                )
                .to(
                    ".b-accordions__content",
                    {
                        y: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "start"
                )
                .to(".b-accordions__accordions__item", {
                    x: 0,
                    opacity: 1,
                    stagger: {
                        each: 0.1,
                        ease: this.gsapAnimation.ease
                    }
                });

            const accordionCard = this.$refs.accordionCard;
            const accordionButton = this.$refs.accordionBtn;
            accordionCard ? this.addAccordionTimeline(accordionCard) : null;
            accordionButton ? this.addAccordionButtonTimeline(accordionButton) : null;
        },
        addAccordionTimeline(target) {
            this.gsapAnimation.timeline
                .to(
                    target,
                    {
                        opacity: 1,
                        y: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "side"
                )
                .to(target, {
                    x: 0,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                });
        },
        addAccordionButtonTimeline(target) {
            this.gsapAnimation.timeline
                .to(
                    target,
                    {
                        opacity: 1,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1 / 2
                    },
                    "side"
                )
                .to(
                    target,
                    {
                        x: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "side"
                )
                .to(target, {
                    y: 0,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                });
        },
        ////////////////////////////////
        //       START DESTROY
        ////////////////////////////////
        destroyTimeline() {
            this.gsapAnimation.timeline
                ? (this.gsapAnimation.timeline.kill(), (this.gsapAnimation.timeline = null))
                : null;
        }
        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.block-accordions {
    @include wrap;
}

.b-accordions {
    &__head {
        @media #{md("md", "max")} {
            margin-left: var(--grid-gutter-minus);
            margin-right: var(--grid-gutter-minus);
        }

        &__btn {
            --bs-columns: 5;
            --btn-columns: span 3 / -1;

            @include pre-animation(100px, 100px); // animation

            @media #{md("lg")} {
                --bs-columns: 5;
                --btn-columns: span 3 / -1;
            }

            .c-shared-btn-square {
                grid-column: var(--btn-columns);
            }
        }
        &__card {
            @include pre-animation(-100px, -100px); // animation
        }
    }

    &__content {
        @include pre-animation(0px, 100px); // animation
        @media #{md("md")} {
            margin-left: var(--grid-gutter-minus);
        }

        @media #{md("sm", "max")} {
            grid-row: 2;
        }

        h2 {
            @include t2;

            &::v-deep strong {
                color: var(--color-malibu);
            }
        }

        &__desc {
            margin-top: var(--grid-gutter);
        }
    }

    &__accordions {
        counter-reset: accordions;
        &__item {
            @include pre-animation(100px, 0px); // animation
        }
    }
}
</style>
