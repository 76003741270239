<template>
    <div class="c-btn-icon" :style="setStyle">
        <icon class="c-btn-icon__svg" :icon="icon" />
        <icon class="c-btn-icon__svg" :icon="icon" />
    </div>
</template>

<script>
import Icon from "@/templates/objects/Icon"; // @/template because the short cut crash the test. Maybe I will come back to that later
export default {
    components: {
        Icon
    },

    props: {
        color: {
            type: String,
            required: false,
            default: "light",
            validator: val => ["light", "dark", "dark-lighten", "gray-chateau", "malibu"].includes(val)
        },
        colorHover: {
            type: String,
            required: false,
            default: "malibu",
            validator: val => ["light", "dark", "dark-lighten", "gray-chateau", "malibu"].includes(val)
        },
        icon: {
            type: String,
            default: null
        },
        isMouseHover: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        setStyle() {
            let setStyle = ` --color-hover: var(--color-${this.colorHover}); --color-btn: var(--color-${this.color});`;
            return setStyle;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-btn-icon {
    --color-btn: $color-light;
    --color-hover: $color-light;
    --fill-svg: var(--color-btn);
    --svg-width: 1.313em;
    --wrapped-clip-path: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    pointer-events: none;

    .c-btn:hover & {
        --wrapped-clip-path: 0%;
    }

    &__svg {
        margin-bottom: 0.4em;

        margin-left: var(--svg-spacing);

        &::v-deep svg {
            fill: var(--fill-svg);
        }

        &:nth-child(2) {
            @include transition(all 0.5s ease); // clip path transition isn't a thing
            --fill-svg: var(--color-hover);
            z-index: 2;
            position: absolute;
            clip-path: inset(0 var(--wrapped-clip-path) 0 0);
        }
    }
}
</style>
