<template>
    <div class="c-app-nav-desktop">
        <div class="c-app-nav-desktop__inner | bs-grid">
            <app-nav-list />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { gsap } from "gsap/all";

import AppNavList from "components/AppNav/AppNavList";
export default {
    components: {
        AppNavList
    },
    computed: {
        ...mapState({
            nav: state => state.global.navs
        })
    },
    data() {
        return {};
    },
    mounted() {
        this.$nextTick(() => {
            this.initMobileTimeline();
        });
    },
    watch: {
        nav() {
            this.$nextTick(() => {
                this.initMobileTimeline();
            });
        }
    },
    methods: {
        initMobileTimeline() {
            if (!this.nav.length || this.timeline) return;
            this.timeline = gsap.timeline({ paused: false });
            this.timeline.to(".c-app-nav-desktop__inner .c-navigation-link__link", {
                opacity: 1,
                y: 0,
                ease: "power1.inOut",
                duration: 0.5,
                stagger: 0.2,
                delay: 0.5
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.c-app-nav-desktop {
    --display-nav-mobile: none;
    --button-padding: 0rem 0.1rem;

    display: var(--display-nav-mobile);
    height: auto;

    @media #{md("sm")} {
        --display-nav-mobile: flex;
    }

    &__inner {
        --nav-direction: column;

        display: flex;
        flex-direction: var(--nav-direction);

        @media #{md("sm")} {
            --nav-margin-top: 0rem;
        }
    }
}
</style>
