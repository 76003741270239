<template>
    <div class="c-shared-thumbnail">
        <asset :title="alt" :asset="asset" />
    </div>
</template>

<script>
import Asset from "@/templates/objects/Asset";
export default {
    components: {
        Asset
    },
    props: {
        asset: {
            type: Object,
            required: false,
            default: null
        },
        alt: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-thumbnail {
    @include radius(50%);
    max-width: 6rem;
    overflow: hidden;
}
</style>
