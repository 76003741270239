// State
const state = {
    isNavOpen: false,
    isLogoVisible: false
};

// Getters
const getters = {};

// Actions
const actions = {
    toggleNav(store, bool) {
        store.commit("updateNavState", bool);
    },
    toggleLogoVisibility(store, bool) {
        store.commit("updateLogoVisibility", bool);
    }
};

// Mutations
const mutations = {
    updateNavState(state, bool) {
        state.isNavOpen = bool;
    },
    updateLogoVisibility(state, bool) {
        state.isLogoVisible = bool;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
