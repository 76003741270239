/*
 * Loader
 */

// State
const state = {
    loading: 0,
    isFirstLoad: true,
    isTargetSquareReady: false
};

// Getters
const getters = {
    isLoading: state => state.loading > 0,
    loadCount: state => state.loading
};

// Actions
const actions = {
    startLoad(store) {
        store.commit("updateLoad", store.state.loading + 1);
    },
    endLoad(store) {
        store.commit("updateLoad", store.state.loading - 1);
    },
    resetLoad(store) {
        store.commit("updateLoad", 0);
    },
    firstLoaded(store) {
        store.commit("setFirstLoad", false);
    },
    targetSquaredIsLoaded(store) {
        store.commit("toggleIsTargetLoaded", true);
    },
    resetTargetSquaredIsLoaded(store) {
        store.commit("toggleIsTargetLoaded", false);
    }
};

// Mutations
const mutations = {
    updateLoad(state, value) {
        // Uncomment for debug
        //console.log('updateLoad', value)
        state.loading = value;
    },
    setFirstLoad(state, isFirstLoaded) {
        state.isFirstLoad = isFirstLoaded;
    },
    toggleIsTargetLoaded(state, bool) {
        state.isTargetSquareReady = bool;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
