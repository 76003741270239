<template>
    <user-card
        v-if="user"
        :user="user"
        size="medium"
        orientation="horizontal"
        :isRoleVisible="true"
        :isLinkedinVisible="true"
        :isDarkMode="true"
    />
</template>

<script>
import { mapState } from "vuex";
import UserCard from "components/UserCard";

export default {
    components: {
        UserCard,
    },
    computed: {
        ...mapState({
            defaultUser: (state) => state.global.settings.defaultUser,
        }),
        user() {
            return this.defaultUser && this.defaultUser.length ? this.defaultUser[0] : null;
        },
    },
};
</script>

<style lang="scss" scoped></style>
