import snippets from "./snippets";
//import getAsset from './assets'

/*
 * Craft GraphQL Queries
 */

/**********************************
 *
 *
 *  GENERAL
 *
 *
 **********************************/

// Main query
export const general = `{
    navs: globalSet(handle: "navs") {
        ... on navs_GlobalSet {
            navNodes {
                ...on navNodes_BlockType {
                    entry {
                        ${snippets.defaultData}
                        title
                        section: sectionHandle
                    }
                    label
                }
            }
        }
    }
    forms: globalSet(handle: "formConfirmations") {
        ... on formConfirmations_GlobalSet {
            success: body3
        }
    }
    settings: globalSet(handle: "settings") {
        ... on settings_GlobalSet {
            address
            ${snippets.map}
            defaultUser: ${snippets.relationUser}
            phone
            email
        }
    }
}`;

// Steps
export const step = (slug) => `{
    entry(section: "step", slug: "${slug}") {
        ${snippets.step}
    }
}`;

// Services
export const service = (slug) => `{
    entry(section: "service", slug: "${slug}") {
        ${snippets.service}
    }
}`;

/**********************************
 *
 *
 *  SEARCH
 *
 *
 **********************************/

export const categoryGroup = `query Categories($group: [String!], $relations: [EntryCriteriaInput]){
    categories(group: $group, relatedToEntries: $relations) {
        id
        title
        handle: slug
        ...on articleTypes_Category {
            plural: label
        }
    }
}
`;

export const entryCount = `query entryCount($section: [String!], $categories: [CategoryCriteriaInput]) {
    entryCount(section: $section, relatedToCategories: $categories)
}`;

export const searchEntries = (section, options) => {
    if (section == undefined) {
        throw "A section is required to perform the searchEntries query";
    }

    let appendQuery = "";

    if (options.relatedToCategories && options.relatedToCategories.length) {
        let relatedToCategories = [];

        options.relatedToCategories.forEach((categ) => {
            let category = `{
                group: ["${categ.group}"],
                id: [${categ.id}]
           }`;

            relatedToCategories.push(category);
        });

        appendQuery += `, relatedToCategories: [${relatedToCategories}]`;
    }
    if (options.searchText) {
        appendQuery += `, search: "${options.searchText}"`;
    }

    if (options.fixedOrder) {
        appendQuery += `, fixedOrder: ${options.fixedOrder}`;
    } else {
        let orderKey = options.order ? options.order.key : "postDate",
            orderDirection = options.order ? options.order.direction : "DESC";

        let orderBy = `${orderKey} ${orderDirection}`;
        appendQuery += `, limit: ${options.limit || 100}, offset: ${options.offset || 0}`;
        appendQuery += `, orderBy: "${orderBy}"`;
    }

    if (options.ids) {
        let _ids = options.ids.map((id) => parseInt(id));

        _ids.forEach((id) => {
            if (isNaN(id)) throw "ids should be of type Number";
        });
        appendQuery += `, id: [${_ids}]`;
    }

    return `{
        totalCount: entryCount(section: "${section}")
        resultsCount: entryCount(section: "${section}" ${appendQuery})
        entries(section: "${section}", ${appendQuery}) {
            ${snippets.entry}

        }
    }`;
};

/**********************************
 *
 *
 *  CONTENT
 *
 *
 **********************************/

// Homepage
export const home = `{
    entry(section: "home") {
        ${snippets.defaultData}
        section: sectionHandle

        ...on home_home_Entry {
            heading
            headerType
            ${snippets.layout}
            ${snippets.seo}
        }
    }
}`;

// Pages
export const page = (slug) => `{
    entry(section: "page", slug: "${slug}") {
        ${snippets.page}
    }
}`;

// Articles
export const articles = `{
    entry(section: "articles") {
        ${snippets.entry}

        ...on articles_articles_Entry {
            heading
            headerType
            ${snippets.seo}
        }
    }
}`;

export const article = (slug) => `{
    entry(section: "article", slug: "${slug}") {
        ${snippets.article}
    }
}`;

export default {
    // GENERAL
    general,

    // SEARCH
    categoryGroup,
    entryCount,
    searchEntries,

    // CONTENT
    home,
    page,
    articles,
    article,
};
