<template>
    <div>
        <hero :copy="page.heading" :header-type="page.headerType" />
        <app-elements
            v-if="articlesCount"
            :filterGroups="filterGroups"
            :articlesCount="articlesCount"
            sectionHandle="article"
            ref="elements"
        >
            <template #paginationText>
                {{ $t("articles.pagination.type") }}
            </template>
        </app-elements>
    </div>
</template>

<script>
import { SECTION_HANDLES } from 'src/constants';
import Hero from "components/Hero/Hero";
import Card from "components/Card/Card.vue";
import AppElements from "layout/AppElements/AppElements";

export default {
    name: "Articles",
    components: {
        Hero,
        AppElements,
        Card,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null,
        };
    },
    props: {
        page: Object,
    },
    data() {
        return {
            SECTION_HANDLES,
            isReady: false,
            filterGroups: [
                {
                    group: "articleTypes",
                    type: "category",
                },
            ],
            articlesCount: null,
        };
    },
    created() {
        this.$store.dispatch("article/fetchCount").then((r) => (this.articlesCount = r));
    },
};
</script>

<style lang="scss">
.p-articles {
}
</style>
