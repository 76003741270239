<template>
    <tag
        :is="tag"
        :href="tag == 'a' && href !== null ? href : false"
        :to="tag == 'router-link' && href !== null ? href : false"
        :rel="tag == 'a' ? 'noopener' : false"
        :class="className"
        :aria-disabled="isDisabled"
        :target="hrefTarget"
    >
        <span class="c-link__label">
            <slot>{{ label }}</slot>
        </span>
        <sup v-if="count !== null" class="c-link__count" v-html="count" />
    </tag>
</template>

<script>
export default {
    name: "BtnLink",
    props: {
        //--- start base ---//
        tag: {
            type: String,
            default: "button"
        },
        href: {
            type: String,
            default: null
        },
        label: {
            type: String | Number,
            default: "Button Label"
        },
        color: {
            type: String,
            required: false,
            default: "dark",
            validator: val => ["light", "dark", "dark-lighten", "gray-chateau", "malibu"].includes(val)
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isTargetBlank: {
            type: Boolean,
            required: false,
            default: false
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false
        },
        //--- end base ---//

        //--- start count related props ---//
        count: {
            type: String | Number,
            default: null
        }
        //--- end count related props ---//
    },
    computed: {
        className() {
            let classname = "c-link";

            if (this.isActive) {
                classname += ` c-link--active`;
            }

            return classname;
        },
        hrefTarget() {
            return this.tag === "a" ? this.isAnchorTargetBlank() : null;
        }
    },
    methods: {
        isAnchorTargetBlank() {
            return this.isTargetBlank ? "_blank" : "_self";
        }
    }
};
</script>

<style lang="scss" scoped>
.c-link {
    --color-btn: var(--color-gray-chateau);

    @include disabler;
    @include transition(color 0.5s ease);
    display: inline-flex;
    align-items: center;
    color: var(--color-btn);
    padding-bottom: 1.1rem;
    cursor: pointer;

    &__label {
        @include t3;
        line-height: 0.55;
    }

    &__count {
        line-height: 1;
        top: -0.1rem;
        margin-left: 0.6rem;
    }

    &:after {
        @include pseudo-el;
        @include transition(all 0.4s $in-out-quad);
        --line-opacity: 0;
        --line-translateY: -0.4rem;
        height: 2px;
        position: absolute;
        top: 100%;
        left: 0;
        opacity: var(--line-opacity);
        background: var(--color-btn);
        transform: translateY(var(--line-translateY));
    }

    // Active
    &:hover,
    &--active {
        --color-btn: var(--color-dark);

        &:after {
            --line-opacity: 1;
            --line-translateY: 0;
        }
    }
}
</style>
