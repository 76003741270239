<template>
    <div v-if="data" class="b-content | wrap" v-reveal.once>
        <div ref="content" class="b-content__inner | bs-grid" :style="valign" v-html="editedContent" />
    </div>
</template>

<script>
import { ScrollTrigger } from "gsap/all";

export default {
    name: "BlockContent",
    props: {
        data: false
    },
    data() {
        return {
            editedContent: null
        };
    },
    methods: {
        async refreshTrigger() {
            const content = (await this.$refs.content) || this.$el.querySelector(".b-content__inner");

            if (content) {
                const images = content.querySelectorAll("img");
                if (images.length) {
                    images.forEach(img => {
                        img.addEventListener("load", () => {
                            ScrollTrigger.refresh();
                        });
                    });
                }
            }
        }
    },
    computed: {
        valign() {
            return {
                "--valign": this.data.verticalAlignment
            };
        }
    },
    watch: {
        data: {
            handler: "refreshTrigger",
            immediate: true
        }
    },
    beforeMount() {
        // Divide content in columns
        const columnOpeningDiv = "<div class='b-content__column | t-cms'>";
        const columnClosingDiv = "</div>";
        this.editedContent = columnOpeningDiv + this.data.content + columnClosingDiv;
        this.editedContent = this.editedContent.replace(new RegExp("<hr />", "g"), columnClosingDiv + columnOpeningDiv);
    }
};
</script>

<style lang="scss">
.b-content {
    @include pre-animation(0px, 100px);
    &.is-visible {
        @include transform(translate(0px, 0px));
        opacity: 1;
        transition: 0.5s ease transform, 0.3s linear opacity;
    }
    &__inner {
        row-gap: $line-height * 1em;
        align-items: var(--valign, start);
    }

    &__column {
        --only-column: 1 / -1;
        --left-column: var(--only-column);
        --right-column: var(--only-column);

        figure {
            --figure-width: 100%;
            --figure-margin-left: 0px;
            width: var(--figure-width);
            margin-left: var(--figure-margin-left);
            @media #{md("xs")} {
                --figure-width: 100%;
                --figure-margin-left: 0px;
            }
        }

        &:nth-child(2n -1) {
            grid-column: var(--left-column);
        }
        &:nth-child(2n) {
            grid-column: var(--right-column);
        }
        &:only-child {
            grid-column: var(--only-column);
        }

        @media #{md("xs")} {
            --only-column: 2 / span 10;
        }

        @media #{md("md")} {
            --only-column: 5 / span 8;
            --left-column: 1 / span 7;
            --right-column: 9 / span 7;
        }
    }
}
</style>
