// State
const state = {};

// Getters
const getters = {};

// Actions
const actions = {};

// Mutations
const mutations = {};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
