<template>
    <section class="b-scroll-hack-trigger">
        <ul class="b-scroll-hack-trigger__list">
            <block-scroll-hack-trigger-item
                v-for="(entry, i) in entries"
                :key="`sroll-hack-trigger-${entry.slug}-${i}`"
                :id="entry.slug"
                :entry="entry"
                :entry-index="i"
                :is-square-blue="isSquareBlue"
            />
        </ul>
    </section>
</template>

<script>
import BlockScrollHackTriggerItem from "blocks/BlockScrollhack/BlockScrollHackTrigger/BlockScrollHackTriggerItem.vue";
export default {
    components: {
        BlockScrollHackTriggerItem
    },
    props: {
        entries: {
            type: Array,
            required: false,
            default: () => []
        },
        isSquareBlue: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.b-scroll-hack-trigger {
    &__list {
        @include reset-list;
        display: flex;
        flex-direction: column;
        counter-reset: scroll-hack-counter;
    }
}
</style>
