<template>
    <component v-if="text" :is="cms ? 'div' : tag" :class="className" v-html="text" />

    <!-- I dont need it forv-reveal.once="reveal ? revealHandle : false" -->
</template>

<script>
import { gsap, SplitText } from "gsap/all";
gsap.registerPlugin(SplitText);

export default {
    name: "AnimText",
    props: {
        text: {
            type: String | Number,
            default: null
        },
        tag: {
            type: String,
            default: "span"
        },
        reveal: {
            type: Boolean,
            default: true
        },
        isVisible: {
            type: Boolean,
            default: false
        },
        cms: {
            type: Boolean,
            default: false
        },
        chars: {
            type: Boolean,
            default: false
        },
        word: {
            type: Boolean,
            default: false
        },
        lines: {
            type: Boolean,
            default: false
        },
        isAxisY: {
            type: Boolean,
            required: false,
            default: true
        },

        options: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            split: null,
            opts: {
                inDuration: 1,
                inDelta: 1,
                idDelay: 0,
                outDuration: 0.8,
                outDelta: 1,
                outDelay: 0
            }
        };
    },
    created() {
        this.opts = { ...this.opts, ...this.options };
    },
    mounted() {
        this.init();
    },
    computed: {
        className() {
            let classname = "o-at";

            if (this.reveal) {
                classname += " js-reveal";
            }

            if (this.isVisible) {
                classname += " is-visible";
            }

            if (this.cms) {
                classname += " t-cms";
            }

            if (this.chars) {
                classname += " -chars";
            }

            return classname;
        },
        splitItems() {
            return this.chars ? this.split.chars : this.isSplitedEachWordOrByLine();
        }
    },
    watch: {
        isVisible(bool) {
            bool ? this.show() : this.hide();
        }
    },
    methods: {
        isSplitedEachWordOrByLine() {
            return this.word ? this.split.words : this.split.lines;
        },

        init() {
            if (this.cms) {
                return;
            }

            //======= START INIT SPLITEXT =======//

            const type = this.chars ? "lines,chars" : "lines,words";

            this.split = new SplitText(this.$el, {
                type,
                charsClass: "o-at__c",
                wordsClass: "o-at__w",
                linesClass: "o-at__l",
                reduceWhiteSpace: false
            });

            let outStagger = 0;
            if (this.chars || this.lines) {
                outStagger = {
                    each: 0.04,
                    from: "start",
                    axis: this.lines ? this.whichStaggerAxis() : null
                };
            }

            // Initial hide
            gsap.set(this.splitItems, { opacity: 0 });

            //======= END INIT SPLITEXT =======//

            //======= START TIMELINE =======//

            // Set timeline
            this.tl = gsap
                .timeline({ paused: true, onComplete: () => {} })
                .addLabel("show")
                .set(this.splitItems, {
                    opacity: 0,
                    xPercent: this.isAxisY ? 0 : -this.opts.inDelta * 10,
                    yPercent: this.isAxisY ? this.opts.inDelta * 100 : 0,
                    delay: this.opts.inDelay
                })
                .to(this.splitItems, {
                    duration: this.opts.inDuration,
                    xPercent: 0,
                    yPercent: 0,
                    opacity: 1,
                    stagger: {
                        each: this.isAxisY ? 0.04 : 0.1,
                        // from: this.chars ? "start" : "end",

                        axis: this.lines ? this.whichStaggerAxis() : null
                    },
                    ease: this.chars ? "power3.inOut" : "power3.inOut",
                    overwrite: "all"
                })
                .add(() => {
                    this.emitToParent("completedIn");
                })
                .addPause()
                .addLabel("hide")
                .to(this.splitItems, {
                    duration: this.opts.outDuration,
                    delay: this.opts.outDelay,
                    opacity: 0,
                    stagger: outStagger,
                    xPercent: this.isAxisY ? 0 : -this.opts.outDelta * 11,
                    yPercent: this.isAxisY ? this.opts.outDelta * 130 : 0,
                    ease: "power3.in"
                    // overwrite: 'all',
                })
                .set(this.splitItems, {
                    opacity: 0
                })
                .add(() => {
                    this.emitToParent("completedOut");
                });

            this.isVisible ? this.show() : null;
        },

        //======= END TIMELINE =======//

        /*------------------------------
    Start methods for the timeline
    ------------------------------*/
        whichStaggerAxis() {
            return this.isAxisY ? "y" : "x";
        },
        emitToParent(emitName) {
            this.$emit(emitName);
        },

        /*------------------------------
    End methods for the timeline
    ------------------------------*/

        /*------------------------------
    Start Show/Hide/Reveal the timeline
    ------------------------------*/
        revealHandle(state) {
            if (state.isActive) {
                this.show();
            } else {
                this.hide();
            }
        },
        show() {
            if (this.cms) {
                return;
            }

            this.tl.play("show");
        },
        hide() {
            if (this.cms) {
                return;
            }

            this.tl.play("hide");
        }
        /*------------------------------
    End Show/Hide/Reveal the timeline
    ------------------------------*/
    }
};
</script>

<style lang="scss">
.o-at {
    display: block;

    &.-chars {
        .o-at__l {
            margin-top: 0;
            padding-top: 0;
        }
    }

    html.reduced-motion & {
        &.t-cms > *,
        .o-at__w {
            transition: none !important;
        }
    }
}
.o-at__l {
    margin-top: -0.2em;
    padding-top: 0.2em;
    margin-bottom: -0.4em;
    padding-bottom: 0.2em;
    overflow: hidden;

    // display the text above the decorative image
    z-index: 3;
}

.-last-line-above {
    .o-at__l {
        z-index: 1;
        &:last-child {
            z-index: 3;
        }
    }
}

.-first-last-line-below {
    .o-at__l {
        z-index: 3;
        &:first-child,
        &:last-child {
            z-index: 1;
        }
    }
}

.o-at__c,
.o-at__w {
    transform-origin: 0 100%;
    will-change: transform;
}
</style>
