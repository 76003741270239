<template>
    <div v-if="data" class="b-steps | wrap" ref="blockSteps" v-reveal.once>
        <div class="b-steps__inner">
            <!-- block copy -->
            <div class="b-steps__inner__intro | start-1 end-1">
                <h1 v-html="data.heading" class="b-steps__inner__intro__copy" />
                <p class="b-steps__inner__intro__copy">{{ data.description }}</p>
            </div>

            <!-- our approche components -->
            <block-steps-grid
                :page="data.page[0]"
                :label="data.label"
                :is-animation-running="gsapAnimation.isRunning"
            />
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
import BlockStepsGrid from "blocks/BlockSteps/BlockStepsGrid";
export default {
    components: {
        BlockStepsGrid
    },
    props: {
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            gsapAnimation: {
                timeline: null,
                durations: { duration1: 0.5 },
                ease: "power1.inOut",
                isRunning: false
            }
        };
    },
    mounted() {
        this.observer = new MutationObserver(mutations => {
            for (const m of mutations) {
                const newValue = m.target.getAttribute(m.attributeName);
                this.$nextTick(() => {
                    this.onClassChange(newValue, m.oldValue);
                });
            }
        });

        this.observer.observe(this.$refs.blockSteps, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ["class"]
        });
    },
    beforeDestroy() {
        this.observer.disconnect();
        this.destroyTimeline();
    },
    methods: {
        ////////////////////////////////
        //       START TRIGGER ANIMATION
        ////////////////////////////////
        onClassChange(classAttrValue) {
            const classList = classAttrValue.split(" ");
            if (classList.includes("is-visible")) {
                !this.gsapAnimation.timeline ? this.runTimeLine() : null;
            }
        },
        ////////////////////////////////
        //       END TRIGGER ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        runTimeLine() {
            this.toggleTimelineIsRunning(true);
            this.gsapAnimation.timeline = gsap.timeline({ paused: false });
            this.gsapAnimation.timeline.to(".b-steps__inner__intro__copy", {
                x: 0,
                opacity: 1,
                stagger: {
                    each: 0.5,
                    ease: this.gsapAnimation.ease
                }
            });
        },
        toggleTimelineIsRunning(bool) {
            this.gsapAnimation.isRunning = bool;
        },

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////
        ////////////////////////////////
        //       START DESTROY
        ////////////////////////////////
        destroyTimeline() {
            this.gsapAnimation.timeline
                ? (this.gsapAnimation.timeline.kill(), (this.gsapAnimation.timeline = null))
                : null;
        }
        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.b-steps {
    display: flex;
    flex-direction: column;

    &__inner {
        &__intro {
            --block-action-h1-max-width: 770px;
            --block-action-p-max-width: 500px;
            h1 {
                @include t1;
                @include b-for-blue;
                margin-bottom: var(--grid-gutter-2X);
                max-width: var(--block-action-h1-max-width);
            }
            p {
                @include fontCopy;
                max-width: var(--block-action-p-max-width);
            }
            &__copy {
                @include pre-animation(-100px, 0px); // animation
            }
        }
    }
}
</style>
