<template>
    <div class="l-builder">
        <section v-for="(section, i) in sections" class="-s" :key="`section-${i}`" :id="sectionId(section)">

            <div class="-s__title" v-if="section.label && section.displayLabel">
                <span class="t-t4">{{ section.label }}</span>
            </div>

            <template v-if="section.children.length > 0">
                <div
                    v-for="(block, i) in section.children"
                    :key="`block-${i}`"
                    class="-b"
                    :class="block.typeHandle | snake"
                >
                    <component :is="block.typeHandle" :data="block" />
                </div>
            </template>

        </section>
    </div>
</template>

<script>
import BlockAccordions from "blocks/BlockAccordions";
import BlockApproach from "blocks/BlockApproach/BlockApproach";
import BlockSteps from "blocks/BlockSteps/BlockSteps";
import BlockArticles from "blocks/BlockArticles/BlockArticles";
import BlockContent from "blocks/BlockContent";
import BlockGallery from "blocks/BlockGallery";
import BlockImage from "blocks/BlockImage";
import BlockScrollhack from "blocks/BlockScrollhack/BlockScrollhack";
import BlockSpacer from "blocks/BlockSpacer";
import BlockTagline from "blocks/BlockTagline";
import BlockReferences from "blocks/BlockReferences/BlockReferences";

import { toKebab } from "src/utils";

export default {
    name: "AppBuilder",
    props: {
        sections: {
            type: Array,
            default: () => []
        }
    },
    components: {
        BlockAccordions,
        BlockApproach,
        BlockSteps,
        BlockArticles,
        BlockContent,
        BlockGallery,
        BlockImage,
        BlockScrollhack,
        BlockSpacer,
        BlockTagline,
        BlockReferences
    },
    methods: {
        sectionId(section) {
            return `section-${toKebab(section.label) || section.id}`;
        }
    }
};
</script>

<style lang="scss">
.l-builder {
    --section-spacer-top: 10rem;
    --section-spacer-bottom: 10rem;
    --block-spacer: 10rem;

    // Sections
    .-s {
        &:not(:first-of-type) {
            padding-top: var(--section-spacer-top);
        }
        &:not(:last-of-type) {
            padding-bottom: var(--section-spacer-bottom);
        }

        // Custom spacings on homepage
        &#section-approche {
            --section-spacer-top: 0;
            --section-spacer-bottom: 8.5rem;
        }
        &#section-distinction {
            --section-spacer-top: 0;
            @media #{md("lg", "max")} {
                --section-spacer-bottom: 0;
            }
        }
        &#section-reflexions {
            --section-spacer-top: 8.5rem;
            --section-spacer-bottom: 0;
        }
        &#section-references {
            @media #{md("lg")} {
                --section-spacer-top: 3rem;
            }
        }
    }

    // Blocks
    .-b {
        &:not(:first-of-type) {
            margin-top: var(--block-spacer);
        }

        &.block-tagline + .block-content {
            --block-spacer: 3rem;
        }
    }
}
</style>
