<template>
    <div class="c-app-nav-list" :class="{ 'c-app-nav-list--mobile': isMobile }">
        <nav
            v-if="filteredNav"
            class="c-app-nav-list__inner"
            itemscope
            itemtype="http://schema.org/SiteNavigationElement"
        >
            <ul class="c-app-nav-list__inner__list">
                <app-nav-link v-for="(item, i) in filteredNav" :key="i" itemprop="name" :item="item" />
                <li class="c-navigation-link" itemprop="name">
                    <button class="c-navigation-link__link" :title="$t('footer.nav-label')" v-on:click="scrollToFooter">
                        {{ $t("footer.nav-label") }}
                    </button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { mapState } from "vuex";

import AppNavLink from "components/AppNav/AppNavLink";

import { gsap } from "gsap/all";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export default {
    components: {
        AppNavLink
    },
    computed: {
        ...mapState({
            nav: state => state.global.navs
        }),
        filteredNav() {
            return this.nav;
        }
    },
    props: {
        isMobile: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        scrollToFooter() {
            const elem = document.querySelector("#footer");
            const elemStyles = getComputedStyle(elem);

            this.$store.dispatch("navigation/toggleNav", false);

            gsap.to(window, {
                duration: 2,
                scrollTo: {
                    y: elem,
                    offsetY: parseInt(elemStyles.getPropertyValue("margin-top"), 10)
                },
                ease: "power4.inOut"
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.c-app-nav-list {
    --nav-list-width: 100%;
    --nav-list-direction: column;
    --nav-list-height: 100%;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    width: var(--nav-list-width);
    height: var(--nav-list-height);

    @media #{md("sm")} {
        --nav-list-direction: row;
        --nav-list-width: auto;
        --nav-list-height: auto;
    }

    &__inner {
        --nav-list-width: 100%;
        width: var(--nav-list-width);

        @media #{md("sm")} {
            --nav-list-width: auto;
        }

        &__list {
            --nav-list-gap: var(--grid-gutter);
            @include reset-list;
            display: flex;
            flex-direction: var(--nav-list-direction);
            padding-right: var(--nav-list-gap);
            text-align: center;

            .c-app-nav-desktop & {
                --nav-list-gap: var(--grid-gutter-2X);
            }

            @media #{md("sm")} {
                --nav-list-gap: var(--grid-gutter-half);
            }

            @media #{md("sm", "max")} {
                padding: var(--nav-list-gap) 0;
            }
        }
    }
}
</style>
