<template>
    <svg width="549" height="140" viewBox="0 0 549 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            class="logo-square"
            d="M518.201 29.5378L548.609 29.5378V1.90735e-06L518.201 1.90735e-06V29.5378Z"
            fill="#0C162A"
        />
        <path
            class="logo-letter -letter-t"
            d="M507.745 39.4875V19.554H507.71L492.395 24.4036V39.453L481.965 39.4357L481.957 47.0639L492.395 47.0812V108.616H507.745V47.1071L527.111 47.1416V47.1243L527.12 39.5134L507.745 39.4875Z"
            fill="#0C162A"
        />
        <path
            class="logo-letter -letter-a-last"
            d="M457.455 40.7906V48.2117C452.25 43.1117 445.603 39.6288 438.357 38.2049C431.11 36.7811 423.592 37.4806 416.756 40.2145C409.919 42.9484 404.073 47.5934 399.959 53.5603C395.845 59.5271 393.648 66.5469 393.648 73.7284C393.648 80.9098 395.845 87.9294 399.959 93.8962C404.073 99.8631 409.919 104.508 416.756 107.242C423.592 109.976 431.11 110.676 438.357 109.252C445.603 107.828 452.25 104.345 457.455 99.2448V108.616H472.806V35.9495L457.455 40.7906ZM433.212 97.2687C428.419 97.2687 423.734 95.8882 419.749 93.3015C415.763 90.7148 412.657 87.0383 410.823 82.7368C408.989 78.4353 408.509 73.7021 409.444 69.1357C410.379 64.5692 412.687 60.3747 416.076 57.0825C419.465 53.7903 423.784 51.5484 428.484 50.6401C433.185 49.7318 438.058 50.1979 442.486 51.9796C446.915 53.7613 450.7 56.7787 453.362 60.6499C456.025 64.5211 457.447 69.0725 457.447 73.7284C457.439 79.9696 454.884 85.9532 450.341 90.3664C445.798 94.7796 439.637 97.2619 433.212 97.2687Z"
            fill="#0C162A"
        />
        <path
            class="logo-letter  -letter-r"
            d="M366.382 37.7357L351.067 42.5767V108.616H366.426V52.6124H392.962V37.7271C383.037 37.7426 373.513 41.5307 366.426 48.2807L366.382 37.7357Z"
            fill="#0C162A"
        />
        <path
            class="logo-letter -letter-d"
            d="M325.34 24.4035V46.6756C319.972 42.0127 313.328 38.9588 306.212 37.8825C299.096 36.8062 291.811 37.7535 285.237 40.6101C278.662 43.4667 273.08 48.1104 269.163 53.981C265.245 59.8515 263.161 66.6979 263.161 73.6938C263.161 80.6896 265.245 87.5359 269.163 93.4064C273.08 99.2769 278.662 103.921 285.237 106.777C291.811 109.634 299.096 110.581 306.212 109.505C313.328 108.429 319.972 105.375 325.34 100.712V108.582H340.691V19.5281L325.34 24.4035ZM301.95 96.4576C297.322 96.4576 292.798 95.1246 288.95 92.6271C285.103 90.1296 282.103 86.5797 280.332 82.4264C278.561 78.2732 278.098 73.7031 279.001 69.294C279.904 64.885 282.132 60.8349 285.405 57.6562C288.677 54.4774 292.846 52.3126 297.385 51.4356C301.924 50.5586 306.629 51.0086 310.905 52.7289C315.18 54.4492 318.835 57.3627 321.406 61.1005C323.977 64.8383 325.349 69.2329 325.349 73.7283C325.342 79.7544 322.875 85.5316 318.488 89.7927C314.101 94.0538 308.154 96.4507 301.95 96.4576V96.4576Z"
            fill="#0C162A"
        />
        <path
            class="logo-letter -letter-a-first"
            d="M240.92 40.7906V48.2117C235.715 43.1117 229.068 39.6288 221.821 38.2049C214.575 36.7811 207.057 37.4806 200.22 40.2145C193.384 42.9484 187.538 47.5934 183.424 53.5603C179.31 59.5271 177.113 66.5469 177.113 73.7284C177.113 80.9098 179.31 87.9294 183.424 93.8962C187.538 99.8631 193.384 104.508 200.22 107.242C207.057 109.976 214.575 110.676 221.821 109.252C229.068 107.828 235.715 104.345 240.92 99.2448V108.616H256.271V35.9495L240.92 40.7906ZM216.686 97.2687C211.893 97.2687 207.208 95.8882 203.222 93.3015C199.237 90.7148 196.131 87.0383 194.297 82.7368C192.462 78.4353 191.982 73.7021 192.917 69.1357C193.853 64.5692 196.161 60.3747 199.55 57.0825C202.939 53.7903 207.257 51.5484 211.958 50.6401C216.659 49.7318 221.532 50.1979 225.96 51.9796C230.388 53.7613 234.173 56.7787 236.836 60.6499C239.499 64.5211 240.92 69.0725 240.92 73.7284C240.913 79.9696 238.358 85.9532 233.814 90.3664C229.271 94.7796 223.111 97.2619 216.686 97.2687V97.2687Z"
            fill="#0C162A"
        />
        <path
            class="logo-letter -letter-u"
            d="M129.13 109.988C118.142 109.988 107.603 105.748 99.8332 98.2003C92.0631 90.6526 87.6978 80.4157 87.6978 69.7416V40.7991L103.013 35.9495V70.2162C103.188 76.8723 106.05 83.1946 110.983 87.8183C115.915 92.4419 122.521 94.996 129.375 94.9292C136.229 94.8623 142.781 92.1799 147.617 87.461C152.453 82.7421 155.184 76.3652 155.221 69.707V55.7882V40.7906L170.527 35.9495V108.616H155.301V100.928C147.918 106.795 138.67 109.997 129.13 109.988V109.988Z"
            fill="#0C162A"
        />
        <path
            class="logo-letter -letter-q"
            d="M63.8192 40.7906V48.2117C58.6141 43.1117 51.9666 39.6288 44.7203 38.2049C37.474 36.7811 29.9556 37.4806 23.1192 40.2145C16.2828 42.9484 10.4366 47.5934 6.32261 53.5603C2.20861 59.5271 0.012207 66.5469 0.012207 73.7284C0.012207 80.9098 2.20861 87.9294 6.32261 93.8962C10.4366 99.8631 16.2828 104.508 23.1192 107.242C29.9556 109.976 37.474 110.676 44.7203 109.252C51.9666 107.828 58.6141 104.345 63.8192 99.2449V139.086L79.1699 134.245V35.9495L63.8192 40.7906ZM39.5851 97.2687C34.792 97.2687 30.1065 95.8882 26.1212 93.3015C22.136 90.7148 19.0298 87.0383 17.1956 82.7368C15.3613 78.4353 14.8814 73.7021 15.8165 69.1357C16.7516 64.5692 19.0597 60.3748 22.4489 57.0825C25.8381 53.7903 30.1562 51.5484 34.8572 50.6401C39.5581 49.7318 44.4309 50.1979 48.8591 51.9796C53.2873 53.7613 57.0722 56.7787 59.7351 60.6499C62.3979 64.5211 63.8192 69.0725 63.8192 73.7284C63.8121 79.9696 61.2566 85.9532 56.7133 90.3664C52.1701 94.7796 46.0102 97.2619 39.5851 97.2687V97.2687Z"
            fill="#0C162A"
        />
    </svg>
</template>

<script>
export default {
    name: "Logo"
};
</script>