<template>
    <tag :is="tag" class="b-scrollhack-main-list-item">
        <section class="b-scrollhack-main-list-item__inner">
            <!-- number -->
            <div class="b-scrollhack-main-list-item__inner__number"></div>
            <h2 v-html="entry.title"></h2>
            <h6 v-html="entry.description"></h6>
            <p v-html="entry.body" class="b-scrollhack-main-list-item__inner__copy"></p>
        </section>
    </tag>
</template>

<script>
export default {
    props: {
        entry: {
            type: Object,
            required: false
        },
        tag: {
            type: String,
            default: "li",
            validator: val => ["li", "div"].includes(val)
        }
    }
};
</script>

<style lang="scss" scoped>
.b-scrollhack-main-list-item {
    --min-height: auto;
    counter-increment: scroll-hack-counter;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: var(--min-height);
    height: 100%;
    padding-top: var(--height);

    @media #{md("sm")} {
        --min-height: 100vh;
    }

    &__inner {
        @include t-content;
        &__number {
            position: relative;
            height: 1rem;
            &:before {
                content: "0" counter(scroll-hack-counter) ".";
                font-size: fs("xlarge");
                color: var(--color-gray-chateau);
            }
        }
        h2 {
            @include t2;
        }
        h6 {
            font-family: ff("body");
            font-size: fs("xlarge");
            font-weight: 100;
        }

        &::v-deep .b-scrollhack-main-list-item__inner__copy {
            @include t-cms;

            p {
                margin-bottom: 0px;
            }

            ul,
            ol {
                margin-top: var(--grid-gutter-half);
            }
        }
    }
}
</style>
