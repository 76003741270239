import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default {
    data() {
        return {
            gsapAnimation: {
                disableScroll: {
                    scrollTrigger: null,
                    isDisabled: false,
                    currentPosition: 0
                }
            }
            // breakpoint: 768
        };
    },
    computed: {
        isDisableScrollAnimationRunning() {
            return this.gsapAnimation.disableScroll.isDisabled;
        }
    },

    mounted() {
        this.disableScroll();
    },

    methods: {
        ////////////////////////////////
        //       START DISABLE SCROLL GLOBALLY
        ////////////////////////////////
        manageScrollGlobally(e) {
            this.gsapAnimation.disableScroll.isDisabled && !this.gsapAnimation.disableScroll.isDisabledForced
                ? this.setScrollTo(e)
                : null;
        },
        setScrollTo(e) {
            e.preventDefault(e);
            window.scrollTo(0, this.gsapAnimation.disableScroll.currentPosition);

            this.preventDefaultForScrollKeys(e);
        },
        preventDefaultForScrollKeys(e) {
            const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
            if (keys[e.keyCode]) {
                e.preventDefault(e);
                return false;
            }
        },

        //======= START EVENT LISTENER =======//

        disableScroll() {
            // this solution fix the jerking on scroll
            // https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily/
            var supportsPassive = false;
            try {
                window.addEventListener(
                    "test",
                    null,
                    Object.defineProperty({}, "passive", {
                        get: () => {
                            supportsPassive = true;
                        }
                    })
                );
            } catch (e) {
                // eslint-disable-next-line
                console.log(e);
            }

            this.wheelOpt = supportsPassive ? { passive: false } : false;
            this.wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
            window.addEventListener("DOMMouseScroll", this.manageScrollGlobally, false); // older FF
            window.addEventListener(this.wheelEvent, this.manageScrollGlobally, this.wheelOpt); // modern desktop
            window.addEventListener("touchmove", this.manageScrollGlobally, this.wheelOpt); // mobile
            window.addEventListener("keydown", this.manageScrollGlobally, false);
        },

        //======= END EVENT LISTENER =======//

        //======= START REMOVE EVENT LISTENER =======//

        removeDisableScroll() {
            window.removeEventListener("DOMMouseScroll", this.manageScrollGlobally, false); // older FF
            window.removeEventListener(this.wheelEvent, this.manageScrollGlobally, this.wheelOpt); // modern desktop
            window.removeEventListener("touchmove", this.manageScrollGlobally, this.wheelOpt); // mobile
            window.removeEventListener("keydown", this.manageScrollGlobally, false);
        },

        //======= END REMOVE EVENT LISTENER =======//

        ////////////////////////////////
        //       END DISABLE SCROLL GLOBALLY
        ////////////////////////////////

        ////////////////////////////////
        //       START SCROLL
        ////////////////////////////////
        initScrollTrigger(container) {
            if (this.gsapAnimation.disableScroll.scrollTrigger) return;
            this.gsapAnimation.disableScroll.scrollTrigger = "";

            // ad a little dealy on mount to avoid some latency issue (staging only)
            const initScrollTriggerTimeout = setTimeout(() => {
                this.gsapAnimation.disableScroll.scrollTrigger = ScrollTrigger.create({
                    trigger: container,
                    markers: false,
                    start: "top top",
                    end: navigator.userAgent.indexOf("Firefox") != -1 ? "+50" : "+1",
                    onUpdate: () => {
                        this.gsapAnimation.disableScroll.isDisabled ? null : this.setDisableScroll();
                    }
                });
                clearTimeout(initScrollTriggerTimeout);
            }, 500);
        },

        //======= START DISABLE SCROLL =======//

        setDisableScroll() {
            // set scroll disable sc
            !this.gsapAnimation.disableScroll.isDisabled ? this.setCurrentPosition() : null;
            this.toggleIsScrollDisable(true);
            this.toggleScrollGlobally(true);
        },
        toggleIsScrollDisable(bool) {
            this.gsapAnimation.disableScroll.isDisabled = bool;
        },
        setCurrentPosition() {
            this.gsapAnimation.disableScroll.currentPosition = window.scrollY;
        },

        /*------------------------------
        Start toggle scroll globally
        ------------------------------*/
        toggleScrollGlobally(bool) {
            this.$store.dispatch("scroll/toggleScrollGlobally", bool);
        },

        /*------------------------------
        End toggle scroll globally
        ------------------------------*/

        //======= END DISABLE SCROLL =======//

        //======= START ENABLE SCROLL BACK =======//
        enableScrollBack() {
            this.forceDisabledScroll();
            this.toggleIsScrollDisable(false);
        },
        forceDisabledScroll() {
            // to enable the next scroll when user go next or before
            this.toggleForceDisabledScroll(true);

            // add a small delay which allow to fix a bug user to scroll up
            this.gsapAnimation.disableScroll.disabledForcedTimeout = setTimeout(() => {
                this.toggleForceDisabledScroll(false);
                this.toggleIsScrollDisable(false);
                this.toggleScrollGlobally(false);
                this.clearIsDisabledForcedTimeout();
            }, 500);
        },
        toggleForceDisabledScroll(bool) {
            this.gsapAnimation.disableScroll.isDisabledForced = bool;
        },

        clearIsDisabledForcedTimeout() {
            this.gsapAnimation.disableScroll.disabledForcedTimeout
                ? (clearTimeout(this.gsapAnimation.disableScroll.disabledForcedTimeout),
                  (this.gsapAnimation.disableScroll.disabledForcedTimeout = null))
                : null;
        },

        //======= END ENABLE SCROLL BACK =======//

        ////////////////////////////////
        //       END SCROLL
        ////////////////////////////////

        ////////////////////////////////
        //       START RESIZE
        ////////////////////////////////
        onResizeDisableScroll() {
            this.gsapAnimation.disableScroll.scrollTrigger
                ? this.gsapAnimation.disableScroll.scrollTrigger.refresh(true)
                : null;
        },

        ////////////////////////////////
        //       END RESIZE
        ////////////////////////////////

        ////////////////////////////////
        //       START ON DESTROY (NEEDED FOR PAGE RESIZE)
        ////////////////////////////////
        onDestroyDisableScroll() {
            this.toggleForceDisabledScroll(false);
            this.toggleIsScrollDisable(false);
            this.clearIsDisabledForcedTimeout();

            this.destroyScrollTrigger();
        },
        destroyScrollTrigger() {
            if (!this.gsapAnimation.disableScroll.scrollTrigger) return;
            this.gsapAnimation.disableScroll.scrollTrigger.kill();
            this.gsapAnimation.disableScroll.scrollTrigger = null;
        }
        ////////////////////////////////
        //       END ON DESTROY (NEEDED FOR PAGE RESIZE)
        ////////////////////////////////
    }
};
