<template>
    <div class="b-bubble">
        <span v-html="`+${data.number}`" class="b-bubble__number" />
        <span v-html="data.text" class="b-bubble__text" />
    </div>
</template>

<script>
export default {
    name: "Bubble",
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.b-bubble {
    width: var(--bubble-size);
    height: var(--bubble-size);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    text-align: center;
    padding: calc(var(--bubble-size) * 0.25);

    &__number {
        @include t3;
    }

    &__text {
        color: var(--color-gray-chateau);
        margin-top: var(--grid-gutter-half);
    }

    // Half border color
    // TODO Make it in svg for cool loading animation
    &:before,
    &:after {
        content: '';
        width: var(--bubble-size-half);
        height: var(--bubble-size);
        position: absolute;
        top: -1px;
        border: 1px solid var(--color-malibu);
        transform-origin: right center;
    }
    &:before {
        left: 0;
        border-top-left-radius: calc(var(--bubble-size-half));
        border-bottom-left-radius: calc(var(--bubble-size-half));
        border-right: none;
    }
    &:after {
        right: 0;
        border-color: var(--color-gray-chateau);
        border-top-right-radius: calc(var(--bubble-size-half));
        border-bottom-right-radius: calc(var(--bubble-size-half));
        border-left: none;
        transform-origin: left center;
    }

    // Positions
    // --bubble-top-side-spacing: 4%;
    
    // @media #{md("md")} {
    //     &.-pos-top-left {
    //         top: calc(var(--bubble-size) * 0.6 - 3.5rem);
    //         left: 0;

    //         @media #{md("lg")} {
    //             left: var(--bubble-top-side-spacing);
    //         }
    //     }
    //     &.-pos-top-left-center {
    //         top: -3.5rem;
    //         left: calc(var(--bubble-size) + var(--bubble-top-side-spacing) * 1.2);
    //     }
    //     &.-pos-top-right-center {
    //         top: -3.5rem;
    //         right: calc(var(--bubble-size) + var(--bubble-top-side-spacing) * 1.2);
    //     }
    //     &.-pos-top-right {
    //         top: calc(var(--bubble-size) * 0.6 - 3.5rem);
    //         right: 0;

    //         @media #{md("lg")} {
    //             right: var(--bubble-top-side-spacing);
    //         }
    //     }
    //     &.-pos-bottom-left {
    //         bottom: 5rem;
    //         right: calc(var(--bubble-size) + 58%);
    //     }
    //     &.-pos-bottom-left-center {
    //         bottom: 0;
    //         right: 53.75%;
    //     }
    //     &.-pos-bottom-right {
    //         bottom: 5rem;
    //         left: calc(var(--bubble-size) + 58%);
    //     }
    //     &.-pos-bottom-right-center {
    //         bottom: 0;
    //         left: 53.75%;
    //     }
    // }
}
</style>