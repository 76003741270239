<template>
    <div :class="className">
        <loader />

        <app-header @toggleNav="toggleNav" />

        <main class="l-main" ref="main">
            <router-view :page="currentPage" />
        </main>

        <app-footer />
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { toKebab } from "src/utils";

import AppHeader from "layout/AppHeader";
import AppFooter from "layout/AppFooter";

import Loader from "objects/Loader";

export default {
    name: "AppBase",
    components: {
        AppHeader,
        AppFooter,
        Loader
    },
    data() {
        return {
            navIsOpen: false
        };
    },
    watch: {
        $route() {
            this.scrollPageToTop();
        }
    },
    computed: {
        ...mapState({
            currentPage: state => state.global.currentPage,
            currentPageName: state => state.global.currentPageName
        }),
        ...mapGetters({
            isLoading: "loader/isLoading"
        }),
        className() {
            let classname = "l-wrapper";

            if (this.currentPageName) {
                classname += ` p-${toKebab(this.currentPageName)}`;
            }
            if (!this.isLoading) {
                classname += ` -loaded`;
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += " nav-is-open";
            }

            return classname;
        }
    },
    methods: {
        toggleNav(isOpen) {
            this.navIsOpen = isOpen;
        },
        scrollPageToTop() {
            window.scrollTo(0, 0);
        }
    }
};
</script>

<style lang="scss">
.l-main {
    margin-top: -1px; //1px from the hero https://mambomambo-team.atlassian.net/browse/QUAD-46
}
</style>
