<template>
    <div class="p-error">
        <hero
            :button="$t('error.hero.button')"
            :copy="$t('error.hero.title')"
            header-type="columns"
            :is-logo-visible="true"
        />
    </div>
</template>

<script>
import { SECTION_HANDLES } from "src/constants";

import Hero from "components/Hero/Hero";

export default {
    name: "Error404",
    components: {
        Hero
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        };
    },
    props: {
        page: Object
    },
    data() {
        return {
            SECTION_HANDLES,
            isReady: false
        };
    }
};
</script>

<style lang="scss">
.p-error {
    --min-height: 100vh;
    width: 100vw;
    height: var(--min-height);
    @media #{md("md")} {
        --min-height: auto;
    }
}
</style>
