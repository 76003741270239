<template>
    <form class="l-elements__filters">
        <div
            v-for="group in filterGroups"
            class="l-elements__filter-group"
            :class="`group-${group.group}`"
            :key="`group-${group.group}`"
            @click.stop=""
        >
            <btn-link
                :label="$t('articles.filters.all')"
                :count="getCount(0)"
                :isActive="selectedOption == 'all'"
                class="l-elements__filter-items"
                @click.prevent.native="reset"
            />

            <div
                v-for="(category, j) in filters[group.group]"
                :key="`category-${j}`"
                class="l-elements__filter-items"
                :class="`-${group.type}`"
                ref="categories"
            >
                <template>
                    <input
                        class="o-input"
                        :class="{
                            '-selected': category.id == selectedOption
                        }"
                        type="radio"
                        :value="category.id"
                        :id="`category-${category.id}`"
                        :ref="group.group"
                        @change="
                            toggleCheck({
                                id: category.id,
                                group: group.group
                            })
                        "
                        :name="group.group"
                    />
                    <label :for="`category-${category.id}`">
                        <btn-link
                            tag="span"
                            :label="category.plural || category.title"
                            :isActive="category.id == selectedOption ? true : false"
                            :count="getCount(category.id)"
                        />
                    </label>
                </template>
            </div>
        </div>
    </form>
</template>

<script>
import Variables from "mixins/variables";
import BtnLink from "components/Link";

import { gsap } from "gsap/all";

export default {
    name: "",
    components: {
        BtnLink
    },
    data: () => ({
        activeOrder: "Plus récent",
        datePanelOpen: false,
        filterPanelOpen: false,
        selectedOrder: "newer",
        selectedOption: "all"
    }),
    props: {
        filterGroups: {
            type: Array,
            default: () => []
        },
        articlesCount: {
            type: Array,
            default: () => []
        },
        filters: {},
        total: {
            type: Number,
            default: 0
        }
    },
    mixins: [Variables],
    mounted() {
        this.btns = gsap.utils.toArray(this.$el.querySelectorAll(".c-link"));
        gsap.set(this.btns, {
            opacity: 0,
            x: -50
        });

        setTimeout(this.revealFilters, 2000);

        document.addEventListener("click", this.closePanels);
        window.addEventListener("resizeEnd", this.updateDatePanel && this.closePanels);
    },
    methods: {
        revealFilters() {
            gsap.to(this.btns, {
                stagger: 0.1,
                opacity: 1,
                x: 0,
                duration: 0.3,
                ease: "power1.in"
            });
        },
        closePanels() {
            if (this.filterPanelOpen) {
                this.filterPanelOpen = false;
            }
            if (this.datePanelOpen) {
                this.datePanelOpen = false;
            }
            return;
        },
        btnBackground(id) {
            return this.filterBtnType(id) != "dark";
        },
        sortByDate(date) {
            this.$emit("sort-by-date", date);

            this.selectedOrder = date;
            return this.updateDatePanel(date);
        },
        getCount(idToFind) {
            const result = this.articlesCount.find(({ id }) => id == idToFind);
            return result.count;
        },
        filterBtnType(id) {
            // if (id == 'all')
            let type = id == this.selectedOption ? "dark" : this.isSmallScreen ? "light" : "outline";

            return type;
        },
        toggleCheck(selected) {
            this.selectedOption = selected.id;
            this.$emit("toggle-check", selected);
        },
        toggleFilterPanel() {
            this.filterPanelOpen = !this.filterPanelOpen;
        },
        updateDatePanel(date) {
            if (this.W.w < 480) this.activeOrder = "";
            else this.activeOrder = date == "newer" ? ": plus récent" : ": plus ancien";
        },
        reset() {
            this.selectedOption = "all";
            this.$emit("reset");
        }
    },
    destroyed() {
        document.removeEventListener("click", this.closePanels);
        window.removeEventListener("resizeEnd", this.updateDatePanel);
    }
};
</script>

<style lang="scss">
.l-elements {
    &__filters {
        width: 100%;
        justify-self: center;
        align-items: center;
        z-index: 5;

        .o-input {
            opacity: 0;
            width: 0;
        }
    }

    &__filter-group {
        --items-spacing: var(--grid-gutter);
        display: flex;
        align-items: flex-start;
        transition: transform 0.35s $in-out-quad, opacity 0.5s $out-quad;

        @media #{md("sm")} {
            --items-spacing: var(--grid-gutter-2X);
        }

        @media #{md("lg")} {
            --items-spacing: var(--grid-gutter-3X);
        }

        > *:nth-child(n + 2) {
            margin-left: var(--items-spacing);
        }
    }

    &__filter-items {
        @include pre-animation(0px, 100px); // animation
        display: flex;
        align-items: flex-start;
    }
}
</style>
