<template>
    <div class="b-steps-card-hover">
        <block-steps-content :copy="copy" :is-full="true" :index-card="indexCard" :url="url" />
    </div>
</template>

<script>
import BlockStepsContent from "blocks/BlockSteps/BlockStepsContent";
export default {
    components: {
        BlockStepsContent
    },
    props: {
        copy: {
            type: Object,
            required: false,
            default: null
        },
        indexCard: {
            type: Number,
            required: false,
            default: 0
        },
        url: {
            type: String,
            required: false,
            default: "/"
        }
    }
};
</script>

<style lang="scss" scoped>
.b-steps-card-hover {
    --action-card-height: 100%;
    @include full-screen-dom(absolute);
    @include transition(clip-path ease 0.8s);
    display: flex;
    background-color: var(--color-malibu);
    clip-path: inset(0 0 var(--action-card-height) 0);
    z-index: 9;
}
</style>
