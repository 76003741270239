import { render, staticRenderFns } from "./BlockScrollHackMainSquareIconItem.vue?vue&type=template&id=6ba7a7ef&scoped=true&"
import script from "./BlockScrollHackMainSquareIconItem.vue?vue&type=script&lang=js&"
export * from "./BlockScrollHackMainSquareIconItem.vue?vue&type=script&lang=js&"
import style0 from "./BlockScrollHackMainSquareIconItem.vue?vue&type=style&index=0&id=6ba7a7ef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba7a7ef",
  null
  
)

export default component.exports