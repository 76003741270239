<template>
    <section class="c-hero-bottom wrap">
        <div class="c-hero-bottom__grid | bs-grid">
            <div v-if="isLogoVisible" ref="largeLogo" class="c-hero-bottom__grid__logo | col-6 col-7-md">
                <logo />
            </div>
            <icon class="c-hero-bottom__grid__arrow | end-1-sm" icon="arrow-down" />
        </div>
    </section>
</template>

<script>
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import Icon from "@/templates/objects/Icon"; // @/template because the short cut crash the test. Maybe I will come back to that later
import Logo from "components/Shared/Logo";

export default {
    components: {
        Icon,
        Logo
    },
    props: {
        isLogoVisible: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            timeline: null
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initNavLogoOnScroll();
        });
    },
    beforeDestroy() {
        this.destroyTimeline();
    },
    methods: {
        initNavLogoOnScroll() {
            if (!this.isLogoVisible) return;

            const largeLogo = this.$refs.largeLogo.$el;
            this.timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: largeLogo,
                    start: "bottom top", // when the top of the trigger hits the top of the viewport
                    // markers: true,
                    onEnter: () => {
                        this.$store.dispatch("navigation/toggleLogoVisibility", true, { root: true });
                    },

                    onEnterBack: () => {
                        this.$store.dispatch("navigation/toggleLogoVisibility", false, { root: true });
                    }
                }
            });
        },
        destroyTimeline() {
            this.timeline ? (this.timeline.kill(), (this.timeline = null)) : null;
        }
    }
};
</script>

<style lang="scss">
.c-hero-bottom {
    &__grid {
        --bs-row-gap: var(--grid-gutter);
        align-items: center;

        &__logo {
            @media #{md("xs", "max")} {
                padding-top: var(--grid-gutter-1-5X);
            }

            svg {
                display: block;
                width: 100%;
                height: auto;
                aspect-ratio: 1 / 0.253;
                fill: var(--color-dark);
            }

            .logo-letter {
                @include transform(translateY(100%));
            }
            .logo-square {
                @include transform(scale(1, 0));
            }
        }

        &__arrow {
            --svg-width: 7px;
            --svg-ratio: 12.571;
            @include pre-animation(0px, -100px);
            align-self: center;

            &:only-child {
                grid-column: 1;
                justify-self: start;
            }

            @media #{md("sm")} {
                justify-self: end;
                margin-right: -4px;
            }
        }
    }
}
</style>
