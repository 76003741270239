<template>
    <div class="c-user-card" :class="className">
        <div class="c-user-card__inner">
            <div class="c-user-card__thumbnail" v-if="user.photo">
                <shared-thumbnail :asset="user.photo" :alt="user.fullName" />
            </div>

            <div class="c-user-card__content">
                <slot name="beforeName">
                    <p><small v-if="beforeName" class="c-user-card__before-name" v-html="beforeName" />&nbsp;</p>
                </slot>
                <p class="c-user-card__name">
                    {{ user.fullName }}&nbsp;
                    <span v-if="user.role && isRoleVisible" class="no-wrap" v-html="`${user.role}`" />
                </p>
            </div>
        </div>

        <div v-if="user.linkedIn && isLinkedinVisible" class="c-user-card__socials">
            <btn
                :color="isDarkMode ? `light` : `dark`"
                :color-hover="isDarkMode ? `gray-chateau` : `dark`"
                :has-underline="true"
                overwrite-line="malibu"
                :label="$t('shared.socialMedia.linkedin')"
                tag="a"
                :href="user.linkedIn"
                :is-target-blank="true"
            />
        </div>
    </div>
</template>

<script>
import SharedThumbnail from "@/templates/components/Shared/SharedThumnail/SharedThumbnail.vue";
import Btn from "@/templates/components/Shared/SharedBtn/SharedBtn";

export default {
    name: "UserCard",
    components: {
        SharedThumbnail,
        Btn
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        beforeName: {
            type: String,
            default: null,
            required: false
        },
        isDarkMode: {
            type: Boolean,
            required: false,
            default: false
        },
        isRoleVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isLinkedinVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        orientation: {
            type: String,
            required: false,
            default: "vertical",
            validator: val => ["horizontal", "vertical"].includes(val)
        },
        size: {
            type: String,
            required: false,
            default: "large",
            validator: val => ["large", "medium", "small"].includes(val)
        }
    },
    computed: {
        className() {
            let classname = "c-user-card";

            if (this.size) {
                classname += ` -${this.size}`;
            }

            if (this.orientation) {
                classname += ` -${this.orientation}`;
            }

            if (this.isDarkMode) {
                classname += " -dark-mode";
            }

            return classname;
        }
    }
};
</script>

<style lang="scss">
.c-user-card {
    $self: &;
    --margin-bottom: var(--grid-gutter);
    --padding: var(--grid-gutter-half);

    --margin-bottom-thumbnail: 0px;
    --padding-thumbnail: 0px var(--grid-gutter-half) 0px 0px;

    --max-width-thumbnail: 10.8rem;

    --user-card-direction: column;
    --user-card-inner-direction: column;
    --user-card-inner-gap: var(--grid-gutter);

    &.-vertical {
        --user-card-inner-direction: row;

        --margin-bottom: var(--grid-gutter);
        --padding: var(--grid-gutter-half);

        --margin-bottom-thumbnail: var(--grid-gutter);
        --padding-thumbnail: var(--grid-gutter-half);

        display: flex;
        flex-direction: var(--user-card-direction);
        justify-content: flex-start;
        align-items: center;
        align-items: flex-start;
        padding: var(--grid-gutter-1-5X);
        background-color: var(--color-concrete);
    }

    &.-horizontal {
        --padding-thumbnail: 0;

        display: grid;
        grid-auto-flow: var(--user-card-direction);
        justify-content: space-between;
        align-items: center;
        gap: var(--grid-gutter-half);
        margin-top: var(--grid-gutter);

        @media #{md("xs")} {
            // avoid the text to wrap and image to shrink
            --user-card-direction: row;
        }
        @media #{md("sm")} {
            --user-card-direction: column;
        }
    }

    &.-medium {
        --max-width-thumbnail: 6rem;
    }

    &.-small {
        --max-width-thumbnail: 2.5rem;
        --user-card-inner-gap: var(--grid-gutter-half);
    }

    &.-dark-mode {
        background-color: var(--color-dark);
    }

    &__inner {
        display: grid;
        grid-auto-flow: var(--user-card-inner-direction);
        gap: var(--user-card-inner-gap);
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    &__thumbnail {
        padding: var(--padding-thumbnail);
        margin-bottom: var(--margin-bottom-thumbnail);

        .c-shared-thumbnail {
            max-width: var(--max-width-thumbnail);
        }
    }

    &__content {
        --content-spacing-top: 0.4rem;
        display: flex;
        flex-direction: var(--user-card-direction);
        line-height: 1;
        padding-top: var(--content-spacing-top);

        .-horizontal & {
            --user-card-direction: row;
            align-items: center;
        }
        .-vertical & {
            --content-spacing-top: 0;
        }
    }

    &__name {
        .-vertical & {
            margin: 0.5rem 0;
        }

        .-large & {
            @include t4;
        }
        .-medium & {
            @include fontCopy;
        }
        .-small & {
            @include fontSmallCopy;
        }
    }

    &__before-name {
        .-vertical & {
            margin-bottom: 0.6rem;
        }
    }
}
</style>
