<template>
    <tag
        :is="tag"
        :href="tag == 'a' && href !== null ? href : false"
        :to="tag == 'router-link' && href !== null ? href : false"
        :rel="tag == 'a' ? 'noopener' : false"
        class="c-shared-btn-square"
        :class="themeName"
        :aria-disabled="isDisabled"
        :target="isTargetBlank"
        :aria-label="ariaLabelBtn"
    >
        <span class="c-shared-btn-square__label">
            {{ label }}
        </span>
        <icon v-if="iconAfter !== null" class="c-shared-btn-square__icon" :icon="iconAfter" />
        <div class="c-shared-btn-square__blind">
            <span class="c-shared-btn-square__label">
                {{ label }}
            </span>
            <icon v-if="iconAfter !== null" class="c-shared-btn-square__icon" :icon="iconAfter" />
        </div>
    </tag>
</template>

<script>
import Icon from "@/templates/objects/Icon";
export default {
    props: {
        //--- start Button stuff ---//
        tag: {
            type: String,
            required: false,
            default: "router-link"
        },
        href: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String | Number,
            default: "Button Label"
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isTargetBlank: {
            type: Boolean,
            required: false,
            default: false
        },
        ariaLabel: {
            type: String,
            required: false,
            default: null
        },
        //--- end Button stuff ---//

        //--- start Themes ---//
        theme: {
            type: String,
            required: false,
            default: "malibu",
            validator: val => ["dark", "malibu"].includes(val)
        },
        //--- end Themes ---//

        //--- start decor ---//
        iconAfter: {
            type: String,
            required: false,
            default: null
        }
        //--- end decor ---//
    },
    components: {
        Icon
    },
    computed: {
        ariaLabelBtn() {
            return this.ariaLabel ? this.ariaLabel : this.label;
        },
        themeName() {
            let classname = "yo";

            if (this.theme) {
                classname = `c-shared-btn-square--theme-${this.theme}`;
            }

            return classname;
        }
    }
};
</script>

<style lang="scss">
.c-shared-btn-square {
    --btn-square-background: var(--color-malibu);
    --btn-square-color: var(--color-dark);
    --translateXSvg: 0%;
    --translateXCopy: 0rem;
    --wrapped-clip-path: 100%;

    --color-blind: var(--color-light);
    --background-blind: var(--color-dark);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;

    overflow: hidden;

    max-width: 24rem;
    aspect-ratio: 1/1;

    padding: var(--grid-gutter-1-25X);
    background: var(--btn-square-background);

    cursor: pointer;

    &:hover {
        // THIS IS SO UGLY CODE, NEED TO TALK TO A DESIGNER ABOUT THAT
        --translateXSvg: 1rem;
        --wrapped-clip-path: 0%;
    }

    &__label {
        @include transition(0.5s ease transform);
        @include transform(translateX(var(--translateXCopy)));
        @include t4;
        max-width: 13rem;
        // z-index: 3;
        color: var(--btn-square-color);

        .c-shared-btn-square__blind & {
            color: var(--color-blind);
        }
    }

    &__icon {
        svg {
            @include transition(0.5s ease transform);
            @include transform(translateX(var(--translateXSvg)));
            fill: var(--btn-square-color);
            .c-shared-btn-square__blind & {
                fill: var(--color-blind);
            }
        }
    }

    &__blind {
        @include transition(0.5s ease all);
        clip-path: inset(0 var(--wrapped-clip-path) 0 0);
        padding: var(--grid-gutter-1-25X);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;

        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 1;
        background: var(--background-blind);
    }

    // Themes
    &--theme-dark {
        @include darkBackgroundTextSelect;
        --btn-square-color: var(--color-light);
        --btn-square-background: var(--color-dark);

        --color-blind: var(--color-dark);
        --background-blind: var(--color-malibu);
    }
}
</style>
