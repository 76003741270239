<template>
    <div class="c-cross" :class="{ 'c-cross--dark': isDark }">
        <span class="c-cross__line c-cross__line--vertical"></span>
        <span class="c-cross__line c-cross__line--horizontal"></span>
    </div>
</template>

<script>
export default {
    props: {
        isDark: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-cross {
    --cross-color: var(--color-gray-chateau);
    @include transition(transform 0.6s $out-expo);
    @include transform(rotate(var(--cross-rotate, 0deg)));
    width: 2.2rem;
    height: 2.2rem;

    &__line {
        @include transition(transform 0.6s $out-expo);
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        background-color: var(--cross-color);

        &--vertical {
            @include transform(rotate(var(--cross-rotate-line, 90deg)));
        }
    }

    &--dark {
        --cross-color: var(--color-dark);
    }
}
</style>
