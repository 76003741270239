import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "src/store";

//--- start vuei18n ---//
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import { languages } from "./i18n/index.js";
import { defaultLocale } from "./i18n/index.js";
const messages = Object.assign(languages);
//--- end vuei18n ---//

import view from "./directives/view"; // eslint-disable-line
import reveal from "./directives/reveal"; // eslint-disable-line
import { resizeEnd } from "src/utils";
import "src/filters";

// Plugins
import VueMeta from "vue-meta";
import VueGtm from "vue-gtm";

// Add window resizeEnd event
resizeEnd();

// Vars
Vue.config.productionTip = false;
const isProd = process.env.NODE_ENV === "production";

// i18n after Node env
var i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: "fr",
    messages
});

// Meta descriptions
Vue.use(VueMeta, {});

// GTM
if (isProd && process.env.VUE_APP_GTM) {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM,
        queryParams: {
            gtm_auth: process.env.VUE_APP_GTM_AUTH,
            gtm_cookies_win: "x"
        },
        defer: true,
        enabled: true,
        debug: false,
        vueRouter: router,
        loadScript: true
        // ignoredViews: [''],
        // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    });
}

// Initial load
store.dispatch("global/initLoad");

// Vue instance
new Vue({
    el: "#vue",
    router,
    store,
    i18n,
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");
