import { fetchApi } from "src/graphql/config";
import queries from "src/graphql/queries";
import { linkResolver } from "src/router";
import { STORE_MODULES } from "src/constants";

/*
 * Global
 */

// State
const state = {
    forms: null,
    navs: [],
    settings: {},
    currentPage: {},
    currentPageName: null,
    isDev: false
};

// Getters
const getters = {};

// Actions
const actions = {
    // Initial load for navigation, globals, etc...
    initLoad(store) {
        const promises = [
            store.dispatch("loadGeneral"),
            store.dispatch("steps/preload", false, { root: true }),
            store.dispatch("services/preload", false, { root: true })
        ];

        return Promise.all(promises).catch(e => {
            console.error(e.message); // eslint-disable-line
        });
    },
    loadGeneral(store) {
        fetchApi(queries.general)
            .then(data => {
                // Format nav and add route path to each nav item
                const nav = data.navs.navNodes;

                nav.forEach(item => {
                    if (!item.entry.length) {
                        item.entry = [{ id: "4", uri: "/", title: "Home", section: "home" }];
                        item.path = "/";
                        item.url = "/";
                    } else {
                        item.entry = item.entry[0];
                        item.path = linkResolver(item.entry.section);
                        item.url = item.entry.section == "home" ? "/" : `/${item.entry.uri}`;
                    }
                });

                store.commit("setNav", nav);
                store.commit("setForms", data.forms);
                store.commit("setSettings", data.settings);
            })
            .catch(e => {
                console.error(e.message); // eslint-disable-line
            });
    },
    loadContent(store, to) {
        return new Promise((resolve, reject) => {
            // Timer in seconds
            const timer = new Date();

            // Start loader
            store.dispatch("loader/startLoad", null, { root: true });

            // Load page content
            let fetchContent;
            const handle = to.meta.section;
            const singleEntry = to.meta.single;

            // Empty promise if nothing to load (static page)
            if (typeof handle === "undefined") {
                fetchContent = new Promise(r => r({}));
            }
            // For single entries, load page with handle
            else if (singleEntry) {
                fetchContent = store.dispatch("pages/loadSingle", handle, { root: true });

                // Vuex Modules exceptions (that extend the crud.js)
            } else if (STORE_MODULES.includes(handle)) {
                const slug = to.params.slug;
                fetchContent = store.dispatch(`${handle}/loadElement`, slug, { root: true });

                // Use default `pages` as fallback, loads the Entry using both handle and slug
            } else {
                const slug = to.params.slug;
                fetchContent = store.dispatch("pages/loadEntry", { handle, slug }, { root: true });
            }

            fetchContent
                .then(page => {
                    // Duration in ms of the fetchContent + 300ms for the loader cover delay (see loader component) + 10ms for latency
                    let delay = timer - new Date() + 800;
                    delay = delay < 0 ? 0 : delay;

                    setTimeout(() => {
                        store.commit("setPage", {});
                        store.commit("setPageName", to.name);
                        resolve(page);

                        // Timeout to let template to render data
                        setTimeout(() => {
                            // Set page after router page has updated to avoid js errors
                            store.commit("setPage", page);
                            store.dispatch("loader/endLoad", null, { root: true });
                        }, 10);
                    }, delay);
                })
                .catch(e => {
                    reject(e);
                    store.dispatch("loader/endLoad", null, { root: true });
                });
        });
    }
};

// Mutations
const mutations = {
    setNav(state, nav) {
        state.navs = nav;
    },
    setForms(state, forms) {
        state.forms = forms;
    },
    setSettings(state, settings) {
        state.settings = settings;
    },
    setPage(state, page) {
        state.currentPage = page;
    },
    setPageName(state, name) {
        state.currentPageName = name;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
