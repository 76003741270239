<template>
    <div>
        <hero :copy="page.heading" :header-type="page.headerType" :is-logo-visible="true" />
        <app-builder v-if="page.sections && page.sections.length" :sections="page.sections" />
    </div>
</template>

<script>
import { SECTION_HANDLES } from "src/constants";

import AppBuilder from "layout/AppBuilder.vue";
import Hero from "components/Hero/Hero";

export default {
    name: "Home",
    components: {
        AppBuilder,
        Hero
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        };
    },
    props: {
        page: Object
    },
    data: () => ({
        SECTION_HANDLES,
        isReady: false
    })
};
</script>

<style lang="scss">
.p-home {
    @include transition(0.5s ease opacity);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
</style>
