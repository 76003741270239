<template>
    <button class="c-app-header-burger" :class="{ 'c-app-header-burger--open': isNavOpen }" @click="toggleNav">
        <div class="c-app-header-burger__copy">
            <transition name="burger-label" mode="out-in">
                <span key="close" v-if="isNavOpen">{{ $t("header.burger.close.label") }}</span>
                <span key="open" v-else>{{ $t("header.burger.open.label") }}</span>
            </transition>
        </div>
        <ul class="c-app-header-burger__lines">
            <li v-for="index in 3" :key="index"></li>
        </ul>
    </button>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            isNavOpen: state => state.navigation.isNavOpen
        })
    },
    methods: {
        toggleNav() {
            this.$store.dispatch("navigation/toggleNav", !this.isNavOpen);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-app-header-burger {
    --display-burger: flex;
    --burger-transform-first: 0px;
    --burger-transform-last: 0px;
    --burger-line-width-middle: 20px;
    --burger-line-width-last: 12px;

    display: var(--display-burger);
    flex-direction: row;
    align-content: center;
    align-items: center;
    width: auto;
    padding: 0.1rem;
    cursor: pointer;

    &--open {
        --burger-transform-first: 6px;
        --burger-transform-last: -6px;

        --burger-line-width-middle: 30px;
        --burger-line-width-last: 30px;
    }

    &__copy {
        @include transform(translateY(0.1rem)); // be
        padding-right: var(--grid-gutter-half);
        span {
            display: block;
            line-height: 0rem;
        }
    }
    ul {
        @include reset-list;
        display: grid;
        grid-auto-flow: row;
        gap: 5px;
        width: 30px;
        max-width: 30px;
        justify-items: center;
        align-items: center;
    }
    ul li {
        @include transition(0.3s ease all); // why width alone doesnt work?

        background: var(--color-dark);
        height: 1px;

        width: 100%;
        display: flex;
        position: relative;

        &:nth-child(1) {
            @include transform(translateY(var(--burger-transform-first)));
        }
        &:nth-child(2) {
            max-width: var(--burger-line-width-middle);
        }
        &:nth-child(3) {
            @include transform(translateY(var(--burger-transform-last)));
            max-width: var(--burger-line-width-last);
        }
    }
    @media #{md("sm")} {
        --display-burger: none;
    }
}

.burger-label-enter-active,
.burger-label-leave-active {
    $transition-burger: opacity 0.5s ease, transform 0.5s ease;
    @include transition($transition-burger);
}

.burger-label-enter,
.burger-label-leave-to {
    @include transform(translateX(-0.5rem));
    opacity: 0;
}
</style>
