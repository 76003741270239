<template>
    <div :class="className">
        <div class="c-accordion__header" @click="isOpen ? close() : open()">
            <slot name="title">
                <h3 v-if="title" class="c-accordion__title t-t3" v-html="title" />
            </slot>
            <cross />
        </div>
        <div ref="container" class="c-accordion__container">
            <div ref="inner" class="c-accordion__content">
                <slot>
                    <div class="t-cms" v-html="content" />
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap/all";
import Cross from "@/templates/components/Shared/Cross";

export default {
    name: "Accordion",
    components: {
        Cross
    },
    data: () => ({
        isOpen: false
    }),
    props: {
        title: {
            type: String,
            default: null
        },
        content: {
            type: String,
            default: null
        },
        options: {
            type: Object,
            default: () => ({})
        },
        upsideDown: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: false
        },
        openDefault: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        className() {
            let classname = "c-accordion";

            if (this.isOpen) {
                classname += " is-open";
            }

            if (this.upsideDown) {
                classname += ` -upside-down`;
            }

            return classname;
        }
    },
    mounted() {
        if (this.openDefault) this.open();
    },
    methods: {
        open() {
            this.isOpen = true;

            const height = this.$refs.inner.offsetHeight;

            gsap.to(this.$refs.container, {
                duration: 1,
                height,
                opacity: 1,
                onComplete: () => {
                    gsap.set(this.$refs.container, {
                        height: "auto"
                    });
                },
                ease: "expo.out"
            });
        },
        close() {
            this.isOpen = false;

            gsap.to(this.$refs.container, {
                duration: 0.6,
                height: 0,
                opacity: 0,
                onComplete: () => {
                    gsap.set(this.$refs.container, {
                        height: 0
                    });
                },
                ease: "expo.out"
            });
        },
        toggle() {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        }
    }
};
</script>

<style lang="scss">
.c-accordion {
    --color-text: var(--color-gray-chateau);
    color: var(--color-text);
    padding: var(--grid-gutter-1-25X) 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--color-concrete);
    }

    &__header {
        cursor: pointer;

        &:before {
            @include fontHeading;
            counter-increment: accordions;
            content: counter(accordions, decimal-leading-zero) ".";
            display: block;
            color: var(--color-gray-chateau);
            font-size: fs("xlarge");
            margin-bottom: 0.8rem;
        }
    }

    &__title {
        @include transition(color 0.5s ease);
        max-width: 51rem;
        padding-right: var(--grid-gutter-3X);
    }

    .c-cross {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__container {
        height: 0;
        opacity: 0;
        overflow: hidden;
    }

    &__content {
        @include transition(color 0.5s ease);
        max-width: 51rem;
        padding-top: 2rem;
        padding-right: var(--grid-gutter-3X);
        pointer-events: none;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    &.is-open {
        --cross-rotate: 180deg;
        --cross-rotate-line: 0deg;
        --color-text: var(--color-dark);

        .c-accordion__content {
            pointer-events: initial;
        }
    }

    &.-upside-down {
        display: flex;
        flex-direction: column;

        .c-accordion__header {
            order: 2;
        }

        .c-accordion__container {
            order: 1;
        }
    }
}
</style>
