const initialDataServices = window.__initialData__.services;
// State
const state = {
    services: []
};

// Getters
const getters = {};

// Actions
const actions = {
    preload(store) {
        store.commit("setServices", initialDataServices);
    }
};

// Mutations
const mutations = {
    setServices(state, services) {
        state.services = services;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
