export const SECTION_HANDLES = Object.freeze({
    HOME: "home",
    ARTICLES: "articles",
    ARTICLE: "article",
    PAGE: "page"
});

// For Vuex stores
export const STORE_MODULES = Object.freeze([
    "article",
    "articles",
    "global",
    "hero",
    "loader",
    "navigation",
    "pages",
    "services",
    "steps",
    "scroll"
]);
