<template>
    <li class="b-scroll-hack-trigger-item">
        <ul ref="scrollHackTriggerItem" class="b-scroll-hack-trigger-item__inner">
            <li class="b-scroll-hack-trigger-item__inner__square">
                <block-scroll-hack-main-square
                    :active-item="activeItem"
                    :custom-entry-index="entryIndex"
                    :entries="[entry]"
                    :is-visible="true"
                    :is-square-blue="isSquareBlue"
                />
            </li>
            <block-scroll-hack-main-list-item :id="entry.slug" :entry="entry" tag="div" :active-item="activeItem" />
        </ul>
    </li>
</template>

<script>
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import BlockScrollHackMainListItem from "blocks/BlockScrollhack/BlockScrollHackMainList/BlockScrollHackMainListItem";
import BlockScrollHackMainSquare from "blocks/BlockScrollhack/BlockScrollHackMainSquare/BlockScrollHackMainSquare";
export default {
    components: {
        BlockScrollHackMainListItem,
        BlockScrollHackMainSquare
    },
    props: {
        entry: {
            type: Object,
            required: false,
            default: null
        },
        entryIndex: {
            type: Number,
            required: false,
            default: 0
        },
        isSquareBlue: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            gsapAnimation: {
                scrollTrigger: null
            },
            activeItem: 0
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initScrollTrigger();
            window.addEventListener("resizeEnd", this.resize);
        });
    },
    beforeDestroy() {
        window.removeEventListener("resizeEnd", this.resize);
    },
    methods: {
        initScrollTrigger() {
            this.gsapAnimation.scrollTrigger = ScrollTrigger.create({
                trigger: this.$refs.scrollHackTriggerItem,
                markers: false,
                start: "top center", // when the top of the trigger hits the top of the viewport
                // markers: true,

                onEnter: () => {
                    this.toggleActiveItem(true);
                },
                onLeave: () => {
                    this.toggleActiveItem(false);
                }
            });
        },
        toggleActiveItem(bool) {
            bool ? (this.activeItem = 1) : (this.activeItem = 0);
        },

        ////////////////////////////////
        //       START RESIZE
        ////////////////////////////////
        resize() {
            this.gsapAnimation.scrollTrigger.refresh();
        }
        ////////////////////////////////
        //       END RESIZE
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.b-scroll-hack-trigger-item {
    --margin-bottom-trigger: var(--grid-gutter-3X);
    display: flex;
    flex-direction: column;
    margin-bottom: var(--margin-bottom-trigger);

    &:nth-child(1n),
    &:nth-child(5n) {
        --scroll-hack-trigger-square-padding: 0rem 8rem 8rem 0rem;
    }
    &:nth-child(2n) {
        --scroll-hack-trigger-square-padding: 8rem 0rem 0rem 8rem;
    }
    &:nth-child(3n) {
        --scroll-hack-trigger-square-padding: 0rem 0rem 8rem 8rem;
    }
    &:nth-child(4n) {
        --scroll-hack-trigger-square-padding: 8rem 8rem 0rem 0rem;
    }
    &:last-child {
        --margin-bottom-trigger: 0px;
    }
    &__inner {
        @include reset-list;
        display: flex;
        flex-direction: column;
        &__square {
            margin: 0px var(--grid-gutter) var(--grid-gutter-2X) 0px;
            padding: var(--scroll-hack-trigger-square-padding);
        }
    }
}
</style>
