<template>
    <div class="c-app-nav">
        <app-nav-mobile />
        <app-nav-desktop />
    </div>
</template>

<script>
import AppNavMobile from "components/AppNav/AppNavMobile/AppNavMobile";
import AppNavDesktop from "components/AppNav/AppNavDesktop";
export default {
    components: {
        AppNavMobile,
        AppNavDesktop
    }
};
</script>

<style lang="scss" scoped>
.c-app-nav {
    --position-nav: static;
    display: flex;
    flex-direction: column;
    position: var(--position-nav);
    @media #{md("sm")} {
        --position-nav: relative;
    }
}
</style>
