<template>
    <section class="c-app-footer-bottom | wrap bs-grid">
        <icon class="c-app-footer-bottom__logo" icon="logo-small" />

        <p class="c-app-footer-bottom__copy | col-10 col-5-lg">
            <span>&copy; {{ year }}</span>
            <span class="c-app-footer-bottom__copy__line"></span>
            <span>{{ $t("footer.legal.by") }}</span>
        </p>

        <a
            class="c-app-footer-bottom__credits | end-1"
            href="https://mambomambo.ca/"
            target="_blank"
            rel="noopener noreferrer"
            :aria-label="$t('footer.legal.mambo')"
            >{{ $t("footer.legal.credits") }}</a
        >

        <app-footer-bottom-shapes />
    </section>
</template>

<script>
import AppFooterBottomShapes from "components/AppFooter/AppFooterBottom/AppFooterBottomShapes";
import Icon from "objects/Icon";

export default {
    components: {
        Icon,
        AppFooterBottomShapes
    },
    computed: {
        year: () => new Date().getFullYear()
    }
};
</script>

<style lang="scss" scoped>
.c-app-footer-bottom {
    --copy-padding-top: 19rem;
    --copy-padding-bottom: 7rem;
    @include darkBackgroundTextSelect;
    // @include pre-animation(0px, 100px);
    color: var(--color-gray-chateau);
    padding-top: var(--copy-padding-top);
    padding-bottom: var(--copy-padding-bottom);
    align-items: center;
    font-size: fs("regular");
    line-height: 1;
    overflow: hidden;
    z-index: 1;

    @media #{md("xs", "max")} {
        --bs-row-gap: var(--grid-gutter-1-5X);
        --copy-padding-top: var(--grid-gutter-4X);
        --copy-padding-bottom: 0;
        justify-items: center;

        > * {
            grid-column: 1 / -1;
        }
    }

    &__logo {
        --svg-width: 3.7rem;
        --svg-ratio: 1.243;

        &::v-deep svg {
            fill: var(--color-light);
        }
    }

    &__copy {
        @include transform(translate(0px, 10px));
        display: flex;
        align-items: center;
        white-space: nowrap;

        &__line {
            width: var(--grid-gutter-3X);
            height: 1px;
            margin: 1px var(--grid-gutter-half) 0;
            background-color: var(--color-gray-chateau);
        }
    }

    &__credits {
        @include transform(translate(0px, 10px));
        @media #{md("xs")} {
            justify-self: end;
            order: 4;
            z-index: 9;
        }
    }
}
</style>
