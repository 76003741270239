<template>
    <li ref="scrollHackSquare" class="b-scroll-hack-main-square-item" :style="setPositionSquare(nItem)">
        <asset class="b-scroll-hack-main-square-item__texture" :asset="entry.texture" :cover="true" />
    </li>
</template>

<script>
import { gsap } from "gsap/all";
import Asset from "@/templates/objects/Asset";

export default {
    components: {
        Asset
    },
    props: {
        isActive: {
            type: Boolean,
            required: false,
            default: false
        },
        entryIndex: {
            type: Number,
            required: false,
            default: 0
        },
        entry: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            gsapAnimation: {
                timeline: null
            }
        };
    },

    watch: {
        isActive() {
            this.toggleAnimation();
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initAnimation();
        });
    },
    computed: {
        nItem() {
            if (this.entryIndex === 0) {
                return 1;
            }
            if ((this.entryIndex + 1) % 3 === 0) {
                return 3;
            }
            if ((this.entryIndex + 1) % 4 === 0) {
                return 4;
            }
            if ((this.entryIndex + 1) % 5 === 0) {
                return 5;
            }
            return 2;
        }
    },
    methods: {
        initAnimation() {
            const target = this.$refs.scrollHackSquare;
            this.gsapAnimation.timeline = gsap.timeline({ paused: true });

            this.$nextTick(() => {
                this.setTimelineAnimation(this.gsapAnimation.timeline, target);
            });
        },
        setTimelineAnimation(timeline, target) {
            if (!timeline) return;
            const ease = "expo.inOut";
            const duration = 0.8;

            switch (this.nItem) {
                case 1:
                    timeline
                        .to(target, {
                            x: "100%",
                            ease: ease,
                            duration: duration
                        })
                        .to(target, {
                            y: "100%",
                            ease: ease,
                            duration: duration
                        });
                    break;
                case 2:
                    timeline
                        .to(target, {
                            x: "-100%",
                            ease: ease,
                            duration: duration
                        })
                        .to(target, {
                            y: "-100%",
                            ease: ease,
                            duration: duration
                        });
                    break;
                case 3:
                    timeline
                        .to(target, {
                            x: "-100%",
                            ease: ease,
                            duration: duration
                        })
                        .to(target, {
                            y: "100%",
                            ease: ease,
                            duration: duration
                        });
                    break;
                case 4:
                    timeline
                        .to(target, {
                            x: "100%",
                            ease: ease,
                            duration: duration
                        })
                        .to(target, {
                            y: "-100%",
                            ease: ease,
                            duration: duration
                        });
                    break;
                default:
                    timeline
                        .to(target, {
                            x: "100%",
                            ease: ease,
                            duration: duration
                        })
                        .to(target, {
                            y: "100%",
                            ease: ease,
                            duration: duration
                        });
                    break;
            }
        },
        toggleAnimation() {
            this.gsapAnimation.timeline.time() > 0
                ? this.gsapAnimation.timeline.reverse()
                : this.gsapAnimation.timeline.play();
        },
        setPositionSquare(nItem) {
            switch (nItem) {
                case 1:
                    return `--top: auto; --right: 0px; --bottom: 0px;--left: auto;`;
                case 5:
                    return `--top: auto; --right: 0px; --bottom: 0px;--left: auto;`;
                case 3:
                    return `--bottom: 0px; --left: 0px; --top: auto; --right: auto;`;

                case 4:
                    return ` --right: 0px; --top: 0px; --bottom: auto; --left: auto;`;

                default:
                    return ` --top: 0px; --left: 0px;--right: auto;--bottom: auto;`;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.b-scroll-hack-main-square-item {
    --top: auto;
    --right: auto;
    --bottom: auto;
    --left: auto;
    --size-square: 8rem;
    --background-color: var(--color-dark); // backup
    @include reset-list;
    position: absolute;
    width: var(--size-square);
    height: var(--size-square); // aspect-ratio: 1/1; https://mambomambo-team.atlassian.net/browse/QUAD-251
    top: var(--top);
    right: var(--right);
    bottom: var(--bottom);
    left: var(--left);

    background-color: var(--background-color);

    @media #{md("lg")} {
        --size-square: 11rem;
    }

    &__texture {
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
    }
}
</style>
