<template>
    <li class="b-scrollhack-main-square-icon" :class="{ 'b-scrollhack-main-square-icon--visible': isActive }">
        <div ref="icon" class="b-scrollhack-main-square-icon__lottie"></div>
    </li>
</template>

<script>
import lottie from "lottie-web";
export default {
    props: {
        icon: {
            type: Object,
            required: false,
            default: null
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            logoAnimation: null,
            autoplay: false,
            iconSvg: null
        };
    },
    watch: {
        isActive(bool) {
            bool ? this.triggerAnimation() : null;
        }
    },
    mounted() {
        this.loadFile();
    },
    beforeDestroy() {
        this.destroyExistingAnimation();
        this.timeoutDestroyer();
    },

    methods: {
        ////////////////////////////////
        //       START GET THE FILE
        ////////////////////////////////
        loadFile() {
            this.icon && this.icon.url ? this.fetchJSON() : null;
        },
        fetchJSON() {
            fetch(this.icon.url)
                .then(result => result.json())
                .then(output => {
                    this.iconSvg = output;
                })
                // eslint-disable-next-line
                .catch(err => console.error(err));
        },
        ////////////////////////////////
        //       END GET THE FILE
        ////////////////////////////////
        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////
        triggerAnimation() {
            this.iconSvg ? this.initAnimation() : null;
        },

        //======= START INIT =======//

        initAnimation() {
            // destroy
            this.destroyExistingAnimation();
            // build
            this.buildAnimation();
            // run
            this.runAnimation();
        },
        destroyExistingAnimation() {
            // Destroy autoplaynimation if exists
            this.logoAnimation ? this.logoAnimation.destroy() : null;
        },

        //======= END INIT =======//

        buildAnimation() {
            this.logoAnimation = lottie.loadAnimation({
                container: this.$refs.icon,
                renderer: "svg",
                autoplay: false,
                loop: false,
                animationData: this.iconSvg
            });
        },

        //======= START RUN =======//

        runAnimation() {
            this.timeout = setTimeout(() => {
                this.logoAnimation.play();
                this.timeoutDestroyer();
            }, 500);
        },

        timeoutDestroyer() {
            this.timeout ? (clearTimeout(this.timeout), (this.timeout = null)) : null;
        }

        //======= END RUN =======//

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.b-scrollhack-main-square-icon {
    --opacity: 0;
    @include transition(0.5s ease opacity);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    position: relative;
    z-index: 999;
    height: 100%;
    opacity: var(--opacity);
    &--visible {
        --opacity: 1;
    }
    &__lottie {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 4rem;
    }
}
</style>
