<template>
    <div class="c-app-footer-top-contact-form">
        <h3>{{ $t("footer.form.title") }}</h3>
        <app-form
            form-name="Contact"
            form-template="contact"
            :subject="$t('form.email.subject')"
            :toEmail="email"
            :successText="successText"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import AppForm from "components/AppForm/AppForm";

export default {
    components: {
        AppForm
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            global: state => state.global.settings,
            forms: state => state.global.forms
        }),
        email() {
            return this.global && this.global.email ? this.global.email : null;
        },
        successText() {
            return this.forms ? this.forms.success : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-app-footer-top-contact-form {
    // @include pre-animation(0px, 100px);
    --margin-right: 0rem;
    display: flex;
    flex-direction: column;
    color: var(--color-dark);

    h3 {
        @include pre-animation(0px, 30px);
        @include t3;
        font-size: 3rem !important;
        margin-bottom: var(--grid-gutter);
    }
}
</style>
