<template>
    <div :class="className" :style="cellsHeight ? `--slide-height: ${cellsHeight}px` : false">
        <lightbox v-if="zoom" ref="zoom" :items="slides" :index="zoomIndex" @close="zoomIndex = null" />

        <flickity ref="flkty" :options="opts" class="c-slider__inner">
            <div
                v-for="(slide, i) in slides"
                :key="`slider-${_uid}-slide-${i}`"
                ref="slide"
                class="c-slider__slide"
                @click="zoomIndex = i"
            >
                <slot
                    name="slide"
                    v-bind="{
                        ...slide,
                        index: i,
                        isActive: i === currentIndex,
                        delta: currentIndex - oldIndex
                    }"
                >
                    {{ slide }}
                </slot>
            </div>
        </flickity>
    </div>
</template>

<script>
import Icon from "objects/Icon";

import Flickity from "vue-flickity";
import Btn from "components/Shared/SharedBtn/SharedBtn";
import Lightbox from "components/Lightbox";

export default {
    name: "Slider",
    components: {
        Icon,
        Btn,
        Flickity,
        Lightbox
    },
    data: () => ({
        isDragging: false,
        currentIndex: 0,
        oldIndex: -1,
        flktySlides: 0,
        opts: {
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true,
            contain: true,
            accessibility: false
        },
        cellsHeight: false,
        zoomIndex: null
    }),
    props: {
        showIndex: {
            type: Boolean,
            default: false
        },
        modifier: {
            type: String,
            default: null
        },
        prevNext: {
            type: Boolean,
            default: true
        },
        translate: {
            type: Boolean,
            default: true
        },
        options: {
            type: Object,
            default: () => {}
        },
        slides: {
            type: Array,
            default: () => []
        },
        fullheight: {
            type: Boolean,
            default: false
        },
        zoom: {
            type: Boolean,
            default: false
        }
    },

    created() {
        this.opts = { ...this.opts, ...this.options };

        if (!this.translate || this.zoom) {
            this.opts.draggable = false;
        }
    },
    mounted() {
        const flkty = this.$refs.flkty;

        flkty.on("dragStart", (this.onDragStart = () => (this.isDragging = true)));
        flkty.on("dragEnd", (this.onDragEnd = () => (this.isDragging = false)));

        this.flktySlides = flkty.slides().length;

        flkty.on(
            "resize",
            (this.onResize = () => {
                this.flktySlides = flkty.slides().length;
                this.setSlidesHeight();
            })
        );

        flkty.on(
            "select",
            (this.onSelect = i => {
                this.oldIndex = this.currentIndex;
                this.currentIndex = i;
                this.$emit("select", this.currentIndex, this.oldIndex);
            })
        );

        this.setSlidesHeight();
    },
    computed: {
        className() {
            let classname = "c-slider";

            if (this.modifier) {
                classname += ` -${this.modifier}`;
            }

            if (!this.translate) {
                classname += ` -no-translate`;
            }

            if (this.zoom) {
                classname += ` -zoom`;
            }

            if (this.isDragging) {
                classname += ` is-dragging`;
            }

            if (this.cellsHeight) {
                classname += ` -fullheight`;
            }

            return classname;
        },
        totalSlides() {
            return this.slides.length;
        },
        sliderProgress() {
            const progress = this.currentIndex / (this.flktySlides - 1);
            return progress;
        },
        slideIndex() {
            return this.currentIndex >= 9 ? this.currentIndex + 1 : `0${this.currentIndex + 1}`;
        },
        slidesCount() {
            return this.slides.length >= 10 ? this.slides.length : `0${this.slides.length}`;
        }
    },
    methods: {
        select(index) {
            this.$refs.flkty.select(index);
        },
        resize() {
            this.$refs.flkty.resize();
        },
        previous() {
            this.$refs.flkty.previous();
        },
        next() {
            this.$refs.flkty.next();
        },
        setSlidesHeight() {
            if (!this.fullheight && this.translate) {
                return;
            }
            this.cellsHeight = false;

            this.$nextTick(() => {
                let newHeight = 0;
                this.$refs.slide.forEach(slide => {
                    const slideHeight = slide.offsetHeight;

                    if (slideHeight > newHeight) {
                        newHeight = slideHeight;
                    }
                });

                this.cellsHeight = newHeight;
            });
        }
    },
    beforeDestroy() {
        const flkty = this.$refs.flkty;

        flkty.off("dragStart", this.onDragStart);
        flkty.off("dragEnd", this.onDragEnd);
        flkty.off("resize", this.onResize);
        flkty.off("select", this.onSelect);
    },
    watch: {
        zoomIndex($v) {
            if (this.$refs.zoom) this.$refs.zoom.index = $v;
        }
    }
};
</script>

<style lang="scss">
.c-slider {
    --slide-width: 100%;
    --slide-gap: var(--grid-gutter);
    --nav-bg: #{$color-light};
    height: auto;

    &.-no-translate {
        --slide-width: 100%;

        .flickity-slider {
            transform: none !important;
        }

        .c-slider__slide {
            &:not(:first-child) {
                top: 0;
                left: 0 !important;
                width: 100%;
            }

            &.is-selected {
                z-index: 1;
            }
        }
    }

    &.-fullheight {
        .flickity-viewport {
            height: var(--slide-height) !important;
        }

        .c-slider__slide {
            height: var(--slide-height);
        }
    }

    &.is-dragging {
        a,
        button {
            pointer-events: none;
        }
    }

    &.-zoom {
        .c-slider__slide {
            cursor: zoom-in;
        }
    }
    @media #{md("sm")} {
        --slide-width: calc(1 / 4 * 100%);
    }

    @media #{md("xl")} {
        --slide-width: calc(1 / 5 * 100%);
    }
}

.c-slider__slide {
    display: block;
    // width: var(--slide-width);
    margin-right: var(--slide-gap);

    user-select: none;
}

.c-slider__side {
    display: flex;
    align-items: center;
    margin-bottom: var(--grid-gutter);

    .c-slider__afterNav {
        position: absolute;
        right: 0;
    }
    .c-slider__beforeNav {
        margin-right: var(--grid-gutter);
    }
}

.c-slider__nav {
    --slider-progress: 0;
    display: flex;
    overflow: hidden;
    border: solid 1px currentColor;

    &.is-disabled {
        pointer-events: none;
    }
    .c-slide__count {
        --slider-btn-width: 5.25em;
        background: var(--nav-bg);
        border-left: solid 1px currentColor;
        border-right: solid 1px currentColor;
        padding: 0.75em 1em;
        text-align: center;
        width: var(--slider-btn-width);
        user-select: none;
    }
}

.c-slider__btn {
    display: flex;
    align-items: center;
    padding: 0.75em 1em;
    cursor: pointer;

    &:before {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.2s ease-out;
    }

    &:hover:before {
        transition: transform 0.3s ease-out;
    }

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.-prev {
        border-top-left-radius: $border-radius-sm;
        border-bottom-left-radius: $border-radius-sm;
        background: var(--nav-bg);
    }

    &.-next {
        border-top-right-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
        background: var(--nav-bg);
    }
}
</style>
