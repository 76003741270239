/*
 * Article
 */

import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'

import Crud from "../crud";
const crud = new Crud("article");

// State
const state = {
    ...crud.state,
    type: "articleTypes",
    count: null
};

// Getters
const getters = {
    ...crud.getters
};

// Actions
const actions = {
    ...crud.actions,
    fetchCount(store) {
        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});
            if(store.state.count) {
                resolve(store.state.count)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});
            }
            else {

                let categoryGroupParams = {
                    group: 'articleTypes',
                    relation: {
                        section: 'article'
                    }
                };

                fetchApi(queries['categoryGroup'], categoryGroupParams)
                .then(group => {

                    const categoryIDs = group.categories.map(g => g.id);
                    let countQueries = [];

                    categoryIDs.forEach(id => {
                        let categoryIDsParams = {
                            section: 'article',
                            categories: [{id}]
                        };
                        countQueries.push(fetchApi(queries['entryCount'], categoryIDsParams));
                    });

                    Promise.all(countQueries)
                    .then(result => {

                        let articlesCount = [];
                        categoryIDs.forEach((ID, i) => {
                            articlesCount.push({
                                id: parseInt(ID),
                                count: result[i].entryCount
                            });
                        })

                        let articlesCountTotal = 0;
                        articlesCount.forEach(article => {
                            articlesCountTotal += article.count;
                        })
                        articlesCount.push({ id: 0, count: articlesCountTotal });

                        store.commit('setCount', articlesCount)

                        resolve(articlesCount)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
                    .catch(e => {

                        reject({
                            code: 404,
                            message: e
                        })
                        store.dispatch('loader/endLoad', null, {root: true});
                    })

                });
            }
        })
    }
};

// Mutations
const mutations = {
    ...crud.mutations,
    setCount(state, count) {
        state.count = count;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
