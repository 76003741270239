<template>
    <div
        class="c-shapes-square"
        :class="{ 'c-shapes-square--responsive': isResponsive }"
        :style="`--background-color: var(--color-${backgroundColor})`"
    >
        <icon :icon="icon" class="c-shapes-square__icon" />
    </div>
</template>

<script>
import Icon from "objects/Icon";
export default {
    components: {
        Icon
    },
    props: {
        icon: {
            type: String,
            required: false,
            default: "shape--inclined-lines"
        },
        backgroundColor: {
            type: String,
            required: false,
            default: "light"
        },
        isResponsive: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shapes-square {
    --background-color: var(--color-light);
    --width-shape-square: 150px;
    --min-width: auto;
    --min-height: auto;
    //  padding-bottom: 100%;// aspect-ratio: 1/1; https://mambomambo-team.atlassian.net/browse/QUAD-252

    position: absolute;
    top: 0;
    left: 0;
    width: var(--width-shape-square);
    height: var(--width-shape-square);
    z-index: 2;
    background: var(--background-color);

    min-width: var(--min-width);
    min-height: var(--min-height);

    &--responsive {
        --width-shape-square: 80px;
        @media #{md("sm")} {
            --width-shape-square: 120px;
        }
        @media #{md("lg")} {
            --width-shape-square: 150px;
        }
    }
    &__icon {
        width: 100%;
        height: 100%;
    }
    &::v-deep svg {
        --svg-width: 100%;
        width: 100%;
    }
}
</style>
