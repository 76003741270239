<template>
    <transition name="mobile-navigation" mode="out-in">
        <div v-show="isNavOpen" key="navigation" class="c-app-nav-mobile">
            <div class="c-app-nav-mobile__inner">
                <app-nav-list class="c-app-nav-mobile__inner__list" :is-mobile="true" />
            </div>
            <app-nav-mobile-contact />
        </div>
    </transition>
</template>

<script>
import { mapState } from "vuex";

import { gsap } from "gsap/all";

import AppNavList from "components/AppNav/AppNavList";
import AppNavMobileContact from "components/AppNav/AppNavMobile/AppNavMobileContact";

export default {
    components: {
        AppNavList,
        AppNavMobileContact
    },
    computed: {
        ...mapState({
            isNavOpen: state => state.navigation.isNavOpen,
            nav: state => state.global.navs
        })
    },

    mounted() {
        this.$nextTick(() => {
            this.initMobileTimeline();
        });
    },
    watch: {
        isNavOpen(bool) {
            this.timeline ? this.toggleTimeline(bool) : null;
        },
        nav() {
            this.$nextTick(() => {
                this.initMobileTimeline();
            });
        }
    },

    methods: {
        initMobileTimeline() {
            if (!this.nav.length || this.timeline) return;
            this.timeline = gsap.timeline({ paused: true });
            this.timeline
                .to(".c-app-nav-mobile .c-navigation-link__link", {
                    opacity: 1,
                    y: 0,
                    ease: "power3.inOut",
                    duration: 0.4,
                    stagger: 0.1,
                    delay: 0.4
                })
                .to(".c-app-nav-mobile-contact__list li", {
                    opacity: 1,
                    y: 0,
                    ease: "power3.inOut",
                    duration: 0.4,
                    stagger: 0.1
                })
                .to(".c-app-nav-mobile-contact", {
                    "--scale-line": "1",
                    ease: "power3.inOut",
                    duration: 0.5
                });
        },
        toggleTimeline(bool) {
            bool ? this.timeline.play() : this.timeline.reverse();
        }
    }
};
</script>

<style lang="scss" scoped>
.c-app-nav-mobile {
    --background-color-header: var(--color-light);
    --nav-position: absolute;
    --min-height: var(--vh);
    --display-nav-mobile: flex;

    @include full-screen-dom(absolute);
    background: var(--background-color-header);

    height: var(--min-height);
    overflow: auto;

    margin-left: calc(0px - var(--grid-gutter-half));
    margin-right: calc(0px - var(--grid-gutter-half));

    display: var(--display-nav-mobile);
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;

    @media #{md("sm")} {
        --display-nav-mobile: none;
    }

    &__inner {
        --nav-direction: column;
        display: var(--display-nav-mobile);
        flex-direction: var(--nav-direction);
        flex: 1;
        flex-grow: 1;
        flex-shrink: 0;

        @media #{md("sm", "max")} {
            &:before {
                content: " ";
                display: block;
                height: 7rem;
                width: 100%;
                flex-shrink: 0;
            }
        }
    }
}

.mobile-navigation-enter-active,
.mobile-navigation-leave-active {
    $transition-burger: transform 0.5s $in-out-cubic;
    @include transition($transition-burger);
}

.mobile-navigation-enter,
.mobile-navigation-leave-to {
    @include transform(translateX(-100%));
}
</style>
