<template>
    <div class="b-articles-title | col-6 col-8-sm col-4-xl" :class="{ 'b-articles-title--hidden': isHidden }">
        <h1 v-if="heading" class="b-articles-title__copy" v-html="heading" />
        <p v-if="description" class="b-articles-title__copy" v-html="description" />
    </div>
</template>

<script>
export default {
    props: {
        heading: {
            type: String,
            required: false,
            default: null
        },
        description: {
            type: String,
            required: false,
            default: null
        },
        isHidden: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>
<style lang="scss" scoped>
.b-articles-title {
    --margin-bottom: var(--grid-gutter-2X);
    --opacity: 1;
    --translateCopy: 0rem, 0rem;
    --translateTitle: 0rem, 0rem;

    @include t-content(var(--grid-gutter));
    @include transition(0.5s ease opacity);
    display: flex;
    flex-direction: column;
    order: var(--article-order-title);
    padding-bottom: var(--margin-bottom);
    opacity: var(--opacity);

    @media #{md("lg")} {
        &--hidden {
            --opacity: 0;
            --translateCopy: -10rem, 0rem;
            --translateTitle: -20rem, 0rem;
        }
    }

    h1 {
        @include t-cms;
        @include t1;
        @include transition(0.5s ease transform);
        @include transform(translate(var(--translateTitle)));
    }
    p {
        @include transition(0.5s ease transform);
        @include transform(translate(var(--translateCopy)));
    }
}
</style>
