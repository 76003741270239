// State
const state = {
    isTitleVisible: false
};

// Getters
const getters = {};

// Actions
const actions = {
    toggleTitleVisibility(store, bool) {
        store.commit("updateTitleVisibility", bool);
    }
};

// Mutations
const mutations = {
    updateTitleVisibility(state, bool) {
        state.isTitleVisible = bool;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
