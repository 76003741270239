import Vue from 'vue'
import { gsap } from 'gsap'

const roundPercent = Symbol('roundPercent');
const whichTransitionEvent = Symbol('whichTransitionEvent');


const viewClass = new class View {

    constructor() {
        // console.log(`${this.constructor.name}:init`)

        this.classname = {
            reveal: 'js-reveal',
            revealVisible: 'is-visible',
        }

        // Correct transitionend event name
        this.transitionEnd = this[whichTransitionEvent]() ? this[whichTransitionEvent]() : 'transitionsEnd';

        this.pos, this.lastPos = {};

        this.items = {}
        this.activeItems = []

        // Define observer
        let $target
        this.observer = new IntersectionObserver($observables => {
            this.lastPos = {}
            $observables.forEach(($observable) => {
                $target = $observable.target
                if ($observable.isIntersecting) {
                    this.activeItems.push($target.id)
                } else {
                    this.activeItems = this.activeItems.filter(i => i !== $target.id)
                }
            })
        });

        // Window events
        window.addEventListener('load', () => {

            // Scroll in page
            this.scroll = {
                x: window.pageYOffset,
                y: window.pageXOffset
            }

            // Set sizes
            this.setSizes()

            // RAF Loop
            window.requestAnimationFrame(this.loop.bind(this))
        })

        window.addEventListener('resize', this.setSizes.bind(this))
    }

    setSizes() {
        //console.log(`${this.constructor.name}:setSizes`)

        this.W = {
            w: window.innerWidth,
            h: window.innerHeight,
        }

        this.doc = {
            h: document.documentElement.scrollHeight,
            w: document.documentElement.scrollWidth
        }
    }

    observeItem(id, item) {
        // console.log(`${this.constructor.name}:observeItem`)

        this.items[id] = item
        this.observer.observe(this.items[id].element)
    }

    unobserveItem(id) {
        // console.log(`${this.constructor.name}:unobserveItem`, id)

        this.observer.unobserve(this.items[id].element)
        delete this.items[id]
        this.activeItems = this.activeItems.filter(i => i !== id)
    }

    loop() {
        // console.log(`${this.constructor.name}:loop`)

        this.pos = {
            top: window.pageYOffset,
            right: window.pageXOffset + this.W.w,
            bottom: window.pageYOffset + this.W.h,
            left: window.pageXOffset,
        }

        // Return if same position
        if(JSON.stringify(this.pos) === JSON.stringify(this.lastPos)) {
            requestAnimationFrame(this.loop.bind(this));
            return;

        }
        // If different position
        else {
            this.lastPos = this.pos;
        }

        // Loop through active items and trigger related properties
        let i, inType;
        this.activeItems.forEach(id => {
            i = this.items[id]

            inType = this.getInType(i)

            // Function handler
            if (i.handler) {
                i.handler({
                    percent: {
                        top: inType.percent.top,
                        bottom: inType.percent.bottom,
                        left: inType.percent.left,
                        right: inType.percent.right,
                        center: {
                            x: inType.percent.center.x,
                            y: inType.percent.center.y,
                        },
                        scroll: {
                            x: this[roundPercent](this.pos.top / (this.doc.h - this.W.h)),
                            y: this[roundPercent](this.pos.left / (this.doc.w - this.W.w))
                        }
                    },
                    rect: inType.rect,
                    scroll: {
                        x: this.pos.top - this.scroll.x,
                        y: this.pos.left - this.scroll.y,
                    },
                    target: i.element
                })
            }

            // Reveal
            if (i.reveal !== false) {
                //console.log('reveal',  i)

                this.items[i.id].reveal = false

                i.element.classList.add(this.classname.revealVisible)

                if(i.reveal.end) {
                    let item = i
                    setTimeout(() => {
                        this.revealEnd(item)
                    }, item.reveal.end * 1000);
                } else {
                    i.element.addEventListener(this.transitionEnd, this.revealEnd(i));
                }
            }

            // Parallax
            if (i.parallax !== false) {
                //console.log('parallax',  i)

                gsap.to(i.element, i.parallax.duration, {
                    x: (1 - inType.percent.top) * i.parallax.x * 10,
                    y: (1 - inType.percent.top) * i.parallax.y * 10,
                    ease: i.parallax.ease,
                })
            }
        })

        // Update scroll values
        this.scroll = {
            x: this.pos.top,
            y: this.pos.left,
        };

        requestAnimationFrame(this.loop.bind(this))
    }

    revealEnd(i) {
        // console.log(`${this.constructor.name}:revealEnd`, i)

        i.element.classList.remove(this.classname.reveal)
        i.element.classList.remove(this.classname.revealVisible)
        i.element.removeEventListener(this.transitionEnd, this.revealEnd)
    }

    getInType(i) {
        // console.log(`${this.constructor.name}:getInType`, i)

        let rect = i.element.getBoundingClientRect();
        let elementTop = rect.top + this.pos.top;
        let elementLeft = rect.left + this.pos.left;
        let elementBottom = elementTop + rect.height;
        let topIn = elementTop > this.pos.top && elementTop < this.pos.bottom;
        let bottomIn = elementBottom > this.pos.top && elementBottom < this.pos.bottom;
        let percentInView = topIn || bottomIn ? ((bottomIn ? elementBottom : this.pos.bottom) - (topIn ? elementTop : this.pos.top)) / rect.height : 0;
        let centerPercentY = (elementTop - this.pos.top + rect.height / 2) / this.W.h;
        let centerPercentX = (elementLeft - this.pos.left + rect.width / 2) / this.W.w;

        let topPercent = this[roundPercent](rect.top + rect.height) / (this.W.h + rect.height);
        let bottomPercent = 1 - topPercent;
        let leftPercent = this[roundPercent](rect.left + rect.width) / (this.W.w + rect.width);
        let rightPercent = 1 - leftPercent;

        return {
            percent: {
                inView: this[roundPercent](percentInView),
                top: topPercent,
                bottom: bottomPercent,
                left: leftPercent,
                right: rightPercent,
                center: {
                    x: this[roundPercent](centerPercentX),
                    y: this[roundPercent](centerPercentY),
                },
            },
            rect: rect
        }
    }

    /**
    * Round percentage to a thousandth
    * @return {number} The rounded number
    */
    [roundPercent](v) {
        return (v * 1000 | 0) / 1000
    }

    /**
    * Use the correct `transitionend` event
    * @return {string} The prefixed event name
    */
    [whichTransitionEvent]() {
        const el = document.createElement('div');
        const transitions = {
            'OTransition':'oTransitionEnd',
            'MozTransition':'transitionend',
            'WebkitTransition':'webkitTransitionEnd',
            'transition':'transitionend',
        };

        for(let t in transitions){
            if( el.style[t] !== undefined ){
                return transitions[t];
            }
        }
    }
}

let itemIndex = 0
export const view = Vue.directive('view', {
    inserted: function($el, bind) {

        // Return if value is set to false
        if(typeof bind.value === 'boolean' && !bind.value) {
            return
        }

        // Define item id
        let id = $el.id || ('view-id-' + itemIndex++)

        // Set default item values
        let item = viewClass.items[id] || {
            id,
            rect: {},
            element: $el,
            handler: false,
            reveal: false,
            parallax: false,
        }

        let propertyValue
        const styles = getComputedStyle($el);

        // Set reveal
        if (bind.modifiers.reveal === true) {
            item.reveal = {}

            propertyValue = styles.getPropertyValue('--reveal-end')
            item.reveal.end = propertyValue ? propertyValue : false

            item.element.classList.add(viewClass.class.reveal)
        }

        // Set parallax
        if (bind.modifiers.parallax === true) {
            item.parallax = {}

            propertyValue = styles.getPropertyValue('--parallax-duration')
            item.parallax.duration = propertyValue ? propertyValue : .2
            propertyValue = styles.getPropertyValue('--parallax-x')
            item.parallax.x = propertyValue ? propertyValue : 0
            propertyValue = styles.getPropertyValue('--parallax-y')
            item.parallax.y = propertyValue ? propertyValue : 0
            propertyValue = styles.getPropertyValue('--parallax-ease')
            item.parallax.ease = propertyValue ? propertyValue : 'sine.out'
        }

        // Check if element has handler
        if(typeof bind.value === 'function') {
            item.handler = bind.value
        }

        $el.id = id

        viewClass.observeItem(id, item)
    },
    unbind: function($el) {
        viewClass.unobserveItem($el.id)
        delete view.items[$el.id]
    }
})
