import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            isDev: state => state.global.isDev
        })
    },
    methods: {
        isDevEnv() {
            return this.isDev && process.env.NODE_ENV === "development" ? true : false;
        }
    }
};
