<template>
    <div v-if="cards.length > 0" :class="className">
        <slider
            v-if="sliderIsActive"
            :options="sliderOpts"
            :slides="cards"
            :prevNext="prevNext"
            :fullwidth="fullwidth"
            :fullheight="true"
            ref="slider"
            class="c-card-grid__grid"
            :show-index="showIndex"
        >
            <template #beforeNav>
                <div v-if="$slots.beforeNav" class="c-slider__beforeNav">
                    <slot name="beforeNav" />
                </div>
            </template>
            <template #slide="card">
                <component :is="componentName" :element="card" />
            </template>
            <template #afterNav>
                <div v-if="$slots.afterNav" class="c-slider__afterNav">
                    <slot name="afterNav" />
                </div>
            </template>
        </slider>

        <div v-else class="c-card-grid__grid" ref="grid">
            <component v-for="(card, i) in cards" :key="`card-grid-${i}`" :is="componentName" :element="card">
                <template #asset>
                    <slot name="cardAsset" v-bind="card" />
                </template>
                <template #content>
                    <slot name="cardContent" v-bind="card" />
                </template>
                <template #link>
                    <slot name="cardLink" v-bind="card" />
                </template>
            </component>
        </div>
    </div>
</template>

<script>
import Slider from "components/Slider";
import Card from "components/Card/Card";

export default {
    name: "CardGrid",
    components: {
        Slider,
        Card
    },
    props: {
        showIndex: Boolean,
        cardType: {
            type: String,
            required: false,
            default: null
        },
        cards: {
            type: Array,
            default: () => []
        },
        slider: {
            type: Boolean,
            default: false,
            required: false
        },
        prevNext: {
            type: Boolean,
            default: true
        },
        fullwidth: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        sliderIsActive: false,
        sliderOpts: {
            watchCSS: true,
            groupCells: true,
            percentPosition: false
        }
    }),
    mounted() {
        if (this.slider) {
            this.setSlider();

            window.addEventListener("resizeEnd", this.setSlider);
        }
    },
    computed: {
        componentName() {
            return this.cardType ? `card-${this.cardType}` : `card`;
        },
        className() {
            let classname = "c-card-grid";

            classname += this.sliderIsActive ? " -slider" : " -no-slider";

            classname += ` -${this.cardType}`;

            return classname;
        }
    },
    methods: {
        setSlider() {
            this.sliderIsActive = false;
            this.cellsHeight = false;

            this.$nextTick(() => {
                if (this.$refs.grid) {
                    const gridRows = window
                        .getComputedStyle(this.$refs.grid)
                        .getPropertyValue("grid-template-rows")
                        .split(" ").length;

                    if (gridRows > 1) {
                        this.sliderIsActive = true;
                    }
                }
            });
        }
    },
    beforeDestroy() {
        if (this.slider) {
            window.removeEventListener("resizeEnd", this.setSlider);
        }
    }
};
</script>

<style lang="scss">
.c-card-grid {
    $self: &;
    --grid-auto-size: auto-fill;
    --grid-min-width: 18em;
    --grid-custom-gap: var(--elements-grid-gap);

    &.-solution {
        --grid-auto-size: max-content;
    }

    &.-no-slider {
        .c-card-grid__grid {
            display: grid;
            grid-gap: var(--grid-custom-gap);
            grid-template-columns: repeat(var(--grid-auto-size), minmax(var(--grid-min-width), 1fr));
            justify-content: center;
            overflow-anchor: none;
        }

        .c-card {
            --card-width: 100%;
        }
    }

    &.-slider {
        .c-slider {
            --slide-width: 18em;
        }

        &.-solution {
            .c-slider {
                --slide-width: calc(100%);
                --nav-bg: #{$color-light};

                @media #{md("sm")} {
                    --slide-width: calc(3 / 4 * 100%);
                }

                @media #{md("md")} {
                    --slide-width: calc(11 / 12 * 100%);
                }

                @media #{md("lg")} {
                    --slide-width: calc(3 / 4 * 100%);
                }

                @media #{md("xl")} {
                    --slide-width: 62rem;
                }
            }

            .c-btn.-underline {
                --btn-hover-bg-color: #{$color-light};
            }

            @media #{md("sm", "max")} {
                .c-slider__side {
                    flex-direction: column;
                    align-items: flex-start;

                    .c-slider__afterNav {
                        position: relative;
                        padding-top: var(--grid-gutter);
                    }
                    .c-slide__count {
                        display: none;
                    }

                    .c-slider__nav {
                        position: absolute;
                        bottom: 0;
                        overflow: visible;
                        left: 0;
                        right: 0;
                        transform: translateY(300px);
                        z-index: 1;
                        pointer-events: none;

                        .-prev {
                            border: 0;
                            pointer-events: auto;
                            transform: translateX(calc(var(--grid-gutter-half) / 2 * -1));
                        }
                        .-next {
                            border: 0;
                            transform: translateX(calc(var(--grid-gutter-half) / 2));
                            position: absolute;
                            right: 0;
                            pointer-events: auto;
                        }
                    }
                }
            }
        }

        .c-slider__inner:after {
            content: "flickity";
            display: none;
        }

        .c-card {
            width: 100%;

            &:not(:first-child) {
                margin-left: 1em;
            }
        }
    }
}
</style>
