<template>
    <footer id="footer" class="l-footer" ref="footer" v-reveal>
        <div class="l-footer__inner">
            <app-footer-top />
            <app-footer-bottom />
        </div>
    </footer>
</template>

<script>
import { gsap } from "gsap";

import AppFooterBottom from "components/AppFooter/AppFooterBottom/AppFooterBottom";
import AppFooterTop from "components/AppFooter/AppFooterTop";
export default {
    name: "AppFooter",
    components: {
        AppFooterBottom,
        AppFooterTop
    },
    data() {
        return {
            isAnimationRunning: false,
            gsapAnimation: {
                timeline: null,
                durations: { duration1: 0.5 },
                ease: "power1.inOut"
            }
        };
    },
    mounted() {
        this.observer = new MutationObserver(mutations => {
            for (const m of mutations) {
                const newValue = m.target.getAttribute(m.attributeName);
                this.$nextTick(() => {
                    this.onClassChange(newValue, m.oldValue);
                });
            }
        });

        this.observer.observe(this.$refs.footer, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ["class"]
        });
    },
    beforeDestroy() {
        this.observer.disconnect();
        this.destroyTimeline();
    },
    watch: {
        $route() {
            this.destroyTimeline();
        }
    },
    methods: {
        ////////////////////////////////
        //       START TRIGGER ANIMATION
        ////////////////////////////////
        onClassChange(classAttrValue) {
            const classList = classAttrValue.split(" ");
            if (classList.includes("is-visible")) {
                !this.gsapAnimation.timeline ? this.runTimeLine() : null;
            }
        },
        ////////////////////////////////
        //       END TRIGGER ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////
        runTimeLine() {
            this.gsapAnimation.timeline = gsap.timeline({ paused: false });
            this.gsapAnimation.timeline

                .to(
                    ".c-app-footer-top-contact",
                    {
                        y: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "start"
                )

                .to(".c-app-footer-top-contact", {
                    x: 0,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                })

                .to(
                    ".c-shapes-square",
                    {
                        x: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "start"
                )
                .to(".c-shapes-square", {
                    y: 0,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                })
                // .to(".c-app-footer-bottom-shapes__large", {
                //     y: 0,
                //     ease: this.gsapAnimation.ease,
                //     duration: this.gsapAnimation.durations.duration1
                // })
                // .to(".c-app-footer-bottom-shapes__large", {
                //     x: 0,
                //     ease: this.gsapAnimation.ease,
                //     duration: this.gsapAnimation.durations.duration1
                // })
                .to(
                    ".c-app-footer-top-meet__list li",
                    {
                        x: 0,
                        opacity: 1,
                        ease: this.gsapAnimation.ease,

                        stagger: {
                            each: 0.1
                        }
                    },
                    "contactForm"
                )
                .to(
                    ".c-app-footer-top-contact-form h3",
                    {
                        y: 0,
                        opacity: 1,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "contactForm"
                )
                .to(
                    ".c-app-footer-top-contact-form .c-form__inner > div",
                    {
                        y: 0,
                        opacity: 1,
                        ease: this.gsapAnimation.ease,
                        stagger: {
                            each: 0.1
                            // ease: this.gsapAnimation.ease
                        }
                    },
                    "contactForm+=0.1"
                )
                .to(
                    ".c-app-footer-bottom__copy",
                    {
                        opacity: 1,
                        y: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "footer-bottom"
                )
                .to(
                    ".c-app-footer-bottom__credits",
                    {
                        opacity: 1,
                        y: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "footer-bottom"
                );
        },
        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////
        ////////////////////////////////
        //       START DESTROY
        ////////////////////////////////
        destroyTimeline() {
            this.gsapAnimation.timeline
                ? (this.clearAllProps(), this.gsapAnimation.timeline.kill(), (this.gsapAnimation.timeline = null))
                : null;
        },
        clearAllProps() {
            gsap.set(".c-app-footer-top-contact", { clearProps: "all" });
            gsap.set(".c-shapes-square", { clearProps: "all" });
            gsap.set(".c-app-footer-top-meet__list li", { clearProps: "all" });
            gsap.set(".c-app-footer-top-contact-form h3", { clearProps: "all" });
            gsap.set(".c-app-footer-top-contact-form .c-form__inner > div", { clearProps: "all" });
            gsap.set(".c-app-footer-bottom__copy", { clearProps: "all" });
            gsap.set(".c-app-footer-bottom__credits", { clearProps: "all" });
        }
        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.l-footer {
    background-color: var(--color-dark);
    margin-top: 12.5rem;
}
</style>
