<template>
    <button class="c-btn-share" v-on:click="share">
        <btn
            tag="span"
            color="dark"
            :has-underline="true"
            overwrite-line="malibu"
            :label="$t('shared.share')"
            iconBefore="share"
        />
    </button>
</template>

<script>
import Btn from "@/templates/components/Shared/SharedBtn/SharedBtn";

export default {
    name: "SocialShare",
    components: {
        Btn
    },
    props: {
        title: {
            type: String,
            required: false,
            default: document.title
        },
        url: {
            type: String,
            required: false,
            default: document.location.href
        }
    },
    methods: {
        share: function() {
            if (navigator.canShare) {
                navigator.share({ url: this.url, title: this.title });
            } else {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.url}&t=${this.title}`);
            }
        }
    }
};
</script>
