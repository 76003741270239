const initialDataSteps = window.__initialData__.steps;
// State
const state = {
    steps: []
};

// Getters
const getters = {};

// Actions
const actions = {
    preload(store) {
        store.commit("setSteps", initialDataSteps);
    }
};

// Mutations
const mutations = {
    setSteps(state, steps) {
        state.steps = steps;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
