<template>
    <li class="b-logo">
        <asset :asset="asset" />
    </li>
</template>

<script>
import Asset from 'objects/Asset';

export default {
    name: "Logo",
    components: {
        Asset
    },
    props: {
        asset: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="scss">
.b-logo {
    opacity: 0.4;
    filter: grayscale(100%);

    .o-asset {
        --asset-width: 8rem;
        --asset-height: calc(var(--asset-width) * 0.41);
        width: var(--asset-width);
        height: var(--asset-height);

        @media #{md("sm")} {
            --asset-width: 11rem;
        }

        img {
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>
