<template>
    <div :class="className">
        <textarea
            v-if="type === 'textarea'"
            :id="`input-${_uid}`"
            :name="name"
            ref="textarea"
            class="o-input-text__input"
            v-model="value"
            :required="required"
            @focus="isActive = true"
            @blur="isActive = false"
        ></textarea>
        <input
            v-else
            :id="`input-${_uid}`"
            :name="name"
            ref="input"
            class="o-input-text__input"
            v-model="value"
            :type="type"
            :required="required"
            :autocomplete="autocomplete"
            @focus="isActive = true"
            @blur="isActive = false"
        />
        <label v-if="label && (!disabled || isFilled)" :for="`input-${_uid}`" class="o-input-text__label">
            <span class="o-input-text__text">
                {{ required ? `${$t(`form.inputs.${label}`)}*` : $t(`form.inputs.${label}`) }}
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: "AppInputText",
    props: {
        label: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: "text"
        },
        name: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: null
        },
        defaultValue: {
            type: String,
            default: null
        }
    },
    data: () => ({
        value: "",
        isActive: false,
        isFilled: false
    }),
    mounted() {
        if (this.defaultValue) {
            this.value = this.defaultValue;
        }
    },
    computed: {
        className() {
            let className = "o-input-text";

            if (this.textarea) {
                className += " -textarea";
            }

            if (this.date) {
                className += " -date";
            }

            if (this.disabled) {
                className += " is-disabled";
            }

            if (this.isActive || this.isFilled) {
                className += " is-filled";
            }

            return className;
        },
        textarea() {
            return this.type === "textarea";
        },
        date() {
            return this.type === "date";
        }
    },
    methods: {
        reset() {
            this.value = "";
        },
        update(value) {
            this.value = value;
        }
    },
    watch: {
        value(val) {
            this.$emit("input", val);
            if (val !== "") {
                this.isFilled = true;
            } else {
                this.isFilled = false;
            }
        },
        defaultValue(value) {
            this.value = value;
        }
    }
};
</script>

<style lang="scss">
.o-input-text {
    color: var(--input-color-text);

    &.-date {
        display: flex;

        .o-input-text__input {
            flex-grow: 1;
            width: auto;
            padding-top: 0;
            text-transform: uppercase;
        }

        .o-input-text__label {
            position: relative;
            padding-right: 3em;
            padding-left: 0;
            order: -1;
            white-space: nowrap;
        }
    }

    &.is-filled {
        &:not(.-date) .o-input-text__text {
            transform: scale(0.6) translate(0, -50%);
        }
    }

    &.is-disabled {
        pointer-events: none;
        .o-input-text__label {
            color: var(--input-disabled-color-text);
        }
        .o-input-text__input {
            color: var(--input-disabled-color-text);
            background-color: var(--input-disabled-color-bg);
        }
    }
}

.o-input-text__input {
    width: 100%;
    height: var(--input-height);
    padding-right: 0.8em;
    padding-left: 0em;
    padding-top: 1em;
    background: none;
    border-bottom: 1px solid var(--input-border-color);

    @at-root textarea#{&} {
        height: calc(var(--input-height) * 5);
        padding-top: 1.6em;
        padding-bottom: 0.9em;
        resize: none;
    }
}

.o-input-text__label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: var(--input-height);
    padding-left: 0em;
    pointer-events: none;
}

.o-input-text__text {
    display: inline-block;
    line-height: 1;
    transform-origin: 0 0;
    transition: transform 0.2s ease-out;
}
</style>
