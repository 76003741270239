<template>
    <ul class="b-steps-grid | bs-grid">
        <block-steps-card v-for="(step, index) in steps" :key="index" :step="step" :prefix="urlPrefix" />
        <div ref="stepBtn" class="b-steps-grid__btn">
            <shared-btn-square
                v-if="page"
                :label="label"
                theme="dark"
                :href="page.uri"
                icon-after="arrow-right--small"
            />
            <!-- class="b-steps-grid__btn" -->
        </div>
    </ul>
</template>

<script>
import { gsap } from "gsap";

import { mapState } from "vuex";

import variables from "@/mixins/variables.js";

import BlockStepsCard from "blocks/BlockSteps/BlockStepsCard/BlockStepsCard";
import SharedBtnSquare from "components/Shared/SharedBtn/SharedBtnSquare";
export default {
    mixins: [variables],
    components: {
        BlockStepsCard,
        SharedBtnSquare
    },
    props: {
        page: {
            type: Object,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        isAnimationRunning: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        ...mapState({
            navs: state => state.global.navs,
            steps: state => state.steps.steps
        }),
        urlPrefix() {
            return this.page ? "/" + this.page.uri : null;
        }
    },
    data() {
        return {
            gsapAnimation: {
                timeline: null,
                durations: { duration1: 0.5 },
                ease: "power1.inOut"
            }
        };
    },
    watch: {
        isAnimationRunning(bool) {
            bool ? this.runTimeline() : null;
        }
    },

    beforeDestroy() {
        this.destroyTimeline();
    },
    methods: {
        runTimeline() {
            this.gsapAnimation.timeline = gsap.timeline({ paused: false });

            this.$refs.stepBtn ? this.addBtnAnimation(this.$refs.stepBtn) : null;
        },
        addBtnAnimation(target) {
            this.gsapAnimation.timeline
                .to(
                    target,
                    {
                        opacity: 1,
                        x: 0,
                        ease: this.gsapAnimation.ease,
                        duration: this.gsapAnimation.durations.duration1
                    },
                    "start"
                )
                .to(target, {
                    y: this.isMobile ? "0%" : "-200%",
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                })
                .to(".b-step-card", {
                    marginTop: 0, // margin top instead of translate to avoid weird z index issue after animation
                    opacity: 1,
                    stagger: {
                        from: "end",
                        each: 0.3,
                        ease: this.gsapAnimation.ease
                    }
                });
        },

        ////////////////////////////////
        //       START DESTROY
        ////////////////////////////////
        destroyTimeline() {
            this.gsapAnimation.timeline
                ? (this.gsapAnimation.timeline.kill(), (this.gsapAnimation.timeline = null))
                : null;
        }
        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.b-steps-grid {
    @include reset-list;
    --actions-grid-margin: var(--grid-gutter-2X);
    --actions-rows: repeat(4, 19rem);
    counter-reset: steps;
    row-gap: 0;
    grid-template-rows: var(--actions-rows);
    margin-top: var(--actions-grid-margin);

    @media #{md("xs")} {
        --actions-grid-margin: var(--grid-gutter-4X);
        --actions-rows: 9.6rem 12.5rem 17rem 14rem;
    }

    &__btn {
        --action-cta-column: auto / span 4;
        --action-cta-row-start: auto;
        --action-cta-translate-x: var(--grid-gutter-minus);
        --action-cta-translate-y: 0;
        @include transform(translate(-100px, -100%));
        // @include transform(translate(-100px, 100px));
        grid-column: var(--action-cta-column);
        grid-row-start: var(--action-cta-row-start);
        margin-left: var(--grid-gutter-minus);
        margin-top: 3px;
        // opacity: 0;
        // transform: translate(var(--action-cta-translate-x), var(--action-cta-translate-y));
        // margin-left:calc(

        @media #{md("xs")} {
            --action-cta-column: span 3 / -1;
            --action-cta-row-start: 2;
            --action-cta-translate-x: 0;
            --action-cta-translate-y: -100%;
            @include transform(translate(-100px, -400%));
        }

        @media #{md("md")} {
            // --action-cta-translate-x: var(--grid-gutter-minus);
        }
    }
}
</style>
