<template>
    <div class="c-hero">
        <div v-if="copy" class="c-hero__inner | wrap bs-grid">
            <!-- images -->
            <hero-visual :header-type="headerType" />

            <!-- copy -->
            <hero-left :button="button" :copy="copy" />
        </div>

        <!-- Logo + Arrow -->
        <hero-bottom :is-logo-visible="isLogoVisible" />
    </div>
</template>

<script>
import HeroLeft from "components/Hero/HeroLeft/HeroLeft";
import HeroVisual from "components/Hero/HeroVisual/HeroVisual";
import HeroBottom from "components/Hero/HeroBottom/HeroBottom";
export default {
    components: {
        HeroLeft,
        HeroVisual,
        HeroBottom
    },
    props: {
        button: {
            type: String,
            required: false,
            default: null
        },
        copy: {
            type: String,
            required: false,
            default: null
        },

        headerType: {
            type: String,
            required: false,
            validator: val => ["stairs", "triangles", "cubes", "columns", "rectangles"].includes(val)
        },
        isLogoVisible: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss">
.c-hero {
    --hero-margin-bottom: var(--grid-gutter-3X);
    margin-bottom: var(--hero-margin-bottom);
    overflow: hidden;

    @media #{md("md")} {
        --hero-margin-bottom: var(--grid-gutter-5X);
    }

    .p-home & {
        @media #{md("sm")} {
            --hero-margin-bottom: 16rem;
        }
    }

    &__inner {
        --spacing-hero: var(--grid-gutter-4X);
        --margin-bottom-hero: var(--grid-gutter-2X);
        --bs-row-gap: var(--spacing-hero);
        --min-height-hero: 20rem;
        margin-bottom: var(--margin-bottom-hero);

        min-height: var(--min-height-hero);

        @media #{md("sm")} {
            --min-height-hero: 60rem;
            --margin-bottom-hero: var(--spacing-hero);
        }
    }
}
</style>
