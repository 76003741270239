<template>
    <div class="c-pagination">
        <div class="c-pagination__progress" :style="`--progress-perc: ${progress}%;`">
            <span>
                {{ loaded }} {{ $t("articles.pagination.divider") }} {{ total }}
                <slot name="progressText" />
            </span>
            <span class="-load-progress"></span>
        </div>

        <transition name="btn-loadmore">
            <shared-btn-square
                v-show="nextPage <= totalPages"
                :label="labelButton"
                theme="dark"
                icon-after="plus"
                :class="{ '--disabled': pageChanging }"
                @click.native="changePage(nextPage)"
            />
        </transition>
    </div>
</template>

<script>
import Btn from "components/Shared/SharedBtn/SharedBtn";
import SharedBtnSquare from "components/Shared/SharedBtn/SharedBtnSquare";

export default {
    name: "Pagination",
    components: {
        Btn,
        SharedBtnSquare
    },
    props: {
        loaded: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 12
        },
        isBlocked: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        current: 1,
        pageChanging: false,
        allDisplayed: false
    }),
    created() {
        if (this.currentPage) this.current = this.currentPage;

        if (this.$route.query.page > this.totalPages) this.changePage(this.totalPages);

        if (this.$route.query.page == "all") this.$router.replace({ query: { page: undefined } });
    },
    computed: {
        totalPages() {
            return Math.ceil(this.total / this.perPage);
        },
        nextPage() {
            return this.current + 1;
        },
        prevPage() {
            return this.current - 1;
        },
        progress() {
            return (this.loaded / this.total) * 100;
        },
        labelButton() {
            return this.pageChanging
                ? `${this.$t("articles.pagination.loading")}`
                : `${this.$t("articles.pagination.show-more")}`;
        }
    },
    methods: {
        changePage(page) {
            if (this.isBlocked || this.pageChanging) return null;

            if (page > 0 && page <= this.totalPages && page !== this.current) {
                this.$emit("block", true);
                this.current = page === "all" ? 1 : parseInt(page);
                this.pageChanging = true;
                this.$nextTick(() => {
                    this.$emit("pageChange", page);
                    this.$router.replace({ query: { page } });
                });
            }
        },
        reset() {
            if (this.isBlocked || this.pageChanging) return null;

            this.allDisplayed = false;

            if (this.$route.query.page) {
                this.current = 1;
                this.pageChanging = true;
                this.$router.replace({ query: { page: undefined } });
            }
        }
    },

    watch: {
        $route() {
            this.pageChanging = false;
        }
    }
};
</script>
<style lang="scss">
.c-pagination {
    --elements-gap: var(--grid-gutter-3X);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: var(--elements-gap);

    @media #{md("lg")} {
        --elements-gap: var(--grid-gutter-4X);
    }

    &__progress {
        color: var(--color-gray-chateau);
        padding: 0 var(--grid-gutter) 0.6rem;

        .-load-progress {
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: var(--color-gray-chateau);

            &:before {
                @include pseudo-el($width: 100%, $height: 100%, $bg: var(--color-dark));
                position: absolute;
                left: 0;
                transform-origin: left;
                transform: scaleX(var(--progress-perc));
                transition: scaleX 0.5s ease-out;
            }
        }
    }

    .c-shared-btn-square {
        width: 22rem;
        margin-top: var(--grid-gutter-2X);

        &--disabled {
            pointer-events: none;
        }
    }
}
</style>
