<template>
    <div class="b-references" ref="references" v-reveal.once>
        <div class="b-references__bubbles -top" v-if="topBubbles.length > 0">
            <bubble v-for="(bubble, i) in topBubbles" :key="`b-top-bubble-${i}`" :data="bubble" />
        </div>

        <div class="b-references__content" ref="referenceContent">
            <h2 v-html="data.heading" />
        </div>

        <ul v-if="data.logos" class="b-references__logos">
            <logo v-for="(logo, i) in data.logos" :key="`b-logo-${i}`" :asset="logo" />
        </ul>

        <div class="b-references__bubbles -bottom" v-if="bottomBubbles.length > 0">
            <bubble v-for="(bubble, i) in bottomBubbles" :key="`b-bottom-bubble-${i}`" :data="bubble" />
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";

import Logo from "blocks/BlockReferences/BlockReferencesLogo";
import Bubble from "blocks/BlockReferences/BlockReferencesBubble";

export default {
    name: "BlockReferences",
    components: {
        Logo,
        Bubble
    },
    props: {
        data: false
    },
    data() {
        return {
            topBubbles: [],
            bottomBubbles: [],
            gsapAnimation: {
                timeline: null,
                durations: { duration1: 0.5 },
                ease: "power1.inOut",
                isRunning: false
            }
        };
    },
    created() {
        this.splitBubblesOnMount();
    },
    mounted() {
        this.observer = new MutationObserver(mutations => {
            for (const m of mutations) {
                const newValue = m.target.getAttribute(m.attributeName);
                this.$nextTick(() => {
                    this.onClassChange(newValue, m.oldValue);
                });
            }
        });

        this.observer.observe(this.$refs.references, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ["class"]
        });
    },
    beforeDestroy() {
        this.observer.disconnect();
        this.destroyTimeline();
    },
    methods: {
        splitBubblesOnMount() {
            const half = Math.ceil(this.data.bubbles.length / 2);
            this.topBubbles = this.data.bubbles.slice(0, half);
            this.bottomBubbles = this.data.bubbles.slice(half);
        },
        ////////////////////////////////
        //       START TRIGGER ANIMATION
        ////////////////////////////////
        onClassChange(classAttrValue) {
            const classList = classAttrValue.split(" ");
            if (classList.includes("is-visible")) {
                !this.gsapAnimation.timeline ? this.runTimeLine() : null;
            }
        },
        ////////////////////////////////
        //       END TRIGGER ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        runTimeLine() {
            this.toggleTimelineIsRunning(true);
            this.gsapAnimation.timeline = gsap.timeline({ paused: false });
            this.gsapAnimation.timeline.to(this.$refs.referenceContent, {
                opacity: 1,
                y: 0,
                ease: this.gsapAnimation.ease,
                duration: this.gsapAnimation.durations.duration1,
                delay: 0.5
            });

            this.data.bubbles.length ? this.addBubbleAnimation() : null;
            this.data.logos ? this.addLogosAnimation() : null;
        },
        toggleTimelineIsRunning(bool) {
            this.gsapAnimation.isRunning = bool;
        },
        addBubbleAnimation() {
            this.gsapAnimation.timeline.to(
                ".b-bubble",
                {
                    opacity: 1,
                    y: 0,
                    stagger: {
                        each: 0.3,
                        ease: this.gsapAnimation.ease
                    }
                },
                "staggeredReferences"
            );
        },
        addLogosAnimation() {
            this.gsapAnimation.timeline.to(
                ".b-references__logos li",
                {
                    opacity: 1,
                    y: 0,
                    stagger: {
                        each: 0.3,
                        ease: this.gsapAnimation.ease
                    }
                },
                "staggeredReferences"
            );
        },

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////
        ////////////////////////////////
        //       START DESTROY
        ////////////////////////////////
        destroyTimeline() {
            this.gsapAnimation.timeline
                ? (this.gsapAnimation.timeline.kill(), (this.gsapAnimation.timeline = null))
                : null;
        }
        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.block-references {
    @include wrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    // Make sure no bubbles get croped from overflow
    padding-top: 2px;
    padding-bottom: 2px;
    // width: calc(120rem + var(--wrap-spacer) * 2);
}

.b-references {
    --bubble-size: 19rem;
    --bubble-size-half: calc(var(--bubble-size) * 0.5);
    --overwritten-max-width: calc(120rem + var(--wrap-spacer) * 2);
    max-width: 100%;

    @mixin overwritten-references-width {
        width: var(--overwritten-max-width);
        max-width: 100%;
    }

    @media #{md("sm")} {
        --bubble-size: 21rem;
    }

    &__content {
        @include overwritten-references-width;
        @include pre-animation(0px, 100px); // animation
        @media #{md("sm")} {
            padding-left: var(--bubble-size-half);
            padding-right: var(--bubble-size-half);
        }

        h2 {
            @include t1;
            max-width: 75rem;
            margin: 0 auto;
            text-align: center;
        }
    }

    &__logos {
        // logos should not have a overwritten max width so the logo can overflow outside to the width of the other sections of the page
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        list-style: none;
        margin-top: var(--grid-gutter);

        @media #{md("md")} {
            margin: var(--grid-gutter-3X) 12% 0;
        }

        @media #{md("xs", "max")} {
            margin-left: var(--grid-gutter-minus);
            margin-right: var(--grid-gutter-minus);
        }

        li {
            @include pre-animation(0px, 100px); // animation

            margin: var(--grid-gutter);

            @media #{md("md")} {
                margin-left: var(--grid-gutter-2X);
                margin-right: var(--grid-gutter-2X);
            }
        }
    }

    &__bubbles {
        --bubble-offset: calc(var(--bubble-size) * 0.4);
        @include overwritten-references-width;
        display: flex;
        pointer-events: none;

        @media #{md("sm", "max")} {
            justify-content: center;
            flex-wrap: wrap;

            .b-bubble {
                &:nth-child(2) {
                    margin-top: calc(var(--grid-gutter));
                    @media #{md('sm')} {
                        margin-top: var(--bubble-offset);
                    }
                }

                &:nth-child(3) {
                    @media #{md("xs", "max")} {
                        margin-top: calc(var(--bubble-offset) * -0.6);
                        margin-left: var(--grid-gutter);
                    }
                }
            }
        }

        @media #{md('xs')} {
            justify-content: space-between;
        }
        @media #{md('lg')} {
            --bubble-offset: calc(var(--bubble-size) * 0.6);
        }

        &.-top {
            --bubbles-title-space: var(--grid-gutter);
            --bubbles-margin-right: 0px;
            --bubbles-margin-left: 0px;
            padding-bottom: var(--bubbles-title-space);
            margin-right: var(--bubbles-margin-right);
            margin-left: var(--bubbles-margin-left);

            @media #{md('sm')} {
                --bubbles-margin-right: calc(var(--wrap-spacer) * -2);
                ---bubbles-margin-left: calc(var(--wrap-spacer) * -1.5);
                --bubbles-title-space: var(--grid-gutter-5X);
                flex-direction: row-reverse;

                > *:first-child,
                > *:nth-child(3) {
                    margin-top: var(--bubble-offset);
                    margin-bottom: calc(var(--bubble-offset) * -1);
                }

                > *:nth-child(2) {
                    margin-left: auto;
                }
            }

            @media #{md('md')} {
                margin-right: calc(var(--wrap-spacer) * -0.5);
                margin-left: calc(var(--wrap-spacer) * -0.5);
            }

            @media #{md('lg')} {
                --bubbles-title-space: var(--grid-gutter-2X);
            }
        }

        &.-bottom {
            --bubbles-left-space: calc(var(--wrap-spacer) * -1.5);

            @media #{md("sm", "max")} {
                padding-top: var(--grid-gutter);
                margin-left: 0;
                margin-right: calc(var(--grid-gutter-2X) * -1);
            }

            @media #{md('sm')} {
                margin-left: var(--bubbles-left-space);

                > *:not(:first-child) {
                    margin-left: 3rem;
                }

                > *:nth-child(2) {
                    margin-top: 4rem;
                }
            }

            @media #{md('md')} {
                --bubbles-left-space: 0;
            }

            @media #{md('lg')} {
                --bubbles-left-space: 10%;
            }
        }

        .b-bubble {
            @include pre-animation(0px, 100px); // animation

            pointer-events: auto;
        }
    }
}
</style>
