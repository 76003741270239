<template>
    <div>
        <hero v-bind:page="page" />

        <app-builder-article v-if="page.sections && page.sections.length" :blocks="page.sections" />

        <external-links
            v-if="page.completeArticleLink"
            :title="page.title"
            :link="page.completeArticleLink"
            :image="page.image"
        />
    </div>
</template>

<script>
import { SECTION_HANDLES } from "src/constants";
import Hero from "components/Article/ArticleHero";
import AppBuilderArticle from "layout/AppBuilderArticle";
import ExternalLinks from "components/Article/ArticleExternalLinks";

export default {
    name: "Article",
    components: {
        Hero,
        AppBuilderArticle,
        ExternalLinks
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        };
    },
    props: {
        page: Object
    },
    data() {
        return {
            SECTION_HANDLES,
            isReady: false
        };
    }
};
</script>
