<template>
    <div class="c-app-footer-top | wrap bs-grid">
        <!-- form -->
        <app-footer-top-contact />
        <!-- contact info -->
        <app-footer-top-meet />
    </div>
</template>

<script>
import AppFooterTopMeet from "components/AppFooter/AppFooterTopMeet/AppFooterTopMeet";
import AppFooterTopContact from "components/AppFooter/AppFooterTopContact/AppFooterTopContact";
export default {
    components: {
        AppFooterTopMeet,
        AppFooterTopContact
    },
    props: {
        isAnimationRunning: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            gsapAnimation: {
                timeline: null,
                durations: { duration1: 0.5 },
                ease: "power1.inOut",
                isRunning: false
            }
        };
    }
};
</script>

<style lang="scss" scoped>
.c-app-footer-top {
    --bs-row-gap: var(--grid-gutter-3X);
    align-items: center;
}
</style>
