<template>
    <section class="c-app-footer-top-contact | col-6 col-5-xl start-2-xl">
        <app-footer-contact-form />
    </section>
</template>

<script>
import AppFooterContactForm from "components/AppFooter/AppFooterTopContact/AppFooterContactForm";
export default {
    components: {
        AppFooterContactForm
    }
};
</script>

<style lang="scss" scoped>
.c-app-footer-top-contact {
    --margin-top-form: var(--grid-gutter-4X);
    @include transform(translate(-200px, 200px));
    margin-top: calc(0px - var(--margin-top-form));
    padding: var(--grid-gutter-3X) var(--grid-gutter-1-5X) calc(var(--grid-gutter-1-5X) - 0.5rem);
    background-color: var(--color-malibu);

    @media #{md("xs")} {
        --margin-top-form: var(--grid-gutter-1-5X);
    }

    @media #{md("md")} {
        margin-right: calc(0px - var(--grid-gutter));
        padding: var(--grid-gutter-4X) var(--grid-gutter-2X) calc(var(--grid-gutter-2X) - 0.5rem);
    }
}
</style>
