// State
const state = {
    isScrollDisabled: false
};

// Getters
const getters = {};

// Actions
const actions = {
    toggleScrollGlobally(store, bool) {
        store.commit("updateScrollGlobally", bool);
    }
};

// Mutations
const mutations = {
    updateScrollGlobally(state, bool) {
        state.isScrollDisabled = bool;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
