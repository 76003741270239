<template>
    <ul v-if="element" class="c-card-content" :class="{ 'c-card-content--white': isWhite }">
        <li>
            <card-content-date :article-type="element.articleType" :post-date="element.postDate" />
        </li>
        <li>
            <h3>{{ truncateTitle }}</h3>
        </li>
        <li>
            <user-card
                v-bind:user="element.author"
                :before-name="$t('shared.by')"
                :is-linkedin-visible="false"
                orientation="horizontal"
                size="small"
            ></user-card>
        </li>
    </ul>
</template>

<script>
import { truncate } from "@/utils";

import CardContentDate from "./CardContentDate"; // why the @ dont work here???
import UserCard from "@/templates/components/UserCard.vue";

export default {
    components: {
        CardContentDate,
        UserCard
    },
    props: {
        element: {
            type: Object,
            required: false,
            default: null
        },
        isWhite: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        truncateTitle() {
            return truncate(this.element.title);
        }
    }
};
</script>

<style scoped lang="scss">
.c-card-content {
    --card-color: var(--color-dark);
    @include reset-list;
    @include t-content;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--grid-gutter);
    color: var(--card-color);
    &--white {
        --card-color: var(--color-light);
    }
    h3 {
        @include t3;
    }
}
</style>
