<template>
    <div class="b-articles | wrap">
        <div class="b-articles__slider | bs-grid" ref="articleSlider" v-reveal.once>
            <block-articles-title
                class="b-articles__slider__title"
                :heading="data.heading"
                :description="data.description"
                :is-hidden="isTitleHidden"
            />

            <block-articles-slide
                :go-to-next="goToNextSlide"
                :go-to-previous="goToPreviousSlide"
                @currentIndex="currentIndex"
                @updateTotalSlides="updateTotalSlides"
            />

            <shared-btn-square
                class="b-articles__slider__btn | col-4 start-3 start-1-sm col-4-lg  start-3-lg end-12-lg start-4-xl end-12-xl"
                :label="$t('articles.view-all')"
                href="/articles"
                icon-after="arrow-right--small"
            />

            <block-articles-arrows
                class="b-articles__slider__arrows"
                :isFirstSlide="isFirstSlide"
                :isLastSlide="isLastSlide"
                @goToNext="goToPrevious"
                @goToPrevious="goToNext"
            />
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";

import BlockArticlesArrows from "blocks/BlockArticles/BlockArticlesSlide/BlockArticlesArrows";
import BlockArticlesSlide from "blocks/BlockArticles/BlockArticlesSlide/BlockArticlesSlide";
import BlockArticlesTitle from "blocks/BlockArticles/BlockArticlesTitle";
import SharedBtnSquare from "components/Shared/SharedBtn/SharedBtnSquare";
export default {
    props: {
        data: false
    },
    components: {
        BlockArticlesArrows,
        BlockArticlesSlide,
        BlockArticlesTitle,
        SharedBtnSquare
    },
    data() {
        return {
            goToNextSlide: false,
            goToPreviousSlide: false,
            currentIndexSlider: 0,
            totalSlides: 0,
            gsapAnimation: {
                timeline: null,
                durations: { duration1: 0.5 },
                ease: "power1.inOut",
                isRunning: false
            }
        };
    },
    computed: {
        isTitleHidden() {
            // desktop only
            return this.currentIndexSlider >= 1 || !this.gsapAnimation.isRunning;
        },
        isFirstSlide() {
            return this.currentIndexSlider == 0;
        },
        isLastSlide() {
            return this.currentIndexSlider == this.totalSlides;
        }
    },
    mounted() {
        this.observer = new MutationObserver(mutations => {
            for (const m of mutations) {
                const newValue = m.target.getAttribute(m.attributeName);
                this.$nextTick(() => {
                    this.onClassChange(newValue, m.oldValue);
                });
            }
        });

        this.observer.observe(this.$refs.articleSlider, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ["class"]
        });
    },
    beforeDestroy() {
        this.observer.disconnect();
        this.destroyTimeline();
    },
    methods: {
        ////////////////////////////////
        //       START SLIDE MANAGEMENT
        ////////////////////////////////
        goToNext() {
            this.goToNextSlide = !this.goToNextSlide;
        },
        goToPrevious() {
            this.goToPreviousSlide = !this.goToPreviousSlide;
        },
        currentIndex(index) {
            this.currentIndexSlider = index;
        },
        updateTotalSlides(total) {
            this.totalSlides = total;
        },
        ////////////////////////////////
        //       END SLIDE MANAGEMENT
        ////////////////////////////////

        ////////////////////////////////
        //       START TRIGGER ANIMATION
        ////////////////////////////////
        onClassChange(classAttrValue) {
            const classList = classAttrValue.split(" ");
            if (classList.includes("is-visible")) {
                !this.gsapAnimation.timeline ? this.runTimeLine() : null;
            }
        },
        ////////////////////////////////
        //       END TRIGGER ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        runTimeLine() {
            this.toggleTimelineIsRunning(true);
            this.gsapAnimation.timeline = gsap.timeline({ paused: false });
            this.gsapAnimation.timeline
                .to(
                    ".b-articles-slide__slide .c-slider__slide",
                    {
                        y: 0,
                        opacity: 1,
                        stagger: {
                            each: 0.1,
                            from: "end",
                            ease: this.gsapAnimation.ease
                        },
                        delay: 0.3
                    },
                    "start"
                )

                .to(".b-articles__slider__btn", {
                    opacity: 1,
                    y: 0,
                    ease: this.gsapAnimation.ease,
                    duration: this.gsapAnimation.durations.duration1
                })

                .to(".b-articles__slider__btn", {
                    x: 0,
                    opacity: 1,
                    stagger: {
                        each: 0.1,
                        ease: this.gsapAnimation.ease
                    }
                })
                .to(
                    ".b-articles__slider__arrows",
                    {
                        x: 0,
                        opacity: 1,
                        stagger: {
                            each: 0.1,
                            ease: this.gsapAnimation.ease
                        }
                    },
                    "start"
                );
        },
        toggleTimelineIsRunning(bool) {
            this.gsapAnimation.isRunning = bool;
        },

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////
        ////////////////////////////////
        //       START DESTROY
        ////////////////////////////////
        destroyTimeline() {
            this.gsapAnimation.timeline
                ? (this.gsapAnimation.timeline.kill(), (this.gsapAnimation.timeline = null))
                : null;
        }
        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.b-articles {
    --article-order-title: 1;
    --article-order-slider: 3;
    --article-order-btn: 2;
    --article-order-arrows: 4;

    @media #{md("xs")} {
        --article-order-slider: 2;
        --article-order-btn: 3;
    }

    &__slider {
        row-gap: 0;

        &__arrows {
            @include pre-animation(0px, 100px); // animation
            order: var(--article-order-arrows);
        }

        &__btn {
            --grid-row-btn: 1;
            @include pre-animation(-100px, 100px); // animation

            margin-right: calc(0px - var(--grid-gutter));
            order: var(--article-order-btn);
            // grid-row: var(--grid-row-btn);

            @media #{md("sm")} {
                --grid-row-btn: 2;
            }
        }
    }
}
</style>
