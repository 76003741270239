<template>
    <span class="o-loader" ref="loader">
        <span ref="loader__bg" class="o-loader__bg"> </span>
    </span>
</template>

<script>
import gsap from "gsap";

import { mapGetters, mapState } from "vuex";
import disableScroll from "@/mixins/disableScroll";
import isDevMixin from "@/mixins/isDevMixin";
import Variables from "@/mixins/variables";
import { findDomByClass, getOffsetDom } from "@/utils";

export default {
    name: "Loader",
    mixins: [disableScroll, isDevMixin, Variables],
    data() {
        return {
            positionParentSquare: {
                x: 0,
                y: 0,
                width: 0
            },
            positionTargetSquare: {
                x: 0,
                y: 0,
                width: 0
            },
            timelines: {
                square: { timeline: null },
                first: { timeline: null },
                in: { timeline: null },
                out: { timeline: null }
            }
        };
    },

    computed: {
        ...mapGetters({
            isLoading: "loader/isLoading"
        }),
        ...mapState({
            isTargetSquareReady: state => state.loader.isTargetSquareReady,
            headerType: state => state.global.currentPage.headerType,
            isFirstLoad: state => state.loader.isFirstLoad
        })
    },
    created() {
        this.$store.dispatch("loader/startLoad");
    },
    mounted() {},
    watch: {
        $route() {
            this.resetSquareIsLoadedGlobally();
        },
        isTargetSquareReady(bool) {
            bool ? this.animationFirstLoad() : null;
        },
        isLoading(loading) {
            if (loading) {
                this.playAnimationIn();
            } else if (this.isFirstLoad) {
                this.animationFirstLoad();
            } else if (!this.timelines.first.timeline) {
                this.animationOut();
            }
        }
    },

    methods: {
        ////////////////////////////////
        //       START FIRST LOAD
        ////////////////////////////////

        //======= START INIT FIRST LOAD ANIMATION =======//

        animationFirstLoad() {
            this.isLoading && this.isTargetSquareReady && this.isFirstLoad ? this.initAnimationFirstLoad() : null;
        },
        initAnimationFirstLoad() {
            //disable scroll globally because so the animation of the square can make sense (if not I would need to recalculate the final position on scroll)
            this.toggleIsScrollDisable(true);
            this.disableScroll();
            setTimeout(() => {
                // hide visibility title
                this.toggleHeroTitleVisibility(false);
                this.setSquarePosition("c-hero-visual-square--parent", "positionParentSquare");
                this.setSquarePosition("c-hero-visual-square__shape", "positionTargetSquare");
                this.setSquareAnimation();
                this.runAnimationFirstLoad();
            }, 500);
        },
        /*------------------------------
        Start position of the square
        ------------------------------*/
        setSquarePosition(className, classPath) {
            const findHeroSquare = findDomByClass(className);
            const positionHeroSquare = getOffsetDom(findHeroSquare[0]);
            this.updateCurrentStateValues(positionHeroSquare, classPath);
        },

        updateCurrentStateValues(positionHeroSquare, statePath) {
            this[statePath].x = positionHeroSquare.x;
            this[statePath].y = positionHeroSquare.y;
            this[statePath].width = positionHeroSquare.width;
            this[statePath].height = positionHeroSquare.height;
        },
        /*------------------------------
        End position of the square
        ------------------------------*/

        //======= END INIT FIRST LOAD ANIMATION =======//

        setSquareAnimation() {
            // find current square to update

            const squareToAnimate = findDomByClass("c-hero-visual-square__shape");
            const assetToAnimate = findDomByClass("c-hero-visual-asset");
            const blueToAnimate = findDomByClass("c-hero-visual-blue-square");

            const duration1s = this.isDevEnv() ? 0.5 : 1;
            const duration2s = this.isDevEnv() ? 0.5 : 1.5;

            this.timelines.square.timeline = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.resetSquareIsLoadedGlobally();
                    this.destroyTimeline("square");

                    // enable scroll back
                    this.toggleIsScrollDisable(false);
                    this.removeDisableScroll();
                }
            });
            this.timelines.square.timeline
                .to(assetToAnimate, {
                    x: "50%",
                    y: "50%",
                    ease: "none",
                    duration: 0
                })
                .to(blueToAnimate, {
                    x: "10rem",
                    y: "-10rem",
                    ease: "none",
                    duration: 0
                })
                .to(
                    squareToAnimate,
                    {
                        "--min-width": `${this.positionTargetSquare.width}px`,
                        "--min-height": `${this.positionTargetSquare.width}px`,
                        position: "fixed",
                        zIndex: 101,
                        left: this.calculatePositionSquareX(),
                        top: this.calculatePositionSquareY(),
                        ease: "power4.inOut",
                        duration: duration2s
                    },
                    "started"
                )
                .to(
                    assetToAnimate,
                    {
                        x: "0%",
                        ease: "power4.inOut",
                        duration: duration1s
                    },
                    `animationStep1-=${this.isDevEnv() ? 0 : 0.5}`
                )
                .to(
                    blueToAnimate,
                    {
                        y: "0%",
                        ease: "power4.inOut",
                        duration: duration1s
                    },
                    `animationStep1-=${this.isDevEnv() ? 0 : 0.5}`
                )
                .to(
                    assetToAnimate,
                    {
                        y: "0%",
                        ease: "power4.inOut",
                        duration: duration1s
                    },
                    "animationStep2"
                )
                .to(
                    blueToAnimate,
                    {
                        x: "0%",
                        ease: "power4.inOut",
                        duration: duration1s
                    },
                    "animationStep2"
                )

                .to(squareToAnimate, {
                    clearProps: "all"
                });
            this.$nextTick(() => {
                this.addHeroAnimationToTimeline(this.timelines.square.timeline);
            });
        },
        addHeroAnimationToTimeline(timeline) {
            findDomByClass("logo-letter")
                ? this.addLogoAnimationToTimeline(timeline)
                : this.addHeroTitleAnimation(timeline);
        },
        addLogoAnimationToTimeline(timeline) {
            const duration1s = this.isDevEnv() ? 0.2 : 1;

            timeline.to(
                ".c-hero .logo-letter",
                {
                    y: "0%",
                    ease: "power4.inOut",
                    duration: duration1s,
                    stagger: 0.1
                },
                "animationStep2"
            );
            timeline.to(
                ".c-hero .logo-square",
                {
                    scaleY: 1,
                    scaleX: 1,
                    ease: "power4.inOut",
                    duration: duration1s
                },
                "animationStep2"
            );
            this.addHeroTitleAnimation(timeline);
        },
        addHeroTitleAnimation(timeline) {
            timeline.add(() => {
                this.toggleHeroTitleVisibility(true);
            }, "animationStep2");

            const heroArrow = findDomByClass("c-hero-bottom__grid__arrow");

            heroArrow ? this.addArrowAnimation(timeline, heroArrow[0]) : null;
        },

        //--- start Add arrow animation ---//
        addArrowAnimation(timeline, target) {
            timeline.to(
                target,
                {
                    y: 0,
                    opacity: 1,
                    ease: "power4.inOut",
                    duration: 1
                },
                "animationStep2"
            );
        },
        //--- end Add arrow animation ---//

        //======= START FUCTIONS NEEDED FOR THE TIMELINE SQUARE ANIMATION =======//

        calculatePositionSquareX() {
            switch (this.headerType) {
                case "triangles":
                    return `${this.positionParentSquare.x}px`;

                default:
                    return `${this.positionParentSquare.x +
                        this.positionParentSquare.width -
                        this.positionTargetSquare.width -
                        0}px`;
            }
        },
        calculatePositionSquareY() {
            switch (this.headerType) {
                case "triangles":
                    return `${this.positionParentSquare.y +
                        this.positionParentSquare.height -
                        this.positionTargetSquare.height -
                        0}px`;

                default:
                    return `${this.positionParentSquare.y +
                        this.positionParentSquare.height -
                        this.positionTargetSquare.width +
                        0}px`;
            }
        },
        resetSquareIsLoadedGlobally() {
            this.$store.dispatch("loader/resetTargetSquaredIsLoaded", false, { root: true });
        },
        toggleHeroTitleVisibility(bool) {
            this.$store.dispatch("hero/toggleTitleVisibility", bool);
        },

        //======= END FUCTIONS NEEDED FOR THE TIMELINE SQUARE ANIMATION =======//

        disableIsFirstLoad() {
            this.$store.dispatch("loader/firstLoaded");
        },

        // keep it for later
        runAnimationFirstLoad() {
            const durationIn1s = this.isDevEnv() ? 0.2 : 0.5;

            this.timelines.first.timeline = gsap.timeline({
                paused: false,
                onStart: () => {
                    this.endLoad(); // avoid animation out running on first load
                },
                onComplete: () => {
                    this.disableIsFirstLoad();
                    this.initAnimationIn();
                    this.destroyTimeline("first");
                }
            });

            // display reveal
            // display timeline square or run it with some sort of sync
            // animate reveal and hide the loader in
            // animated square and stairs
            // animate logo quadrat
            // destroy timeline

            const squareToAnimate = findDomByClass("c-hero-visual-square__shape");

            this.timelines.first.timeline
                .to(this.$refs.loader__bg, {
                    background: "#f2f2f2",
                    ease: "none",
                    duration: durationIn1s,
                    delay: 0
                })
                .to(
                    this.$refs.loader__bg,
                    {
                        "--clip-path-1": "0%",
                        "--clip-path-2": "100%",
                        "--clip-path-in-1": "0%",
                        "--clip-path-in-2": "0%",
                        ease: "none",
                        duration: 0
                    },
                    "started"
                )
                .to(
                    squareToAnimate,
                    {
                        "--min-width": "200vw",
                        "--min-height": "200vh",
                        position: "fixed",
                        left: "-50%",
                        top: "-50%",
                        ease: "none",
                        zIndex: 99,
                        duration: 0
                    },
                    "started"
                )
                .to(
                    this.$refs.loader__bg,
                    {
                        "--clip-path-1": "200%",
                        "--clip-path-2": "-100%",

                        ease: "power4.inOut",
                        duration: durationIn1s
                    },
                    "started+=0.25"
                )
                .add(() => {
                    this.timelines.square.timeline ? this.timelines.square.timeline.play() : null;
                });
        },

        endLoad() {
            this.$store.dispatch("loader/resetLoad");
        },

        ////////////////////////////////
        //       END FIRST LOAD
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION IN
        ////////////////////////////////
        initAnimationIn() {
            const durationOut15s = this.isDevEnv() ? 0.2 : 0.5;

            this.timelines.in.timeline = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.$store.dispatch("navigation/toggleNav", false);
                    this.destroyTimeline("in");
                }
            });

            this.timelines.in.timeline
                .to(this.$refs.loader__bg, {
                    "--clip-path-1": "0%",
                    "--clip-path-2": "100%",

                    "--clip-path-in-1": "0%",
                    "--clip-path-in-2": "100%",
                    "--clip-path-in-3": "0%",
                    "--clip-path-in-4": "100%",

                    ease: "none",
                    duration: 0
                })
                .to(this.$refs.loader__bg, {
                    "--clip-path-in-2": "0%",
                    "--clip-path-in-3": "100%",

                    ease: "power4.in",
                    duration: durationOut15s
                })
                .to(this.$refs.loader__bg, {
                    "--clip-path-in-1": "100%",
                    "--clip-path-in-4": "0%",

                    ease: "power4.out",
                    duration: durationOut15s
                });
        },

        playAnimationIn() {
            this.timelines.in.timeline ? this.timelines.in.timeline.play() : null;
        },
        ////////////////////////////////
        //       END ANIMATION IN
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION OUT
        ////////////////////////////////
        animationOut() {
            const durationIn1s = this.isDevEnv() ? 0.2 : 1;

            this.timelines.out.timeline = gsap.timeline({
                paused: false,
                onComplete: () => {
                    this.initAnimationIn();
                    this.endLoad();
                    this.destroyTimeline("out");
                }
            });
            this.timelines.out.timeline
                .to(
                    this.$refs.loader__bg,
                    {
                        "--clip-path-1": "0%",
                        "--clip-path-2": "100%",
                        "--clip-path-in-1": "0%",
                        " --clip-path-in-2": "100%",
                        ease: "none",
                        duration: 0
                    },
                    "started"
                )

                .to(
                    this.$refs.loader__bg,
                    {
                        "--clip-path-1": "200%",
                        "--clip-path-2": "-100%",

                        ease: "power4.inOut",
                        duration: durationIn1s
                    },
                    "started"
                );

            this.addHeroAnimationToTimeline(this.timelines.out.timeline);
        },
        ////////////////////////////////
        //       END ANIMATION OUT
        ////////////////////////////////

        ////////////////////////////////
        //       START DESTROY STUFF
        ////////////////////////////////
        destroyTimeline(path) {
            this.timelines[path].timeline ? this.killTimeline(path) : null;
        },
        killTimeline(path) {
            this.timelines[path].timeline.kill();
            this.timelines[path].timeline = null;
        }
        ////////////////////////////////
        //       END DESTROY STUFF
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.o-loader {
    z-index: 102;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 1;
    background: none;

    &__bg {
        --clip-path-1: 1%;
        --clip-path-2: 99%;

        --clip-path-in-1: 100%;
        --clip-path-in-2: 0%;
        --clip-path-in-3: 100%;
        --clip-path-in-4: 0%;

        --clip-path: 0 0, var(--clip-path-in-1) var(--clip-path-in-2), var(--clip-path-in-3) var(--clip-path-in-4),
            100% 100%, 100% 100%, var(--clip-path-1) 100%, 0 var(--clip-path-2), 0 0; //0 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 100%, 0 0
        clip-path: polygon(var(--clip-path));

        background: var(--color-light);
        position: absolute;
        min-width: 100vw;
        min-height: 100vh;
    }
}
</style>
