<template>
    <div class="c-hero-visual-blue-square"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.c-hero-visual-blue-square {
    background-color: var(--color-malibu);

    padding-bottom: 100%;// aspect-ratio: 1/1; https://mambomambo-team.atlassian.net/browse/QUAD-252
}
</style>
