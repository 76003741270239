<template>
    <div ref="articlesSlide" class="b-articles-slide | col-6 col-8-sm start-5-sm col-11-lg start-6-lg">
        <slider
            v-if="articles.length"
            ref="slider"
            class="b-articles-slide__slide"
            :zoom="false"
            :show-index="false"
            :prev-next="false"
            :options="{
                wrapAround: false,
                draggable: true,
                contain: false,
                cellAlign: 'left',
                initialIndex: 0
            }"
            :slides="articles"
            @select="currentIndex"
        >
            <template #slide="article">
                <card :element="article" />
            </template>
        </slider>
    </div>
</template>

<script>
import Card from "components/Card/Card.vue";
import Slider from "components/Slider";
export default {
    components: {
        Card,
        Slider
    },
    data() {
        return {
            articles: []
        };
    },
    props: {
        goToNext: {
            type: Boolean,
            required: false,
            default: false
        },
        goToPrevious: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        goToNext() {
            this.$refs.slider.next();
        },
        goToPrevious() {
            this.$refs.slider.previous();
        }
    },
    created() {
        this.fetchEntries();
    },
    methods: {
        fetchEntries() {
            this.$store.dispatch("article/searchEntries", { limit: 6 }).then(res => {
                this.articles = res;
                this.$emit("updateTotalSlides", this.articles.length - 1);
            });
        },
        refreshSlider() {
            this.$refs.slider.resize();
        },
        currentIndex(index) {
            return this.$emit("currentIndex", index);
        }
    }
};
</script>

<style lang="scss">
.b-articles-slide {
    order: var(--article-order-slider);

    &__slide {
        display: flex;
        flex-direction: column-reverse;

        .c-slider__slide {
            @include pre-animation(0px, 100px); // animation
        }

        .flickity-viewport {
            overflow: visible;
        }
    }
}
</style>
