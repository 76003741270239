import { browserName, osName, isMobile } from 'mobile-device-detect'

export default {
    data: () => ({
        W: {
            w: window.innerWidth,
            h: window.innerHeight,
            ratio: window.innerWidth/window.innerHeight,
        },
        browser: {
            name: browserName.toLowerCase().replace(' ', '-'),
            os: osName.toLowerCase().replace(' ', '-'),
        },
        isMobile,
    }),
    mounted() {

        // Set size
        this.set()

        // Add Window events
        window.addEventListener('resizeEnd', this.set);
    },
    methods: {
        set() {
            // Define window vars
            this.W = {
                w: window.innerWidth,
                h: window.innerHeight,
            }

            this.W.ratio = this.W.w/this.W.h
        },
    },
    destroyed() {

        // Remove Window events
        window.removeEventListener('resizeEnd', this.set);
    },
}
