<template>
    <section class="c-app-footer-top-meet | col-6 start-8-lg col-5-xl start-8-xl">
        <ul class="c-app-footer-top-meet__list">
            <li v-if="global.address">
                <a
                    v-if="googleMapUrl"
                    itemprop="address"
                    :aria-label="$t('shared.meetUs.google-maps')"
                    target="_blank"
                    :href="googleMapUrl"
                    v-html="global.address"
                />
                <span v-else v-html="global.address" />
            </li>
            <li v-if="global.phone">
                <a
                    itemprop="telephone"
                    :aria-label="$t('shared.meetUs.phone')"
                    :href="`tel:+${global.phone}`"
                    v-html="global.phone"
                />
            </li>
            <li v-if="global.email">
                <a
                    itemprop="email"
                    :aria-label="$t('shared.meetUs.email')"
                    :href="`mailto:${global.email}`"
                    v-html="global.email"
                />
            </li>
            <li><app-footer-top-meet-card /></li>
        </ul>
    </section>
</template>

<script>
import AppFooterTopMeetCard from "components/AppFooter/AppFooterTopMeet/AppFooterTopMeetCard";
import { mapState } from "vuex";

export default {
    components: {
        AppFooterTopMeetCard
    },
    data() {
        return {
            googleUrlPrefix: "https://www.google.ca/maps/@"
        };
    },
    computed: {
        ...mapState({
            global: state => state.global.settings
        }),
        googleMapCoordinates() {
            return this.global && this.global.map ? this.global.map[0] : null;
        },
        googleMapUrl() {
            if (!this.googleMapCoordinates) return;

            const { lng, lat } = this.googleMapCoordinates;
            return lng && lat ? `${this.googleUrlPrefix}/${lat},${lng}` : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-app-footer-top-meet {
    color: var(--color-light);

    &__list {
        @include darkBackgroundTextSelect;
        @include reset-list;
        display: grid;
        gap: var(--grid-gutter);

        li {
            @include pre-animation(100px, 0px);
            a,
            span {
                @include transition(0.5s ease all);
                @include t4;
                text-decoration: none;
                white-space: pre-wrap;
            }

            a {
                &[href^="mailto"] {
                    white-space: nowrap;
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }
}
</style>
