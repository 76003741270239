<template>
    <div class="b-scrollhack-main-square-icon">
        <ul class="b-scrollhack-main-square-icon__list">
            <block-scroll-hack-main-square-icon-item
                v-for="(entry, i) in entries"
                :key="`scroll-icon-${entry.slug}-${i}`"
                :icon="entry.icon"
                :is-active="isActive(i)"
            />
        </ul>
    </div>
</template>

<script>
import BlockScrollHackMainSquareIconItem from "blocks/BlockScrollhack/BlockScrollHackMainSquare/BlockScrollHackMainSquareIcon/BlockScrollHackMainSquareIconItem.vue";
export default {
    components: {
        BlockScrollHackMainSquareIconItem
    },
    props: {
        entries: {
            type: Array,
            required: false,
            default: () => []
        },
        activeItem: {
            type: Number,
            required: false,
            default: 0
        }
    },
    methods: {
        isActive(index) {
            return index + 1 === this.activeItem;
        }
    }
};
</script>

<style lang="scss" scoped>
.b-scrollhack-main-square-icon {
    @include full-screen-dom(absolute);

    padding-bottom: 100%; // aspect-ratio: 1/1; // https://mambomambo-team.atlassian.net/browse/QUAD-251
    height: 100%;
    z-index: 1;

    &__list {
        @include reset-list;
        position: relative;
        margin-top: 50%;
        z-index: 10;
    }
}
</style>
