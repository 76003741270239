<template>
    <ul class="b-articles-arrows | col-2 start-11-sm start-15-lg">
        <li v-for="index in 2" :key="index">
            <button :aria-label="ariaLabel(index)" :disabled="isDisabled(index)" @click="userClicked(index)">
                <icon icon="arrow-right" />
            </button>
        </li>
    </ul>
</template>

<script>
import Icon from "@/templates/objects/Icon";
export default {
    components: { Icon },
    props: {
        isFirstSlide: {
            type: Boolean,
            required: false,
            default: true
        },
        isLastSlide: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        ariaLabel(index) {
            return index === 1 ? `${this.$t("slider.btn.next")}` : `${this.$t("slider.btn.previous")}`;
        },
        ////////////////////////////////
        //       START CONTROL
        ////////////////////////////////
        userClicked(index) {
            index === 1 ? this.goToNext() : this.goToPrevious();
        },
        isDisabled(index) {
            return index === 1 ? this.isFirstSlide : this.isLastSlide;
        },
        goToNext() {
            this.$emit("goToNext");
        },
        goToPrevious() {
            this.$emit("goToPrevious");
        }
        ////////////////////////////////
        //       END CONTROL
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
ul {
    --arrow-justify-content: flex-start;
    --arrows-position: relative;
    --arrows-row: 1/1;

    @include reset-list;
    align-self: flex-start;
    display: flex;
    justify-content: space-between;
    padding-top: var(--grid-gutter);

    @media #{md("sm")} {
        --arrow-justify-content: flex-end;
        --arrows-row: 2/2;
    }

    li {
        --translateX: 0px;
        --btn-padding: var(--grid-gutter-half);
        display: flex;
        justify-content: flex-end;
        width: calc((100% - var(--grid-gutter)) * 0.5);

        button {
            padding: var(--btn-padding);
            cursor: pointer;

            &::v-deep .o-icon {
                --svg-width: 2.2rem;
                @include transition(0.3s ease transform);
            }

            &:hover {
                --translateX: 0.5rem;
            }

            &[disabled] {
                opacity: 0.2;
                pointer-events: none;
            }
        }

        &:nth-child(1) {
            button {
                margin-right: calc(var(--btn-padding) * -1);
            }
            &::v-deep .o-icon {
                @include transform(rotate(180deg) translateX(var(--translateX)));
            }
        }

        &:nth-child(2) {
            justify-content: flex-start;

            button {
                margin-left: calc(var(--btn-padding) * -1);
            }

            &::v-deep .o-icon {
                @include transform(translateX(var(--translateX)));
            }
        }
    }
}
</style>
