<template>
    <div class="l-builder-article">

        <div
            v-for="(block, i) in blocks"
            :key="`block-${i}`"
            class="-b"
            :class="block.typeHandle | snake"
        >
            <component :is="block.typeHandle" :data="block" />
        </div>

    </div>
</template>

<script>
import BlockTagline from "blocks/BlockTagline";
import BlockContent from "blocks/BlockContent";
import BlockHighlight from "blocks/BlockHighlight";

export default {
    name: "AppBuilderArticle",
    props: {
        blocks: {
            type: Array,
            default: () => []
        }
    },
    components: {
        BlockTagline,
        BlockContent,
        BlockHighlight
    }
};
</script>

<style lang="scss">
.l-builder-article {
    --block-spacer: var(--grid-gutter-3X);

    @media #{md("lg")} {
        --block-spacer: 11rem;
    }

    .-b {
        &:nth-child(n + 2) {
            margin-top: var(--block-spacer);
        }
    }
}
</style>
