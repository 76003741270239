<template>
    <div class="c-hero-visual-square c-hero-visual-square--parent" :class="`c-hero-visual-square--${headerType}`">
        <shapes-square
            :is-responsive="true"
            class="c-hero-visual-square__shape"
            :background-color="setBackgroundColour"
            :icon="icon"
        />
    </div>
</template>

<script>
import ShapesSquare from "components/Shapes/ShapesSquare";
export default {
    components: {
        ShapesSquare
    },
    props: {
        icon: {
            type: String,
            required: false,
            default: "shape--inclined-lines"
        },
        headerType: {
            type: String,
            required: false,
            default: "cubes"
        }
    },
    computed: {
        setBackgroundColour() {
            switch (this.headerType) {
                case "cubes":
                    return "concrete";
                case "triangles":
                    return "dark";

                default:
                    return "light";
            }
        }
    },
    mounted() {
        this.toggleSquareIsLoadedGlobally();
    },
    methods: {
        toggleSquareIsLoadedGlobally() {
            this.$nextTick(() => {
                this.$store.dispatch("loader/targetSquaredIsLoaded", true, { root: true });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.c-hero-visual-square {
    --top-shape-square: auto;
    --bottom-shape-square: 0;
    --left-shape-square: auto;
    --right-shape-square: 0;
    position: absolute;
    bottom: calc(100% - var(--intersection-y));
    right: calc(100% - var(--intersection-x));

    &__shape {
        top: var(--top-shape-square);
        bottom: var(--bottom-shape-square);
        left: var(--left-shape-square);
        right: var(--right-shape-square);
    }

    // Header types
    &--triangles {
        --left-shape-square: 0;
        --right-shape-square: auto;
    }
}
</style>
